export const modelos = [
  { id: "0", text: "Nota de formulário" },
  { id: "01", text: "NF avulsa" },
  { id: "1A", text: "NF avulsa" },
  { id: "1B", text: "NF avulsa" },
  { id: "2B", text: "Cupom fiscal não ECF" },
  { id: "2C", text: "Cupom fiscal PDV" },
  { id: "2D", text: "Cupom fiscal ECF" },
  { id: "04", text: "NF produtor rural" },
  { id: "06", text: "NF de energia elétrica" },
  { id: "08", text: "CT manual" },
  { id: "21", text: "NF comunicação" },
  { id: "22", text: "NF telecomunicações" },
  { id: "28", text: "NF fornecimento de gás" },
  { id: "29", text: "NF fornecimento de água" },
  { id: "55", text: "NF-e" },
  { id: "57", text: "CT-e" },
  { id: "58", text: "MDF-e" },
  { id: "59", text: "SAT" },
  { id: "65", text: "NFC-e" },
  { id: "66", text: "NF3-e energia elétrica" },
  { id: "67", text: "CT-e OS" },
  { id: "99", text: "Outros" },
  { id: "DARE", text: "Documento de arrecadação de receitas estaduais" },
  { id: "LOC", text: "Locação" },
  { id: "NFSE", text: "NFS-e" },
  { id: "PET", text: "Petshop" },
];

export function getModelosPadrao(include = []) {
  const blacklist = [
    "0",
    "2B",
    "2C",
    "08",
    "57",
    "58",
    "66",
    "67",
    "2D",
    "99",
    "LOC",
    "NFSE",
    "PET",
  ];
  include.forEach((i) => blacklist.splice(i));
  return modelos.filter((m) => !blacklist.includes(m.id));
}
