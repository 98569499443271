<template>
  <div class="s-cte-procura-pneu">
    <div ref="pneu" style="margin-top: 2px; width: 110px;"></div>
    <div id="titlePneuInfo"><label>Pneu</label></div>
    <div id="pneuInfo">
      <div v-if="pneu"
        style="text-align: center;line-height: 20px;font-size: 12px;">
        <div>
          <s-cte-pneu v-model="pneu" :dragable="pneudrag"
            @click="editarPneu(pneu.id)"></s-cte-pneu>
        </div>
        <div style="flex:1">
          <div style="font-weight: bold;">
            {{ pneu.codigo }} - {{ pneu.serie }}
            <i class="mdi mdi-history mdi-18px" style="cursor: pointer"
              title="Histórico" @click="history"></i>
          </div>
          <div :title="`Marca: ${pneu.marca}`">{{ pneu.marca }}</div>
          <div :title="`Status: ${pneu.status}`">{{ pneu.status }}</div>
          <div :title="`Modelo: ${pneu.modelo}`">{{ pneu.modelo }}</div>
          <div :title="`Medida: ${pneu.medida}`">{{ pneu.medida }}</div>
          <div v-if="pneu.recapes > 0">{{ pneu.recapes }} recape(s)</div>
          <div style="font-weight: bold;"
            :title="`Hodômetro: ${pneu.hodometro}`">
            {{ pneu.hodometro || '0' }} KM
          </div>
          <div class="s-cte-procura-pneu-veiculo" v-if="pneu.veiculo"
            @click="$emit('veiculoclick', pneu.veiculo)">
            {{ pneu.veiculo.placa }} <i class="mdi mdi-truck mdi-14px"></i>
          </div>
          <div v-if="pneu.veiculo">Posição: {{ pneu.veiculoPosicao }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "s-cte-procura-pneu",

  inject: ["hdl"],

  props: {
    value: [Object, Number, String],
    readonly: {
      type: Boolean,
      default: false,
    },
    pneudrag: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    pneu: function () {
      // Quando for passado somente o id do pneu
      // Retorna um object simples para ocorrer warnings/erros na renderização
      if (typeof (this.value) === "number") {
        return {
          pneu: {},
        };
      }

      return this.value;
    },
  },

  watch: {
    value(novo) {
      this.$refs.pneu.value = novo;
    },

    readonly: function (val) {
      const $pneu = jQuery(this.$refs.pneu);
      $pneu.foreigninput("readonly", val);
    },
  },

  beforeDestroy() {
    jQuery(this.$refs.pneu).off().remove();
  },

  methods: {
    setPneuInfo: function (pneu) {
      if (!pneu) {
        this.$emit("input", null);
        return;
      }
      pneu.imagem = window.Siggma.basePath + "/img/pneu.png";
      this.$emit("input", pneu);
    },

    editarPneu(id) {
      window.Yanda.newApl(538, id, this.hdl, function (hdl) {
        hdl.onSave = function () {
          jQuery(this.$refs.pneu).val(id);
        };
      });
    },

    history() {
      this.pneu && window.Yanda.newApl(550, {
        postData: {
          pneu: this.pneu.id,
        },
      }, this.hdl);
    },
  },

  mounted() {
    const $pneu = jQuery(this.$refs.pneu);
    $pneu.foreigninput({
      url: `${window.Siggma.basePath}/cte/pneus/get-by-codigo`,
      id: 537,
      hdl: this.hdl,
      change: this.setPneuInfo,
      number: true,
      fieldCod: "codigo",
      autocomplete: {
        url: `${window.Siggma.basePath}/cte/pneus/find`,
        info: "Pesquise por Código, Marca, Status",
        renderItem: (ul, data) => {
          return jQuery('<li style="display:flex">').append(
            `<div style="flex: 1 auto;overflow: hidden;white-space: nowrap;text-overflow:
                            ellipsis;width: 0;line-height: 25px;border-radius: 4px;">
                            <b>${data.codigo}</b> - ${data.marca.toUpperCase()}
                            <small style='float: right'>${data.status}</small>
                        </div>`,
          ).appendTo(ul);
        },
      },
    }).find("input").eq(1).css({
      width: "245px",
    });

    $pneu.foreigninput("readonly", this.readonly);
  },
};
</script>

<style>
.s-cte-procura-pneu {
  height: calc(100%);
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  min-height: 0;
}

.s-cte-procura-pneu #titlePneuInfo {
  background-color: var(--s-box-primary-bg-color);
  color: var(--s-box-primary-color);
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  width: 265px;
  z-index: 0;
  padding: 0 10px;
  position: inherit;
  font-weight: bold;
  border-radius: 4px 4px 0 0;
  text-transform: uppercase;
}

.s-cte-procura-pneu #titlePneuInfo label {
  color: #FFF;
  margin-top: 14px;
}

.s-cte-procura-pneu #pneuInfo {
  flex: 1;
  width: 265px;
  border: 1px solid #AAA;
  padding: 10px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 1px 0px 4px #c2c2c2;
  border-radius: 0 0 4px 4px;
  background-color: #FFF;
}

.s-cte-procura-pneu #pneuInfo>div {
  display: flex;
}

.s-cte-procura-pneu-veiculo {
  color: darkblue;
  cursor: pointer;
}

.s-cte-procura-pneu-veiculo:hover {
  text-decoration: underline;
}
</style>