import Notification from "element-ui/lib/notification.js";
import SharedWorker from "../../scripts/workers-loader.js!worker-loader?worker=SharedWorker!../sharedWorker";
import WebWorker from "../../scripts/workers-loader.js!worker-loader!../sharedWorker/indexWorker.js";

function onMessageWorker(evt) {
  const msg = evt.data;
  const cmd = msg.cmd;

  if (cmd === "error") {
    console.debug(msg);
    return;
  }

  if (["connected", "disconnected"].includes(cmd)) {
    console.info(msg.data.msg);
    globalThis.Siggma.ws.isConnected = msg.data.connected === true;
    return;
  }

  if (cmd === "session-another-location") {
    globalThis.Siggma.lockedSession(msg.data.message);
    return;
  }

  if (cmd === "access-control-granted") {
    const janelaDes = globalThis.Yanda.ctr.janelaDes;
    if (janelaDes?.uniqid === msg.data.uniqid) {
      msg.data.motivo && (janelaDes.$("motivo").value = msg.data.motivo);
      janelaDes.usuario = msg.data.usuario;
      janelaDes.$("pwd").value = msg.data.pwd;
      janelaDes.$("btnLiberar").click();
    }
    return;
  }

  if (cmd === "cache-clear") {
    globalThis.Yanda.cache.clear()
      .then(() => console.log("cache limpo com sucesso"));
    return;
  }

  if (cmd === "force-reload") {
    globalThis.onbeforeunload = null;
    globalThis.location.reload();
    return;
  }

  if (cmd === "bind") {
    (msg.data.widgets !== undefined) &&
      globalThis.Siggma.$widgets?.load?.(msg.data.widgets || []);
    globalThis.Siggma.notify.setData(msg.data.notify);
    globalThis.Siggma.atualizacoes(msg.data.atualizacoes);

    const clear = msg.data.systemCacheClear;
    clear && parseInt(Storage.getItem("SystemCacheClear") || "0") < clear &&
      globalThis.Yanda.cache.clear().then(() => {
        Storage.setItem("SystemCacheClear", clear);
      });

    return;
  }

  if (cmd === "widgets-refresh") {
    globalThis.Siggma.$widgets?.refresh?.(msg.data);
    return;
  }

  if (cmd === "notificar") {
    notificar(msg.data);
    return;
  }
}

function notificar(data) {
  data.sound && (new Audio(data.sound)).play();

  const n = Notification.info({
    title: data.title,
    message: data.message,
    duration: data.duration || 0,
    dangerouslyUseHTMLString: true,
    offset: 50,
    onClick() {
      if (data.params?.tela) {
        const { empresa, filial } = globalThis.Siggma;
        (data.empresa == empresa && data.filial == filial) &&
          globalThis.Yanda.newApl(data.params.tela, data.params);
      }

      n.close();
    },
  });
}

function sharedWorker() {
  const prefix = globalThis.Yanda.prefix.replace(":", "");

  if ("SharedWorker" in globalThis) {
    const worker = new SharedWorker(`${prefix}-core`);
    worker.onerror = (event) => console.error("shared-worker", event);
    worker.port.start();
    worker.port.addEventListener("message", onMessageWorker);

    return worker;
  }

  if (!("Worker" in globalThis)) {
    console.error("No suport for Workers");
    return;
  }

  console.info("No suport for SharedWorkers, using normal Worker");

  const ww = new WebWorker(`${prefix}-core`);
  ww.onerror = (event) => console.error("web-worker", event);

  const worker = { port: ww };
  worker.port.addEventListener("message", onMessageWorker);

  return worker;
}

export default sharedWorker;
