export const config = {
  urlArgs: null,
  basePath: "/",
};

export function importModule(url) {
  if (Array.isArray(url)) {
    return Promise.all(url.map((u) => importModule(u)));
  }
  if (!/^https?:\/\//.test(url)) {
    url = `${config.basePath}/${url}`;
  }
  if (config.urlArgs) {
    url += `?${config.urlArgs}`;
  }
  return import(/* webpackIgnore: true */ url);
}

export function require(modules, callback) {
  console.warn("require está depreciado. Utilize Siggma.import no lugar");
  Promise.all(modules.map((name) => importModule(`${name}.js`)))
    .then((resp) => callback?.apply(undefined, resp.map((r) => r?.default)))
    .catch((e) => console.error(e));
}
