<template>
  <div>
    <span v-if="selected && !multiple" :style="selected.css">
      <i :class="`mdi mdi-${selected.icon} mdi-18px`"></i>
    </span>
    <i class="mdi mdi-plus mdi-18px s-icon" v-if="!selected && !multiple"></i>
    <s-select2
      :style="`width: ${multiple ? '300' : '200'}px; text-align: left;`"
      :value="value" @input="$emit('input', $event)" required
      :disabled="disabled" :multiple="multiple"
      :templateResult="templateResultTipo" :data="tiposHistoricos.map(ts => {
        ts.id = ts.name;
        ts.text = ts.name;
        return ts;
      })" />
  </div>
</template>

<script>
import historicos from "@/utils/petshopHistoricos";
import style from "@/utils/style";

function getTiposHistoricos(ignore = null) {
  let tiposHistoricos = historicos.tiposHistoricos();
  ignore && ignore.forEach((i) => {
    tiposHistoricos = tiposHistoricos.filter((t) => t.name !== i);
  });
  return tiposHistoricos;
}

export default {
  name: "s-petshop-tipo",

  computed: {
    selected() {
      return this.tiposHistoricos.find((ts) => ts.name === this.value);
    },
  },

  watch: {
    ignore(v) {
      this.tiposHistoricos = getTiposHistoricos(v);
    },
  },

  props: {
    value: [Array, Object, String, Number],
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    ignore: Array,
  },

  data() {
    return {
      tiposHistoricos: getTiposHistoricos(this.ignore),
    };
  },

  methods: {
    templateResultTipo(ts) {
      const el = globalThis.document.createElement("div"),
        mod = this.tiposHistoricos.find((d) => d.id === ts.id);
      if (!mod) {
        return null;
      }
      el.style = "display: flex; align-items: center";
      el.innerHTML = `<span style="${style.objectToCss(mod.css)}">
        <i class="mdi mdi-${mod.icon} mdi-18px"></i>
      </span>&nbsp;${mod.name}`;
      return el;
    },
  },
};
</script>