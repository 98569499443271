<template>
  <div
    style="display: flex; overflow: hidden; flex-direction: column; width: 100%; flex: 1;">
    <div v-if="editavel" style="display: flex; justify-content: end;">
      <input class="s-card" type="text" v-model="input" @keyup.enter="adicionar"
        @keydown.enter.prevent placeholder="Adicionar grupo...">
      <i class="s-icon mdi mdi-plus mdi-18px" @click="adicionar"></i>
      <i class="s-icon mdi mdi-eraser mdi-18px" @click="limpar"></i>
    </div>
    <div
      style="overflow: auto; display: flex; flex-direction: column; flex: 1;">
      <div v-if="!grupos.length">
        <div style="display: flex; justify-content: center; padding-top: 10%;">
          <label>
            Esta checklist não tem Check Interativo cadastrado.<br>
            <a :href="`${basePath}/help/Dicas_de_uso/Utilidades/Check_Interativo.html`"
              target="blank"> -> O que é Check Interativo?</a>
          </label>
        </div>
      </div>
      <ul v-else style="list-style-type: none; padding: 0;" class="s-card-line">
        <li v-for="(grupo, i) in grupos" :key="grupo.id">
          <grupo :grupo="grupo" @remover="remover(i)"></grupo>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Grupo from "./Grupo";

export default {
  name: "s-check-interativo",

  inject: ["hdl"],

  components: {
    Grupo,
  },

  props: {
    grupos: {
      type: Array,
      required: true,
    },
    editavel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      input: "",
      showInput: false,
      basePath: Siggma.basePath,
    };
  },

  methods: {
    adicionar() {
      if (this.input != "") {
        this.grupos.push({
          id: `uuid${Math.uuid(8)}`,
          name: this.input,
          items: [],
        });
      }

      this.input = "";
    },

    remover(index) {
      const name = this.grupos[index].name;
      globalThis.Yanda.confirm({
        msg: `Deseja realmente remover o grupo <b>${name}</b>?`,
        actionTrue: () => this.grupos.splice(index, 1),
      });
    },

    limpar() {
      globalThis.Yanda.confirm({
        msg: "Deseja realmente remover <b>TODOS</b> os grupos?",
        actionTrue: () => this.grupos = [],
      });
    },
  },
};
</script>