import jQuery from "jquery";

function closeZtBox(e) {
  if (
    e && (jQuery(e.target).closest(".zt-box").index() >= 0 ||
      jQuery(e.target).closest(".zt-preview").index() >= 0)
  ) {
    return;
  }
  jQuery(".zt-box, .zt-seta").hide().parent().removeClass("zt-show");
  jQuery(".zt-box").parent().find(".zt-detail").css({
    "visibility": "visible",
  });
  document.removeEventListener("click", closeZtBox);
}

function controlarZtModulos() {
  jQuery("#zt-modulos .zt-box li").on("click", function (event) {
    const ctrl = event && (event.ctrlKey || event.metaKey);
    const data = this.dataset;
    const modulo = data.id;
    if (Siggma.modulo == modulo) {
      return;
    }

    closeZtBox();
    function trocar() {
      const url = `${Siggma.basePath}/modulos/${data.md5}/`;
      if (ctrl) {
        globalThis.open(url);
        return;
      }
      Yanda.disableAll(true);
      globalThis.onbeforeunload = null;
      document.location = url;
    }

    if (ctrl || !Yanda.getTotJans()) {
      trocar();
      return;
    }

    Yanda.confirm(
      true,
      1,
      "Confirmação",
      "Ao trocar de módulo todas as janelas serão fechadas<br><br>Deseja continuar mesmo assim?",
      1,
    ).onBeforeClose = function () {
      if (!this.returnValue) {
        return;
      }
      this.kill();
      trocar();
      return false;
    };
  });
}

function stopEvent(e) {
  e.stopPropagation();
  e.preventDefault();
}

function controlarZtBox() {
  const $ulEmpresa = jQuery("#zt-empresa .zt-box ul");
  const $input = jQuery("#zt-empresa .zt-box input");
  let searchVal, searchTimeout, requestIndex = 0;

  jQuery(
    `#zt-empresa .zt-preview,
        #zt-modulos .zt-preview,
        #zt-virtual .zt-preview,
        #zt-filial .zt-preview,
        #zt-notify .zt-preview,
        #zt-opcoes .zt-preview,
        #zt-helps .zt-preview`,
  ).on("click", function () {
    const $elm = jQuery(this).parent();
    const $box = $elm.find(".zt-box, .zt-seta");
    if ($box.index() === -1) {
      return;
    }
    const toClose = $box[0].style.display === "block";
    closeZtBox();
    if (toClose) {
      return;
    }

    document.addEventListener("click", closeZtBox, false);
    $box.parent().addClass("zt-show");
    $box.show();
    $elm.find(".zt-detail").css({ visibility: "hidden" });
    $box.find("input").focus();
  });

  jQuery("#zt-opcoes .zt-box li").on("click", function () {
    jQuery("#zt-opcoes .zt-preview").trigger("click");
    jQuery(this.dataset.elm).trigger("click");
  }).find("i").css({ width: "25px", "text-align": "center" });

  jQuery("#zt-filial .zt-box li").on("click", function () {
    const filial = this.dataset.value;

    closeZtBox();
    function trocar() {
      jQuery.ajax({
        method: "POST",
        url: `${Siggma.basePath}/app/index/trocar-filial`,
        data: "id=" + filial,
      }).done(function (resp) {
        if (resp.type !== "success") {
          Yanda.alert(true, "Alerta", resp.msg, 0, resp.exception);
          return;
        }
        globalThis.localStorage.setItem("trocaFilial", new Date());
        globalThis.onbeforeunload = null;
        document.location.reload();
      });
    }

    if (!Yanda.getTotJans()) {
      trocar();
      return;
    }

    Yanda.confirm(
      true,
      1,
      "Confirmação",
      "Ao trocar de filial todas as janelas serão fechadas<br><br>Deseja continuar mesmo assim?",
      1,
    ).onBeforeClose = function () {
      const that = this;
      if (!that.returnValue) {
        return;
      }
      that.kill();
      trocar();
      return false;
    };
  });

  jQuery("#zt-virtual .zt-box li").on("click", function () {
    const action = this.dataset.action;
    const question = "<br><br>Deseja continuar mesmo assim?";
    const actions = {
      recarregar:
        '<span style="font-weight: bold; font-size: 15px;">Ao recarregar dados iniciais todas operações realizadas serão perdidas</span>' +
        question,
      limpar:
        '<span style="font-weight: bold; font-size: 15px;">Ao limpar os dados todas as imformações serão perdidas</span>' +
        question,
    };

    closeZtBox();
    function trocar() {
      Yanda.disableAll(true);
      const $div = jQuery("<div></div>").css({
        width: "100%",
        height: "100%",
        background:
          `url(${Yanda.src}img/carregando.gif) no-repeat center center`,
      });

      jQuery(document.body).append($div);

      jQuery.ajax({
        method: "POST",
        url: `${Siggma.basePath}/app/index/virtual-actions`,
        data: "action=" + action,
      }).done(function (resp) {
        $div.remove();

        if (resp.type !== "success") {
          Yanda.disableAll(false);
          Yanda.alert(true, "Alerta", resp.msg, 0, resp.exception);
          return;
        }

        globalThis.onbeforeunload = null;
        Notify[resp.type](resp.msg);
        setTimeout(() => document.location.reload(), 10);
      });
    }

    const hdl = Yanda.confirm(true, 1, "Confirmação", actions[action], 1);
    hdl.onBeforeClose = function () {
      const that = this;
      if (!that.returnValue) {
        return;
      }
      that.kill();
      trocar();
    };

    jQuery(hdl.$("btnOk")).removeClass("btn-primary").addClass("btn-danger")
      .css({ fontWeight: "bold" });
  });

  async function trocaEmpresa(id) {
    const browserHash = await Siggma.database.connect(Siggma.basePath)
      .then(() => Siggma.database.get("_", 1))
      .then((data) => (data && data.hash))
      .catch((e) =>
        console.log(
          `Erro ao connectar no IndexedDB. Erro: ${e.target.errorCode}`,
        )
      );

    jQuery.ajax({
      url: `${Siggma.basePath}/app/index/trocar-empresa`,
      data: { id, browserHash },
      method: "POST",
    }).done(function (resp) {
      if (resp.type !== "success") {
        Yanda.alert(true, "Alerta", resp.msg, 0, resp.exception);
        return;
      }
      Yanda.disableAll(true);
      closeZtBox();
      globalThis.localStorage.setItem("trocaEmpresa", new Date());
      globalThis.onbeforeunload = null;
      document.location.reload();
    });
  }

  function setEventosBoxEmpresas() {
    $ulEmpresa.find("li").on("click", function () {
      trocaEmpresa(this.dataset.id);
    });
  }

  function renderEmpresas(data) {
    $ulEmpresa.html("");
    jQuery.each(data, function (_i, empresa) {
      jQuery(
        '<li tabindex="0" data-id="' + empresa.em_reg_idx + '">' +
          empresa.em_reg_idx + " - " + empresa.em_raz_soc + "</li>",
      ).appendTo($ulEmpresa);
    });
    setEventosBoxEmpresas();
  }

  $input.on("keydown", function (e) {
    const $ul = jQuery("#zt-empresa .zt-box ul");
    if (e.keyCode === 40 || e.keyCode === 13) { // Down
      stopEvent(e);
      $ul.find("li:visible").length && jQuery.tabNext();
      return;
    }
  });
  $input.on("keyup", function () {
    if (this.value === searchVal) {
      return;
    }
    searchVal = jQuery(this).val();
    clearTimeout(searchTimeout);
    if (!searchVal) {
      renderEmpresas([]);
      return;
    }
    searchTimeout = setTimeout(function () {
      const index = ++requestIndex;
      jQuery.ajax({
        method: "GET",
        url: `${Siggma.basePath}/app/index/empresas-usuario`,
        data: "filtro=" + searchVal,
      }).done((resp) => (index === requestIndex) && renderEmpresas(resp));
    }, 300);
  });

  setEventosBoxEmpresas();
  $ulEmpresa.on("keydown", function (e) {
    switch (e.keyCode) {
      case 13: // enter
        stopEvent(e);
        trocaEmpresa(document.activeElement.dataset.id);
        break;
      case 38: // Up
        stopEvent(e);
        jQuery.tabPrev();
        if ($ulEmpresa.find(document.activeElement).length) {
          return;
        }
        break;
      case 40: // Down
        stopEvent(e);
        jQuery.tabNext();
        if ($ulEmpresa.find(document.activeElement).length) {
          return;
        }
        $ulEmpresa.find("li:visible").first().focus();
        break;
      default:
        break;
    }
  });

  // atualiza outras abas
  globalThis.addEventListener("storage", function (e) {
    if (["trocaEmpresa", "trocaFilial"].includes(e.key)) {
      globalThis.onbeforeunload = null;
      document.location.reload();
    }
  }, false);

  controlarZtModulos();
}

function criarSiggmaNotify() {
  return Siggma.Notify({
    box: jQuery("#zt-notify .zt-box"),
    boxInfo: jQuery("#zt-notify .siggma-notify-info"),
  }).on("remove", function (_ev, data) {
    fetch(
      `${Siggma.basePath}/app/notificacoes/remove?id=${data.id}&page=${data.page}`,
    )
      .then((resp) => resp.json())
      .then((resp) => {
        globalThis.Siggma.notify.setData(resp.data);
        Siggma._worker.port.postMessage({ cmd: "bind" });
      })
      .catch(() => null);
  }).on("removeAll", function () {
    fetch(`${Siggma.basePath}/app/notificacoes/remove-all-by-user`)
      .then((resp) => resp.json())
      .then((resp) => {
        globalThis.Siggma.notify.setData(resp.data);
        Siggma._worker.port.postMessage({ cmd: "bind" });
      })
      .catch(() => null);
  }).on("fetch", function (_ev, page) {
    fetch(`${Siggma.basePath}/app/notificacoes/fetch?page=${page}`)
      .then((resp) => resp.json())
      .then((resp) => globalThis.Siggma.notify.setData(resp.data))
      .catch(() => null);
  }).on("refresh", () => Siggma._worker.port.postMessage({ cmd: "bind" }));
}

export function inicializa(options) {
  Yanda.ajaxResponseError = (xhr) => {
    const setError = (detail) => {
      try {
        const resp = detail ? JSON.parse(detail) : null;
        if (resp?.type) {
          Yanda.message(resp);
          return;
        }
      } catch (_e) {
        // no action
      }

      try {
        eval(detail);
      } catch (_e) {
        Yanda.message({
          type: "error",
          title: "Oh não",
          msg: [
            "Ocorreu uma falha de comunicação com o nosso servidor",
            "e a nossa equipe técnica já está ciente e resolvendo.",
            "<br>Por favor, tente novamente em alguns instantes",
          ].join("<br>"),
          detail,
        });
      }
    };

    switch (xhr.status) {
      case 200:
        break;
      case 400:
        Yanda.alert(true, "Oops", "Dados incorretos, tente novamente.");
        break;
      case 403:
        Siggma.response403(xhr);
        break;
      case 404:
        Yanda.alert(true, "Oops", "Página não encontrada");
        break;
      case 413:
        Yanda.alert(
          true,
          "Oops",
          "413 - Está sendo enviado muitas informações ao servidor",
          false,
        );
        break;
      default:
        if (xhr.responseType === "blob") {
          const resp = xhr.response;
          resp.type === "application/json" &&
            resp.text().then((text) => setError(text)).catch(() => null);
          break;
        }

        setError(xhr?.responseText || "");
        break;
    }
  };

  controlarZtBox();
  Siggma.notify = criarSiggmaNotify();

  Siggma.database.connect(Siggma.basePath).then((conn) => {
    Yanda.cache.config = {
      pkey: "id",
      table: "apps",
      disabled: Storage.getItem("SystemCacheDisabled") === "1",
      connection: conn,
    };
  }).catch((e) =>
    console.log(`Erro ao connectar no IndexedDB. Erro: ${e.target.errorCode}`)
  );

  !Siggma.devMode && (globalThis.onbeforeunload = () => false);
  globalThis.onresize = function () {
    Siggma.painelMenu && Siggma.painelMenu.refresh();
    Yanda.redraw();
    Yanda.toolbar.refresh();
  };

  const $tSystemCmd = document.querySelector("#tSystemCmd");
  $tSystemCmd.addEventListener("blur", function () {
    this.parentElement.style.display = "none";
  });
  $tSystemCmd.addEventListener(
    "keypress",
    function (evt) {
      if (evt.key !== "NumpadEnter" && evt.key !== "Enter") {
        return;
      }

      this.blur();
      if (!this.value) {
        return;
      }

      let id;
      switch (this.value) {
        case "bind":
          Siggma._worker.port.postMessage({ cmd: this.value });
          break;
        case "ce":
          Yanda.cache.disabled = false;
          Storage.setItem("SystemCacheDisabled", "");
          Notify.success("Cache de janelas habilitado");
          break;
        case "cd":
          Yanda.cache.disabled = true;
          Storage.setItem("SystemCacheDisabled", "1");
          Notify.success("Cache de janelas desabilitado");
          break;
        case "cc":
          Yanda.cache.clear()
            .then(() => Notify.success("Limpado cache de janelas"))
            .catch(() => Notify.error("Falha ao limpar cache de janelas"));
          break;
        default:
          id = parseInt(this.value.replace(/[^0-9]/g, ""));
          (id > 0) && Yanda.newApl(id);
          break;
      }
    },
  );

  function onlineCheck() {
    const $zt = jQuery("#zt-online");
    if (navigator.onLine) {
      $zt.css({ color: "inherit" }).find(".zt-info").html("");
      $zt.find("span:first-child").removeClass("mdi mdi-exclamation-thick");
      return;
    }
    $zt.css({ color: "red" }).find(".zt-info").html("Off-line");
    $zt.find("span:first-child").addClass("mdi mdi-exclamation-thick");
  }
  globalThis.addEventListener("offline", onlineCheck);
  globalThis.addEventListener("online", onlineCheck);
  onlineCheck();

  jQuery("#zt-sair").on("touchstart click", function (e) {
    e.preventDefault();

    // Slim|Construção|SnoopySmart sai diretamente
    document.location = `${Siggma.basePath}/app/index/` +
      (["7", "9", "15"].includes(Siggma.modulo) ? "logout" : "workbench");
  });

  jQuery("#zt-preferencias").on("touchstart click", function (e) {
    e && e.preventDefault && e.preventDefault();
    Yanda.newApl(1, Siggma.modulo, false);
  });
  jQuery("#zt-mensagens").on("touchstart click", function (e) {
    e.preventDefault();
    Yanda.newApl(209, Siggma.modulo, false);
  });
  jQuery("#zt-painelAgendaControle").on("touchstart click", function (e) {
    e.preventDefault();
    Yanda.newApl(422);
  });
  jQuery("#zt-painelAgendaSimples").on("touchstart click", function (e) {
    e.preventDefault();
    Yanda.newApl(205);
  });
  jQuery("#zt-usuario").on("touchstart click", function (e) {
    e.preventDefault();
    Yanda.newApl(683);
  });
  jQuery("#zt-atualizacoes").on("touchstart click", function (e) {
    e.preventDefault();
    e.stopPropagation();
    globalThis.open(
      `${Siggma.basePath}/help/Atualiza%C3%A7%C3%B5es.html`,
      "_blank",
    );
  });
  jQuery("#zt-area-cliente").on("touchstart click", function (e) {
    e.preventDefault();
    e.stopPropagation();
    globalThis.open(`${Siggma.basePath}/app/area-cliente?close=1`, "_blank");
  });
  jQuery("#zt-help").on("touchstart click", function (e) {
    e.preventDefault();
    e.stopPropagation();
    globalThis.open(`${Siggma.basePath}/help/Sistema_Zetta_ERP.html`, "_blank");
  });
  jQuery(
    `<div class="ajan-brt-toggle lock" style="padding-top: 2px;">
      <i class="mdi mdi-lock mdi-18px" style="margin-left: 5px;"
        aria-hidden="true" title="Bloquear sessão Ctrl+Alt+L">
        <div class="ajan-help-rtl ajan-help-rtl-focus ajan-help-up"></div>
      </i>
    </div>`,
  ).on("click", Siggma.lockSession).prependTo(
    Yanda.ctr.BT.querySelector(".ajan-brt-tray-icons"),
  );
  options.zettaManager?.temPermissao && jQuery(
    `<div class="ajan-brt-toggle lock" style="padding-top: 2px;">
      <i class="mdi mdi-chart-areaspline mdi-18px" style="margin-left: 5px;"
        aria-hidden="true" title="Abrir Zetta Manager">
        <div class="ajan-help-rtl ajan-help-rtl-focus ajan-help-up"></div>
      </i>
        <div class="ajan-help-rtl ajan-help-rtl-focus ajan-help-up"></div>
      </img>
    </div>`,
  )
    .on("click", () => Yanda.newApl(23))
    .prependTo(Yanda.ctr.BT.querySelector(".ajan-brt-tray-icons"));

  options.smartPdv?.visivel && jQuery(
    `<div class="ajan-brt-toggle lock" style="padding-top: 2px;">
        <i class="mdi mdi-cart mdi-18px" style="margin-left: 5px;"
          aria-hidden="true" title="Abrir Zetta Smart PDV">
          <div class="ajan-help-rtl ajan-help-rtl-focus ajan-help-up"></div>
        </i>
          <div class="ajan-help-rtl ajan-help-rtl-focus ajan-help-up"></div>
        </img>
      </div>`,
  )
    .on("click", () => globalThis.open("https://pdv.zettabrasil.com.br"))
    .prependTo(Yanda.ctr.BT.querySelector(".ajan-brt-tray-icons"));

  jQuery(
    `<div class="ajan-brt-toggle lock" style="padding-right: 1px; min-width: 100px; display:flex;align-items: center;">
    <i class="mdi mdi-book-open-page-variant mdi-24px"></i>
      <span style="font-size: 14px; font-weight: bold">Manual</span>
    </div>`,
  ).on(
    "click",
    () =>
      globalThis.open(
        `${Siggma.basePath}/help/Sistema_Zetta_ERP.html`,
        "_blank",
      ),
  )
    .prependTo(Yanda.ctr.BT.querySelector(".ajan-brt-tray-icons"));

  jQuery(
    `<div class="ajan-brt-toggle" style="padding-right: 1px; min-width: 110px; display:flex;align-items: center;">
      <i class="mdi mdi-whatsapp mdi-24px"></i>
      <span style="font-size: 14px; font-weight: bold">Suporte</span>
    </div>`,
  ).on("click", async () => {
    const suporte = await fetch(`${Siggma.basePath}/app/index/get-suporte`)
      .then((r) => r.json())
      .then((j) => j.data);

    if (!suporte.cpfcnpj) {
      Notify.warning("Responsável pelo suporte não cadastrado");
      return;
    }

    if (!suporte.whatsapp) {
      Notify.warning(
        `Responsável pelo suporte<br><b>${suporte.nome}</b><br>Com WhatsApp inválido`,
      );
      return;
    }
    Siggma.sendWhatsapp({
      phoneSuporte: suporte.whatsapp,
      message:
        `_*[${Siggma.empresa}-${Siggma.filial}]* ${suporte.usuarioNome}_`,
      suporte: true,
    });
  }).prependTo(Yanda.ctr.BT.querySelector(".ajan-brt-tray-icons"));

  const isPet = ["2", "15"].includes(Siggma.modulo);
  const midiasSociais = [
    {
      icon: "mdi-facebook",
      url: `https://www.facebook.com/${isPet ? "ZettaPet" : "ZettaBrasil"}`,
      title: "Facebook",
    },
    {
      icon: "mdi-instagram",
      url: `https://www.instagram.com/${
        isPet ? "zettapet_" : "zettabrasil_sistemas"
      }`,
      title: "Instagram",
    },
    {
      icon: "mdi-linkedin",
      url: `https://www.linkedin.com/company/${
        isPet ? "dr-snoopy" : "zettabrasil-sistemas-de-gest%C3%A3o"
      }`,
      title: "Linkedin",
    },
  ];

  midiasSociais.forEach((midia) => {
    jQuery(
      `<div class="ajan-brt-toggle lock" style="padding-top: 2px;">
        <i class="mdi ${midia.icon} mdi-24px" style="margin-left: 5px;"
          aria-hidden="true" title="${midia.title}">
          <div class="ajan-help-rtl ajan-help-rtl-focus ajan-help-up"></div>
        </i>
          <div class="ajan-help-rtl ajan-help-rtl-focus ajan-help-up"></div>
        </img>
      </div>`,
    ).on("click", () => globalThis.open(midia.url, "_blank")).prependTo(
      Yanda.ctr.BT.querySelector(".ajan-brt-tray-icons"),
    );
  });

  jQuery(
    `<div class="ajan-brt-toggle lock" style="min-width: 170px; display:flex; align-items: center;">
      <i class="mdi mdi-cash-multiple"></i>
      <span style="font-size: 14px; font-weight: bold" title="Indique um novo cliente e ganhe R$ 100,00">
        Indique e Ganhe
      </span>
    </div>`,
  ).on(
    "click",
    () => globalThis.open("https://forms.gle/XBYLJ9R7xbrFv25h6", "_blank"),
  ).prependTo(
    Yanda.ctr.BT.querySelector(".ajan-brt-tray-icons"),
  );

  document.addEventListener("keydown", function (e) {
    // (Ctrl + Alt + L)
    if (e.ctrlKey && e.altKey && e.code === "KeyL") {
      Siggma.lockSession();
      return;
    }

    // (F1)
    if (e.code === "F1") {
      e.preventDefault();
      e.stopPropagation();
      const hdl = Yanda.jansPid.find((j) => j && j.estatus == "FO");
      if (hdl && hdl.janela) {
        const help = hdl.janela
          .querySelector(".ajan-tit")
          .querySelector(".ajan-help-rtl");
        help && help.click();
      }
      return;
    }

    // F4 - abre/esconde agenda
    if (e.code === "F4") {
      stopEvent(e);
      jQuery("#zt-painelAgendaControle, #zt-painelAgendaSimples").trigger(
        "click",
      );
    }

    if (e.shiftKey && e.ctrlKey && e.altKey && e.code === "KeyM") {
      const change = function () {
        Siggma.setUsuariosPreferencias({
          menu: Siggma.painelMenu ? "antigo" : "novo",
        }).then(() => {
          globalThis.onbeforeunload = null;
          location.reload();
        });
      };

      if (!Siggma.painelMenu) {
        change();
        return;
      }
      const hdl = Yanda.confirm(
        true,
        1,
        "Troca de Menu",
        "Tem certeza que deseja alterar para o menu <b>Antigo</b>?<br><br>Esse menu está sendo descontinuado!",
        1,
      );
      hdl.onBeforeClose = () => {
        hdl.returnValue && change();
      };

      jQuery(hdl.janela).find("img").remove();
      jQuery(hdl.$("body")).find("td").first().append(
        `<i class="mdi mdi-emoticon-sad-outline"
          style="font-size: 50px !important;color: #c94743;font-weight: bold; margin-right: 10px;"></i>`,
      );
      jQuery(hdl.janela).find(".btn-primary").removeClass("btn-primary")
        .addClass("btn-danger");
    }
  }, true);

  const $elmConfig = jQuery(".zt-icon-notify").parent().parent();

  Yanda.help.create(
    $elmConfig.find(".ajan-help-rtl"),
    `<div style="font-size: 13px; padding: 8px;">
      <b style="font-size: 15px;">Novas opções de configuração estão disponíveis</b>
      <br><br>
      Configure as opções aqui!
    </div>`,
    true,
  );

  $elmConfig.find(".ajan-help-info").css({ width: "400px" });
  if (!Yanda.help.hide) {
    jQuery(".ajan-brt-toggle.desktop").mouseenter(() =>
      Yanda.help.create(
        jQuery(this).find(".ajan-help-rtl"),
        '<div style="font-size: 13px; padding: 8px;">Minimizar todas janelas    Ctrl+Alt+D</div>',
      )
    ).on("mouseleave", () => jQuery(this).find(".ajan-help-up div").remove());

    jQuery(".ajan-brt-toggle.lock").mouseenter(() =>
      Yanda.help.create(
        jQuery(this).find(".ajan-help-rtl"),
        '<div style="font-size: 13px; padding: 8px;">Bloquear sessão Ctrl+Alt+L</div>',
      )
    ).on("mouseleave", () => jQuery(this).find(".ajan-help-up div").remove());
  }

  // espera carregar menu novo
  Yanda.help.menu && !Yanda.util.isMobile() && setTimeout(function () {
    Siggma.painelMenu && Yanda.help.create(
      jQuery("#zt-menu").parent().find("#zt-menu"),
      `<div style="font-size: 13px; padding: 8px;" class="ajan-help-info-menu-f3">
                <b style="font-size: 15px;">Menu</b>
                <br>Tecla de atalho <b>F3</b>
            </div>`,
      true,
    );

    jQuery(".ajan-help-info-menu-f3").parent().on(
      "touchstart click",
      function (e) {
        e.preventDefault();
        Siggma.setUsuariosPreferencias({ menuHelp: false });
      },
    );
  }, 1000);
}
