<template>
  <div style="display: flex; justify-content: center; align-items: center;">
    <span class="mdi mdi-chevron-double-left mdi-18px s-icon"
      @click.stop="first"></span>
    <span class="mdi mdi-chevron-left mdi-18px s-icon"
      @click.stop="prev"></span>

    <label>Página</label>
    <s-numberinput ref="input" :decimals="0" :value="page" @change="changePage"
      style="width: 50px; text-align: center; margin: 0 5px" />
    <label>de {{ pages }}</label>

    <span class="mdi mdi-chevron-right mdi-18px s-icon"
      @click.stop="next"></span>
    <span class="mdi mdi-chevron-double-right mdi-18px s-icon"
      @click.stop="last"></span>
    <select v-if="!!rows" :value="rows" @change="changeRows">
      <option>10</option>
      <option>15</option>
      <option>20</option>
      <option>30</option>
      <option>40</option>
      <option>50</option>
      <option>100</option>
    </select>
    <label v-if="!!records" style="margin-left: 5px">{{ info }}</label>
  </div>
</template>

<script>
export default {
  name: "s-pagination",

  props: {
    page: {
      type: Number,
      default: 1,
    },
    pages: {
      type: Number,
      default: 1,
    },
    rows: {
      type: Number,
      default: 0,
    },
    records: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    info() {
      if (!this.records) {
        return;
      }

      if (!this.rows) {
        return `${this.records} registros`;
      }

      const inicial = ((this.page - 1) * this.rows) + 1,
        fim = inicial + this.rows - 1,
        final = (fim > this.records) ? this.records : fim;

      return `Ver ${inicial} - ${final} de ${this.records}`;
    },
  },

  watch: {
    records() {
      this.first();
    },
  },

  methods: {
    next() {
      this.pagination(this.page + 1);
    },
    prev() {
      this.pagination(this.page - 1);
    },
    last() {
      this.pagination(this.pages);
    },
    first() {
      this.pagination(1);
    },
    changePage(e) {
      this.pagination(parseInt(e.target.value));
    },
    changeRows(e) {
      this.$emit("update:rows", parseInt(e.target.value));
      this.pagination(1, e.target.value);
    },
    pagination(page, rows = "") {
      !page && (page = 1);
      (page > this.pages) && (page = this.pages);
      (page < 1) && (page = 1);

      this.$emit("update:page", page);
      this.$refs.input.setValue(page);
      ((rows && this.rows !== rows) || this.page !== page) && this.$emit(
        "change",
        page,
      );
    },
  },
};
</script>