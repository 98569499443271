<template>
  <td :style="tableCellStyle" @click="handleClick" class="table-cell">
    <div :class="tableCellContentClass" v-if="head && sortable">
      <slot>
        <span>field_name</span>
      </slot>
      <i :style="!direction ? 'visibility: hidden' : undefined" :class="[
        'table-cell-icon',
        's-icon',
        'mdi',
        direction === 'asc' ? 'mdi-arrow-down' : 'mdi-arrow-up',
        'mdi-18px'
      ]"></i>
    </div>
    <div :class="tableCellContentClass" v-else>
      <slot>
        <span>field_name</span>
      </slot>
    </div>
  </td>
</template>
<script>
export default {
  props: {
    align: {
      type: String,
      default: "start",
    },
    direction: String,
    head: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 350,
    },
    minWidth: {
      type: Number,
      default: 72,
    },
    border: {
      type: Boolean,
      default: false,
    },
    background: String,
  },

  computed: {
    tableCellContentClass() {
      return this.head ? "table-cell-content" : "";
    },
    tableCellStyle() {
      const border = this.border ? "1px solid rgba(0, 0, 0, 0.10)" : "none";
      const background = this.background
        ? `background-color: ${this.background};`
        : "";
      return `
        text-align: ${this.align};
        width: ${this.maxWidth}px;
        max-width: ${this.maxWidth}px;
        min-width: ${this.minWidth}px;
        border-bottom: ${border};
        border-right: ${border};
        ${background}
      `;
    },
  },

  methods: {
    handleClick(e) {
      if (!this.head || !this.sortable) {
        this.$emit("click", e);
        return;
      }
      if (this.direction === "desc") {
        this.$emit("change", "asc");
        return;
      }
      this.$emit("change", "desc");
    },
  },
};
</script>

<style scoped>
.table-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 4px;
}

.table-cell:last-child {
  border-right: none !important;
}

.table-cell-content {
  position: relative;
  user-select: none;
  cursor: pointer;
}

.table-cell-content:hover {
  opacity: 0.6;
}

.table-cell-icon {
  padding: 0;
  margin: -2px 0 0 0;
  position: absolute;
  color: #757575;
}
</style>