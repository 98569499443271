<template>
  <card
    style="padding: 30px 15px 15px; position: relative; max-width: calc(100% - 15px); margin-top: 15px">
    <div class="s-card-box-title">
      {{ title }}
    </div>
    <div style="min-height:100px !important;">
      <slot></slot>
    </div>
  </card>
</template>

<script>
import Card from "./SCard";

export default {
  name: "s-card-box",

  components: {
    Card,
  },

  props: {
    title: String,
  },

  data() {
    return {};
  },

  watch: {},

  methods: {},

  mounted() {},
};
</script>

<style>
.s-card-box-title {
  background-color: var(--s-box-primary-bg-color);
  color: var(--s-box-primary-color);
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
  position: absolute;
  left: 10px;
  top: -14px;
  z-index: 0;
  align-items: center;
  max-width: 300px;
  min-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>