const tipos = [
  { id: "0", text: "Mercadoria para Venda" },
  { id: "1", text: "Materia Prima" },
  { id: "2", text: "Embalagem" },
  { id: "3", text: "Produto em Processo" },
  { id: "4", text: "Produto Acabado" },
  { id: "5", text: "Subproduto" },
  { id: "6", text: "Produto Intermediario" },
  { id: "7", text: "Material de Uso e Consumo" },
  { id: "8", text: "Ativo Imobilizado" },
  { id: "9", text: "Serviço" },
];

export default {
  getTipos() {
    return tipos;
  },
};
