const listeners = new Map();

// A conexão WebSocket utilizada pelo siggma é feita por um SharedWorker
// ou WebWorker, quando o primeiro não estiver disponível.
// De forma geral para enviar um evento para o WebSocket precisamos enviar
// uma mensagem para o Worker que, por sua vez, envia para o websocket.
// Nesse contexto, WsManager surge para expor métodos mais simples para emitir
// e receber eventos da conexão websocket abstraindo a comunicação com o worker.

export class WsManager {
  #workerPort = null;
  isConnected = false;

  constructor(workerPort) {
    this.#workerPort = workerPort;
  }

  emit(type, msg) {
    this.#workerPort.postMessage({
      cmd: "ws",
      data: { cmd: "emit", params: { type, msg } },
    });
  }

  addMessageListener(callback) {
    if (listeners.has(callback)) {
      return;
    }
    const wrapperCallback = (msg) => {
      if (msg.data.fromWs) {
        callback(msg.data);
      }
    };
    listeners.set(callback, wrapperCallback);
    this.#workerPort.addEventListener("message", wrapperCallback);
  }

  removeMessageListener(callback) {
    if (listeners.has(callback)) {
      this.#workerPort.removeEventListener(
        "message",
        listeners.get(callback),
      );
      listeners.delete(callback);
    }
  }
}
