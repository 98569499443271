export default {
  status: {
    "P": { "nome": "Processada", "cor": "#FFFFFF" },
    "E": { "nome": "Enviada", "cor": "#8396EA" },
    "O": { "nome": "Recebida corretamente", "cor": "#6BE38E" },
    "X": { "nome": "Recebida com erros", "cor": "#D87373" },
    "C": { "nome": "Contingência", "cor": "#ECEC7A" },
    "D": { "nome": "Denegada", "cor": "#EE82EE" },
  },
};
