<template>
  <div class="s-tela">
    <div class="s-overlay s-tela-block" @click="toggleMenu = !toggleMenu"
      v-if="breakMenu && toggleMenu"></div>
    <div v-if="!$slots.header && !!$slots.menu && breakMenu"
      class="s-tela-menu-icon" style="position:absolute;top:5px">
      <i class="mdi mdi-menu" style="font-size:25px !important;"
        @click="toggleMenu = !toggleMenu"></i>
    </div>
    <div style="padding: 5px 0;display:flex;align-items:center"
      v-if="!!$slots.header">
      <div v-if="!!$slots.menu && breakMenu" class="s-tela-menu-icon">
        <i class="mdi mdi-menu" style="font-size:25px !important;"
          @click="toggleMenu = !toggleMenu"></i>
      </div>
      <slot name="header"></slot>
    </div>
    <div class="s-tela-body">
      <div ref="menu" class="s-tela-menu" v-if="!!$slots.menu">
        <slot name="menu"></slot>
      </div>
      <div class="s-tela-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["hdl"],

  name: "s-tela",

  data() {
    return {
      toggleMenu: false,
    };
  },

  computed: {
    breakMenu() {
      return this.$mq === "xs" || this.$mq === "sm";
    },
  },

  watch: {
    breakMenu(v) {
      this.$refs.menu?.classList[v ? "add" : "remove"]("s-tela-menu-break");
      !v && this.toggleMenu && (this.toggleMenu = false);
    },
    toggleMenu() {
      this.$refs.menu?.classList.toggle("s-tela-menu-toggle");
    },
  },

  mounted() {
    this.breakMenu && this.$refs.menu?.classList.toggle("s-tela-menu-break");
  },
};
</script>

<style>
.s-tela {
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}

.s-tela-body {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.s-tela-menu {
  overflow: auto;
  min-width: 150px;
  padding: 10px;
}

.s-tela-content {
  flex: 1;
  overflow: hidden;
  position: relative;
  display: flex;
}

.s-tela-menu-break {
  display: none;
}

.s-tela-menu-toggle {
  top: 0;
  left: 0;
  margin: 0;
  height: 100%;
  z-index: 2;
  display: block;
  position: absolute;
  overflow: auto;
  background-color: #f0f0f0;
  box-sizing: border-box;
}

.s-tela-block {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
}

.s-tela-menu-icon {
  cursor: pointer;
  z-index: 1;
  background-color: parent;
  border-radius: 50%;
  padding: 3px 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset, 0 0 5px rgba(34, 34, 34, 0.6);
  background-color: white;
  margin: 0 5px;
}
</style>