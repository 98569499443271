import Vue from "vue";

const id = "siggma-mensagens";

let root;
let vueApp;

const Mensagens = {
  template: `
        <div class="s-mensagens">
            <div class="s-mensagens-block"></div>
            <div class="s-mensagens-box">
                <div class="s-mensagens-content" v-html="msg.content"></div>
                <div class="s-mensagens-footer">
                    <button @click="next">Ler mais tarde</button>
                    <button class="btn-primary" @click="confirmar">Li e entendi</button>
                </div>
            </div>
        </div>
    `,
  props: {
    mensagens: Array,
  },
  data() {
    return {
      current: 0,
    };
  },
  computed: {
    msg() {
      return this.mensagens[this.current];
    },
  },
  methods: {
    next() {
      const idx = this.current + 1;
      if (idx >= this.mensagens.length) {
        finish();
        return;
      }
      this.current = idx;
    },
    confirmar() {
      const id = this.msg.id;
      globalThis.fetch(
        `${globalThis.Siggma.basePath}/config/mensagens/sistema-read?id=${id}`,
      );
      this.next();
    },
  },
};

function finish() {
  vueApp.$destroy();
  root.remove();
  root = null;
  vueApp = null;
}

function createRoot() {
  const d = document.createElement("div");
  d.id = id;
  return d;
}

function showMessages(r) {
  root = globalThis.document.getElementById(id) || createRoot();
  document.body.appendChild(root);

  vueApp = new Vue({
    render(h) {
      return h(Mensagens, { props: { mensagens: r } });
    },
  });
  vueApp.$mount();
  root.appendChild(vueApp.$el);
}

export default function () {
  globalThis.fetch(
    `${globalThis.Siggma.basePath}/config/mensagens/sistema?modulo=${Siggma.modulo}`,
  )
    .then((e) => e.json())
    .then((r) => {
      r.type == "success" && r.data?.length && showMessages(r.data);
    });
}
