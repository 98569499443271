import "element-ui/lib/theme-chalk/index.css";

import Draggable from "vuedraggable";
import ElementUI from "./configElementUI";
import Siggma from "./siggma";
import SiggmaVue from "./siggmaVue";
import Vue from "vue";
import VueMq from "vue-mq";
import locale from "element-ui/lib/locale/lang/pt-br";

// deno-lint-ignore no-process-globals
const isProd = process.env.NODE_ENV === "production";

Vue.config.productionTip = isProd;
Vue.use(VueMq, {
  breakpoints: {
    xs: 600,
    sm: 960,
    md: 1280,
    lg: 1920,
    xl: Infinity,
  },
});

// TODO: remover depências do ElementUI
Vue.use(ElementUI, { locale });

Vue.use(SiggmaVue, Siggma);
Vue.component(Draggable.name, Draggable);
