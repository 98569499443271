export default {
  tabelaSangriaSuprimento: [
    "114", // sangria
    "115", // suprimento
  ],
  contaContabil: {
    credito: "5.01.01.03",
    debito: "6.01.01.01",
  },
  findPlanoConta: {
    credito: [6, 8, 20, 149],
    debito: [6, 8],
  },
};
