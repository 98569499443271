<template>
  <card class="s-card-hover" style="padding: 15px;position:relative">
    <div class="s-card-simple-info" :style="{color}">
      <slot></slot>
    </div>
    <slot name="extra"></slot>
    <div style="font-size: 14px; margin-top: 15px;">{{title}}</div>
  </card>
</template>

<script>
import Card from "./SCard";

export default {
  name: "s-card-simple-info",

  components: {
    Card,
  },

  props: {
    title: String,
    color: {
      type: String,
      default: "#000",
    },
  },

  data() {
    return {};
  },

  watch: {},

  methods: {},

  mounted() {},
};
</script>

<style>
.s-card-simple-info {
  font-weight: bold;
  font-weight: 700;
  font-size: 24px;
  line-height: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: hidden;
}

.s-card-simple-info::after {
  content: "";
  height: 40px;
  width: 6px;
  border-radius: 4px;
  box-shadow: 0 3px 8px 0 rgba(93, 120, 255, .28);
  top: 20px;
  left: -3px;
  position: absolute;
  background: currentColor;
}
</style>