import Yanda from "yanda";

/** @param {object} options {crypt, msg, pwd, liberar} */
export async function controleAcesso(options, janela) {
  const Siggma = globalThis.Siggma;
  if (typeof options !== "object") {
    Notify.warning("Parametros inválidos");
    return;
  }

  if (!options.pwd) {
    options.liberar?.();
    return;
  }

  function setLog(motivo, usuario) {
    // somente registrar na tela de origem
    (globalThis.Yanda.ctr.janelaDes?.uniqid === options.uniqid) && hdl.http({
      url: `${Siggma.basePath}/app/logs/persistir`,
      data: {
        acao: "password",
        chave: janela?.id || 1,
        tabela: options.type || "senha",
        data: JSON.stringify({
          janela: janela?.titulo?.innerText || "Controle de Acesso",
          titulo: options.label,
          mensagem: options.msg,
          motivo,
          usuario,
        }),
      },
      loading: false,
      callback: true,
    });
  }

  async function validarSenha(pwd) {
    if (!pwd) {
      return false;
    }
    const hash = await Siggma.crypto.sha256(pwd);
    return Siggma.usuarioRole === "suporte" || options.pwd === hash;
  }

  const motivoLiberacao = await Siggma
    .getFiliaisPreferencias(["motivoLiberacaoSenha"])
    .then(({ data }) => data.motivoLiberacaoSenha).catch(() => "");

  let liberado = false;
  const hdl = Yanda.newJanela({
    title: "Controle de acesso",
    mode: "new",
    btFe: true,
  });
  const uniqid = hdl.uniqid;
  let btnLiberarClick = false;

  hdl.content.innerHTML = `<div id="body" style="text-align: center;">
    <div style="font-size: 14px; padding: 15px;">
      ${options.msg || "Liberar acesso"}
    </div>
    <label controle-acesso>${options.label || "Senha"}:</label>
    <input controle-acesso type="password" id="pwd" style="margin-right: 3px; margin-bottom: 10px; width: 265px;">
    <textArea
      id="motivo"
      placeholder="Motivo da liberação..."
      style="width: 98%; ${!motivoLiberacao ? "display: none;" : ""}"
      ${motivoLiberacao}
    ></textArea>
  </div>
  <div id="footer" style="text-align: right">
      <button controle-acesso id="btnLiberar" class="btn-primary" style="float: right">Liberar</button>
      <button id="btnCancelar">Cancelar</button>
  </div>`;

  hdl.setTamanho(500, 330);
  hdl.centraliza();
  hdl.setResize(false);

  hdl.onCreate = function () {
    hdl.acptDisableAll = false;
    Yanda.disableAll(true);
  };

  hdl.onShow = () => hdl.$("pwd").select();
  hdl.onClose = () => {
    fetch(
      `${Siggma.basePath}/app/notificacoes/access-control?uniqid=${uniqid}`,
    );
    !liberado && options.bloquear && options.bloquear();
    !btnLiberarClick && options.cancel && options.cancel();
    btnLiberarClick = false;
  };
  hdl.onAfterClose = () => Yanda.disableAll(false);

  hdl.$("pwd").addEventListener("keydown", function (e) {
    (e.keyCode === 13) && hdl.$("btnLiberar").click();
  });

  hdl.$("pwd").addEventListener(
    "keydown",
    (e) => (e.keyCode === 27) && options.cancel && options.cancel(),
  );

  hdl.$("btnCancelar").addEventListener("click", () => hdl.close());
  hdl.$("btnLiberar").addEventListener("click", async () => {
    btnLiberarClick = true;
    const pwd = hdl.$("pwd").value;
    if (!(await validarSenha(pwd))) {
      btnLiberarClick = false;
      Notify.warning("Senha inválida");
      hdl.$("pwd").select();
      return;
    }

    const motivo = hdl.$("motivo").value || "";
    if (motivoLiberacao === "required" && !motivo) {
      Notify.warning("Informe o motivo da liberação");
      hdl.$("motivo").focus();
      return;
    }

    const usuario = hdl.usuario || `${Siggma.usuario} - ${Siggma.usuarioNome}`;
    setLog(motivo, usuario);

    liberado = true;
    setTimeout(() => options.liberar?.({ pwd, motivo, usuario }), 10);
    hdl.close();
  });

  hdl.centraliza();
  hdl.show();

  hdl.setTamanho(500, hdl.$("body").clientHeight + 85);
  hdl.centraliza();

  if (!options.uniqid) {
    options.uniqid = uniqid;
    hdl.http({
      url: `${Siggma.basePath}/app/notificacoes/access-control`,
      data: { data: JSON.stringify(options) },
      loading: false,
      callback: true,
    });
  }
}

export function controleAcessoLiberar(options) {
  document.body.click(); // Fechar janela notificações

  if (options?.agent === "smart-pdv") {
    options.liberar = async ({ pwd, motivo, usuario }) => {
      const data = new FormData();
      data.append("uniqid", options.uniqid);
      data.append("action", "ws-access-control-granted");
      data.append("user", options.usuario);
      data.append("pwd", await globalThis.Siggma.crypto.sha256(pwd));
      data.append("motivo", motivo);
      data.append("usuario", usuario);

      fetch(
        `${globalThis.Siggma.basePath}/app/notificacoes/send-pdv`,
        { method: "post", body: data },
      );
    };
    controleAcesso(options);
    return;
  }

  options.liberar = ({ pwd, motivo, usuario }) => {
    globalThis.Siggma.ws.isConnected && globalThis.Siggma.ws.emit("event", {
      cmd: "access-control-granted",
      data: { pwd, motivo, usuario, uniqid: options.uniqid },
    });
  };
  controleAcesso(options);
}
