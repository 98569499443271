function mouseenter() {
  jQuery(this).find(".zjq-menu").css({ "overflow-y": "auto" });
}

function mouseleave() {
  jQuery(this).find(".zjq-menu").css({ "overflow-y": "hidden" });
}

export default function (options) {
  const Yanda = globalThis.Yanda,
    paineis = [],
    pageSize = function () {
      return new globalThis.Yanda.util.getPageSize();
    };

  let painelBlock = jQuery(document).find("div.painel-block");
  if (!painelBlock.length) {
    painelBlock = jQuery("<div>").css({
      width: "100%",
      bottom: 0,
      height: "100%",
      position: "fixed",
      visibility: "hidden",
      "z-index": "600000",
    }).addClass("painel-block").appendTo(document.body);
    painelBlock.on("click", () => toggleAll());
  }

  function toggleAll() {
    paineis.forEach((painel) => painel.toggleAuto());
  }

  function isFixed(menuFixed) {
    // min (1360, 1024) - (possivel scroll vertical)
    const ps = pageSize();
    return (menuFixed === "full" && ps.windowWidth > 1345) ||
      (menuFixed && ps.windowWidth > 1009);
  }

  function Painel(options) {
    paineis.push(this);
    this.$elm = options.elm;
    this.open = options.menuOpen;
    this.width = options.width;
    this.isOpen = false;
    this.menuFixed = options.menuFixed;

    const toggleShort =
        () => ((this.menuFixed === "short") ? Siggma.zjqMenu.minWidth : 0),
      redraw = () => {
        this.$elm.css({ "max-width": this.width + "px" });

        Yanda.ctr.janMaPosLeft = isFixed(this.menuFixed) && this.isOpen
          ? (toggleShort() || this.width)
          : 0;
        jQuery(Yanda.ctr.BT).css({
          "padding-left": Yanda.ctr.janMaPosLeft + "px",
        });

        Yanda.redraw();
        Yanda.toolbar.refresh();
      };

    this.options = {};

    jQuery.extend(this.options, options);

    this.trigger = function (type, data) {
      jQuery(this).triggerHandler(type, data);
    };

    this.setScrollFixed = (fixed) => {
      // Deixando o scroll padrao quando for mobile. Tarefa #49643
      if (Yanda.util.isMobile()) {
        return;
      }

      fixed
        ? this.$elm.off("mouseenter", mouseenter).off("mouseleave", mouseleave)
          .find(".zjq-menu").css({ "overflow-y": "auto" })
        : this.$elm.on("mouseenter", mouseenter).on("mouseleave", mouseleave)
          .find(".zjq-menu").css({ "overflow-y": "hidden" });
    };
    this.setScrollFixed(false);

    this.setMenuFixed = (menuFixed) => {
      this.width = menuFixed === "full" ? 320 : 65;
      this.menuOpen = true;
      this.menuFixed = menuFixed;
      Siggma.zjqMenu.toggle(this.menuFixed !== "full");
      !this.isOpen && this.toggle(true);
    };

    Siggma.zjqMenu.on("open", function (_e, open) {
      open && painelBlock.css({ visibility: "visible" });
    });

    this.refresh = function () {
      if (this.widthCache === pageSize().windowWidth) {
        return;
      }
      this.widthCache = pageSize().windowWidth;
      this.$elm.css({ "max-width": this.width + "px" });

      (toggleShort() && toggleShort() !== Siggma.zjqMenu.width) &&
        Siggma.zjqMenu.toggle(true);

      if (!isFixed(this.menuFixed)) {
        this.isOpen && this.toggle();
        return;
      }
      (this.isOpen !== this.open) && this.toggle();
    };

    this.on = function (type, data) {
      jQuery(this).on(type, data);
      return this;
    };

    this.toggleAuto = function () {
      const toggle = this.isOpen && !isFixed(this.menuFixed);

      toggle && this.toggle();
      toggleShort() && Siggma.zjqMenu.toggle(true);

      return toggle;
    };

    this.toggle = function (open) {
      if (open && isFixed(this.menuFixed)) {
        this.open = !this.open;
        Siggma.setUsuariosPreferencias({ menuOpen: this.open });
      }

      this.isOpen = !this.isOpen;
      if (this.$elm.css("visibility") === "visible") {
        painelBlock.css({ visibility: "hidden" });
        this.$elm.css({ visibility: "hidden" });
        this.trigger("close");
        redraw();
        return;
      }

      !isFixed(this.menuFixed) && painelBlock.css({ visibility: "visible" });

      this.$elm.css({ visibility: "visible" });
      this.trigger("open");
      redraw();
    };

    this.changeWidth = function (width) {
      this.width = width;
      toggleShort()
        ? painelBlock.css({
          visibility: (this.width !== toggleShort() && this.isOpen
            ? "visible"
            : "hidden"),
        })
        : Siggma.setUsuariosPreferencias({ menuWidth: this.width });
      redraw();
    };
  }
  return new Painel(options);
}
