<template>
  <div
    :class="`s-chamada-telefone-root ${editar && 's-chamada-telefone-editar'}`">
    <div
      :class="`s-chamada-telefone ${editar && 's-chamada-telefone-editar-title'}`"
      @click="clicou">
      <div>
        <span id="tipo" style="width: 20px;margin-right: 10px">
          <i style="position:relative;top: 2px"
            :class="`mdi mdi-phone mdi-18px`"></i>
        </span>
        <div style="width: 180px">{{ formatDate(value.datahora) }} <i
            class="mdi mdi-clock-outline"></i></div>
      </div>
      <div id="cliente" style="flex: 1; text-align: left;min-width: 170px">
        <span class="s-tag s-tag-success"
          :style="`top: 5px;position:relative;${editar ? 'color:#000' : ''}`">
          {{ formatNumero(value.numero) }}
        </span>
      </div>
      <div id="status">
        <div id="observacao">
          <label v-for="p in value.pessoas" :key="p.id">
            <span v-if="p.cliente"
              style="font-weight:bold">{{ p.cliente }}</span>
            {{ p.nome }}
            <span> | </span>
          </label>
        </div>
        <div style="display: inline-block;margin-left: 5px;white-space:nowrap">
          <button v-if="semPessoas" @click.stop="cadastrarCliente"
            type="button">
            <i class="mdi mdi-card-account-details-outline mdi-18px"></i>
          </button>
          <button v-if="editar" type="button" @click="editou">
            <i class="mdi mdi-check-bold"></i>
          </button>
          <span v-if="loading" class="s-chamada-telefone-loading"
            v-loading="loading"></span>
        </div>
      </div>
    </div>
    <div v-if="editar">
      <div style="padding: 20px 40px 10px">
        <textarea style="width: 100%;height: 100px" v-model="value.observacao"
          placeholder="Observação"></textarea>
      </div>
      <div id="pessoas">
        <div id="pessoa" v-for="p in value.pessoas" :key="p.id">
          <div v-if="p.cliente">
            <span>Cliente:</span> <span>{{p.cliente}}</span>
            <button @click.stop="pedido(p.cliente, 61)" type="button">
              <i class="mdi mdi-plus-thick mdi-18px">Pedidos/Notas</i>
            </button>
            <button @click.stop="pedido(p.cliente, 76)" type="button">
              <i class="mdi mdi-plus-thick mdi-18px">Venda Balcão</i>
            </button>
          </div>
          <div>
            <span>Nome:</span> <span>{{p.nome}}</span>
          </div>
          <div>
            <span>Endereço:</span> <span>{{p.endereco}}, {{p.numero}}</span>
          </div>
          <div>
            <span>Bairro:</span> <span>{{p.bairro}}</span>
          </div>
          <div>
            <span>Município:</span> <span>{{p.municipio}}</span>
          </div>
          <div>
            <span>CEP:</span> <span>{{p.cep}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Inputmask from "inputmask";

export default {
  name: "s-telefone-chamada",

  inject: ["hdl"],

  props: {
    value: Object,
    editar: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    semPessoas() {
      return this.value && this.value.id && !this.value.pessoas.length;
    },
  },

  methods: {
    formatDate(date) {
      return date; // moment(date).format('DD/MM/YYYY hh:mm:ss');
    },

    formatNumero(numero) {
      numero += "";
      if (numero.length >= 10) {
        return Inputmask({
          mask: [
            "(99) 9999-9999",
            "(99) 99999-9999",
          ],
        }).format(numero);
      }

      return Inputmask({
        mask: [
          "9999-9999",
          "99999-9999",
        ],
      }).format(numero);
    },

    formatPessoas() {
      if (!this.value.pessoas.length) {
        return "";
      }
    },

    getPessoas() {
      this.loading = true;
      const post = `numero=${this.value.numero}`;
      this.hdl.ajax(
        false,
        `${window.Siggma.basePath}/app/telefones-chamadas/get-pessoas-by-telefone`,
        post,
        (xhr) => {
          this.loading = false;
          const resp = JSON.parse(xhr.responseText);
          if (resp.exception) {
            Yanda.alert(true, "Alerta", resp.msg, false, resp.exception);
            return;
          }

          if (resp.type === "success") {
            this.value.pessoas = resp.data;
            this.$emit("update:value", this.value);
            return;
          }

          window.Notify[resp.type](resp.msg);
        },
      );
    },

    persist() {
      this.loading = true;
      const post = jQuery.param(this.value);
      this.hdl.ajax(
        false,
        `${window.Siggma.basePath}/app/telefones-chamadas/persistir`,
        post,
        (xhr) => {
          this.loading = false;
          const resp = JSON.parse(xhr.responseText);
          if (resp.exception) {
            window.Yanda.alert(
              true,
              "Alerta",
              resp.msg,
              false,
              resp
                .exception,
            );
            return;
          }

          if (resp.type === "success") {
            this.$emit("input", resp.data);
            return;
          }

          window.Notify[resp.type](resp.msg);
        },
      );
    },

    clicou() {
      this.$emit("on-editar-open", this.value);
    },

    editou() {
      this.persist();
    },

    pedido(cliente, tela) {
      window.Yanda.newApl(tela, {
        cliente: cliente,
      }, this.hdl);
    },

    cadastrarCliente() {
      window.Yanda.newApl(
        46,
        {
          telefone: this.formatNumero(this.value.numero),
        },
        this.hdl,
        (hdl) => {
          hdl.onBeforeClose = () => this.getPessoas();
        },
      );
    },
  },

  mounted() {
    if (!this.value.id) {
      this.persist();
    }
    // !this.value.pessoas.length && this.getPessoas();
  },
};
</script>

<style>
.s-chamada-telefone-root {
  width: calc(100% - 20px);
  margin: 0 auto 2px;
  position: relative;
  max-width: 1200px;
  transition: margin .5s;
  box-shadow: 0 1px 3px #c1c1c1;
  border-radius: 5px;
  background-color: #fff;
}

.s-chamada-telefone-editar {
  width: calc(100% - 5px);
  margin: 30px auto;
  max-width: 1220px;
}

.s-chamada-telefone-editar-title {
  background: rgb(75, 217, 138);
}

.s-chamada-telefone {
  cursor: pointer;
  margin: 0 auto 2px;
  display: flex;
  padding: 5px;
  position: relative;
  flex-flow: row nowrap;
  min-height: 0;
}

.s-chamada-telefone>div {
  display: flex;
  line-height: 40px;
}

.s-chamada-telefone #tipo {
  color: black;
  padding: 0 8px;
  text-align: center;
  background: rgb(75, 217, 138) none repeat scroll 0% 0%;
  font-weight: bold;
  text-shadow: none;
  border-radius: 8px;
  text-transform: capitalize;
}

.s-chamada-telefone #cliente {
  order: 1;
  overflow: hidden;
  min-width: 120px;
  white-space: nowrap;
}

.s-chamada-telefone #status {
  order: 2;
  display: flex;
  overflow: hidden;
  margin-left: 3px;
  justify-content: flex-end;
}

.s-chamada-telefone-root #observacao {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.s-chamada-telefone-root #observacao label {
  cursor: pointer;
}

.s-chamada-telefone-root #pessoas {
  display: flex;
  padding: 10px 40px 20px;
  flex-wrap: wrap;
}

.s-chamada-telefone-root #pessoa {
  flex: 1;
  border: 1px solid #c7c7cc;
  padding: 10px 20px;
  min-width: 300px;
  text-align: center;
  border-radius: 5px;
}

.s-chamada-telefone-root #pessoa>div {
  flex: 1;
  text-align: left;
  line-height: 20px;
  margin-right: 5px;
}

.s-chamada-telefone-root #pessoa>div span:first-child {
  display: inline-block;
  min-width: 60px;
  text-align: right;
  font-weight: bolder;
}

.s-chamada-telefone-loading {
  top: 10px;
  width: 30px;
  height: 30px;
  display: inline-block;
}

.s-chamada-telefone-loading .el-loading-spinner .circular {
  width: 30px;
  height: 30px;
}

.s-chamada-telefone-loading .el-loading-spinner .path {
  stroke-width: 2;
}

@media (max-width: 800px) {
  .s-chamada-telefone {
    flex-flow: column;
  }

  .s-chamada-telefone #cliente {
    order: 2;
    overflow: unset;
  }

  .s-chamada-telefone>div:first-child {
    flex: 1;
  }

  .s-chamada-telefone #status {
    flex: 1;
    order: 1;
    min-width: 300px;
  }

  .s-chamada-telefone-root #pessoa {
    flex-flow: column-reverse;
  }
}
</style>