import Yanda from "yanda";

export default function (Siggma, config) {
  Yanda.ini(`${Siggma.basePath}/app/yanda`, config.urlAssets);
  Yanda.prefix = `${Siggma.basePath}:`.replace("/", "");
  Yanda.basePath = Siggma.basePath; // utilizado em Toolbar|Help
  Yanda.help.menu = config.menuHelp;
  Yanda.help.hide = config.helpJaEntendi;
  Yanda.ctr.janMaPosTop = config.isMenuNovo ? 41 : 53;
  Yanda.ctr.janPosTopMax = config.isMenuNovo ? 41 : 53;
  Yanda.ctr.janMaPosLeft = 0;
  Yanda.ctr.update();

  Yanda.toolbar = new Yanda.newToolbar();
  Yanda.toolbar.load(config.toolbar);
  Yanda.cache.setAppsChange(config.yandaUpdate);

  const telaDefaultConsulta = Yanda.telaDefaultConsulta;
  Yanda.telaDefaultConsulta = function (options, template = null) {
    const _hdl = telaDefaultConsulta(options, template);

    // Subscreve método original que ainda utiliza o printThis
    // TODO: Trazer todo o código das telas default do yanda para o erp assim
    // que possível.
    _hdl.imprimeDadosGrid = function () {
      const $elm = jQuery(_hdl.$(`gview_${options.gridId}`)).clone();
      $elm.find(".ui-jqgrid-sdiv").remove();

      $elm.find("td").css({
        "border-bottom": "solid 1px",
        "border-left": "solid 1px",
      });
      $elm.find("tr > td:last-child").css("border-right", "solid 1px");

      const title = _hdl.options.title.replace(/<[^>]*>?/gm, "");
      const html = `
          <div>
            <table style='border: solid 1px; border-spacing: 0px; width: 100%;
              border-radius:4px; margin-bottom:20px;'>
              <tr><td style='text-align: center;'><b>${title}</b></td></tr>
            </table>
            ${$elm.html()}
          </div>
        `;

      const el = document.createElement("div");
      el.innerHTML = html;
      new Siggma.utils.Printd().print(el);
    };

    return _hdl;
  };
}
