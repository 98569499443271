<template>
  <div>
    <div class="s-card" style="margin: 0; display: flex; align-items: center;">
      <label style="flex: 1;">{{ grupo.name }}</label>

      <div v-if="showInput" style="display: flex; align-items: center;">
        <input v-model="input" @keyup.enter="adicionar" class="s-card"
          @keydown.enter.prevent>
        <i class="s-icon mdi mdi-plus mdi-18px" @click="adicionar"></i>
        <i class="s-icon mdi mdi-close mdi-18px" @click="fechar"></i>
      </div>
      <div v-else style="display: flex; align-items: center;">
        <i class="s-icon mdi mdi-plus-circle-outline mdi-18px"
          @click="showInput = true"></i>
        <i class="s-icon mdi mdi-backspace mdi-18px" style="float: right;"
          @click="$emit('remover')"></i>
      </div>
    </div>

    <ul style="list-style-type: none;">
      <li v-for="(item, i) in grupo.items" :key="item.id">
        <div class="s-box-line">
          <label
            :style="`flex: 1; text-decoration: ${item.value ? 'line-through' : 'none'};`">
            <input v-model="item.value" type="checkbox">
            {{ item.name }}
          </label>
          <i class="s-icon mdi mdi-backspace-outline mdi-18px"
            @click="remover(i)"></i>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    grupo: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      input: "",
      showInput: false,
    };
  },

  methods: {
    remover(index) {
      this.grupo.items.splice(index, 1);
    },

    adicionar() {
      if (this.input != "") {
        this.grupo.items.push({
          id: `uuid${Math.uuid(8)}`,
          name: this.input,
          value: false,
        });
      }
      this.fechar();
    },

    fechar() {
      this.input = "";
      this.showInput = false;
    },
  },
};
</script>