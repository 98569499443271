let connection = null;

function connect(basePath) {
  if (connection) {
    return Promise.resolve(connection);
  }

  return new Promise((resolve, reject) => {
    const request = globalThis.indexedDB.open(
      `${basePath}`.replace("/", ""),
      2,
    );
    request.onupgradeneeded = function (event) {
      const db = event.target.result;
      (event.oldVersion < 1) && db.createObjectStore("apps", { keyPath: "id" });
      (event.oldVersion < 2) && db.createObjectStore("_", { keyPath: "id" });
    };
    request.onerror = (event) => reject(event);
    request.onsuccess = (event) => resolve(event.target.result);
  }).then((conn) => connection = conn);
}

function get(table, id) {
  return new Promise(function (resolve, reject) {
    const objectStore = connection.transaction([table]).objectStore(table);
    const request = objectStore.get(id);
    request.onerror = (event) => reject(event);
    request.onsuccess = () => resolve(request.result);
  });
}

function set(table, data) {
  return new Promise(function (resolve, reject) {
    const transaction = connection.transaction([table], "readwrite");
    transaction.onerror = (event) => reject(event);
    transaction.oncomplete = () => resolve();

    const objectStore = transaction.objectStore(table);
    const request = objectStore.get(data.id);

    request.onsuccess =
      () => (request.result ? objectStore.put(data) : objectStore.add(data));
  });
}

export default { connect, get, set };
