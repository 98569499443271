<template>
  <tr :class="computedClass" :style="computedStyle"
    @click="$emit('click', $event)">
    <slot></slot>
  </tr>
</template>

<script>
export default {
  props: ["header", "selected", "disabled"],

  computed: {
    computedClass() {
      return this.selected ? "table-row selected" : "table-row";
    },
    computedStyle() {
      return `opacity: ${this.disabled ? "0.5" : "1"}`;
    },
  },
};
</script>

<style scoped>
.table-row {
  height: 38px;
  font-size: 0.75rem;
}

.table-row td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.table-row:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.selected {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
</style>