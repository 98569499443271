const NOVO = "novo";
const CANCELADO = "cancelado";
const FINALIZADO = "finalizado";
const TRANSFERIDO = "transferido";
const EM_ANDAMENTO = "em andamento";
const APLICADO = "aplicado";
const A_APLICAR = "a aplicar";

class Status {
  static get NOVO() {
    return NOVO;
  }
  static get EM_ANDAMENTO() {
    return EM_ANDAMENTO;
  }
  static get FINALIZADO() {
    return FINALIZADO;
  }
  static get CANCELADO() {
    return CANCELADO;
  }
  static get TRANSFERIDO() {
    return TRANSFERIDO;
  }
  static get A_APLICAR() {
    return A_APLICAR;
  }
  static get APLICADO() {
    return APLICADO;
  }

  static all() {
    return [
      NOVO,
      EM_ANDAMENTO,
      FINALIZADO,
      CANCELADO,
      TRANSFERIDO,
      A_APLICAR,
      APLICADO,
    ];
  }
}

const CIRURGIA = "cirurgia";
const CLINICO = "clínico";
const DAY_CARE = "day care";
const EM_ESPERA = "em espera";
const ESTETICO = "estético";
const EXTERNO = "externo";
const GERAL = "geral";
const HOSPEDAGEM = "hospedagem";
const INTERNACAO = "internação";
const PESAGEM = "pesagem";
const RECEITUARIO = "receituário";
const VACINA = "vacina";
const VERMIFUGO = "vermífugo";

const tiposServicos = [
  CIRURGIA,
  CLINICO,
  DAY_CARE,
  ESTETICO,
  EXTERNO,
  GERAL,
];

const tipos = {
  [CIRURGIA]: { "color": "#FC07CA", "icon": "heart-pulse" },
  [CLINICO]: { "color": "#F5AF59", "icon": "medical-bag" },
  [DAY_CARE]: { "color": "#7FFF00", "icon": "hand-heart" },
  [EM_ESPERA]: { "color": "#FF1053", "icon": "clock-outline" },
  [ESTETICO]: { "color": "#4BD98A", "icon": "auto-fix" },
  [EXTERNO]: { "color": "#9D1EDF", "icon": "ambulance" },
  [GERAL]: { "color": "#7DCAF0", "icon": "earth" },
  [HOSPEDAGEM]: { "color": "#9C735C", "icon": "home" },
  [INTERNACAO]: { "color": "#E05207", "icon": "hospital-building" },
  [PESAGEM]: { "color": "#0DAAC1", "icon": "scale-balance" },
  [RECEITUARIO]: { "color": "#AF5CC1", "icon": "notebook-multiple" },
  [VACINA]: { "color": "#EEEE3B", "icon": "eyedropper" },
  [VERMIFUGO]: { "color": "#4B8F8C", "icon": "pill" },
};

const controleAcesso = async function (data) {
  const isFinalizado = [FINALIZADO, APLICADO].includes(data.statusOld);

  if (!isFinalizado && data.statusOld === data.statusNew) {
    return new Promise((resolve) => resolve());
  }

  const senhas = await Siggma.getFiliaisPreferencias([
    "senhaPetshopEditarFinalizado",
    "senhaPetshopCancelar",
    "senhaPetshopExcluir",
  ]).then(({ data }) => data).catch(() => {});

  return new Promise((resolve, reject) => {
    const params = { pwd: "", type: "", msg: "" };

    if (isFinalizado) {
      params.type = "senha_petshop_editar_finalizado";
      params.msg =
        `Informe a senha para editar atendimento já ${data.statusOld}`;
      params.pwd = senhas?.senhaPetshopEditarFinalizado;
    }

    if (data.statusNew === CANCELADO) {
      params.type = "senha_petshop_cancelar";
      params.msg = "Informe a senha para cancelar o atendimento";
      params.pwd = senhas?.senhaPetshopCancelar;
    }

    if (data.statusNew === "excluido") {
      params.type = "senha_petshop_excluir";
      params.msg = "Informe a senha para excluir o atendimento";
      params.pwd = senhas?.senhaPetshopExcluir;
    }

    if (!params.pwd) {
      resolve();
      return;
    }

    params.liberar = resolve;
    params.bloquear = reject;
    Siggma.controleAcesso(params, data.hdl);
  });
};

export default {
  tipo: (t) => tipos[t],
  tipos,
  tiposServicos,
  tiposKeys: {
    CIRURGIA,
    CLINICO,
    DAY_CARE,
    EM_ESPERA,
    ESTETICO,
    EXTERNO,
    GERAL,
    HOSPEDAGEM,
    INTERNACAO,
    PESAGEM,
    RECEITUARIO,
    VACINA,
    VERMIFUGO,
  },

  controleAcesso,
  status: Status,
  tiposHistoricos() {
    const Siggma = globalThis.Siggma;
    const tps = [];
    for (const name in tipos) {
      const obj = this.tipos[name];
      const css = {
        alignItems: "center",
        background: obj.color,
        border: "1px solid",
        borderColor: Siggma.Color(obj.color).darken(0.3).string(),
        borderRadius: "50%",
        color: Siggma.getContrastYiq(obj.color),
        display: "inline-flex",
        height: "28px",
        justifyContent: "center",
        margin: "0 5px",
        padding: "0 0",
        width: "28px",
      };

      ![EM_ESPERA].includes(name) && tps.push({ name, css, icon: obj.icon });
    }
    return tps;
  },
};
