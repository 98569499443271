<template>
  <div></div>
</template>

<script>
export default {
  name: "s-image-upload",

  inject: ["hdl"],

  props: {
    value: Object,
    title: String,
    url: {
      type: String,
      default() {
        return `${this.$siggma.basePath}/data-files/${this.$siggma.empresa}/`;
      },
    },
    validFileType: Function,
    draw: Boolean,
    accept: String,
  },

  data() {
    return {
      urlTmp: `${this.$siggma.basePath}/data-files/tmp/`,
    };
  },

  watch: {
    value(n, old) {
      (n?.imagem !== old?.imagem) && this.setImage();
    },
  },

  methods: {
    onImageChange() {
      const value = this.internal.getImagem();
      this.$emit("input", value === true ? { deleted: true } : value);
    },
    setImage() {
      this.internal.setImagem(
        this.value?.tmp ? this.urlTmp : this.url,
        this.value,
      );
    },
  },

  beforeDestroy() {
    this.$el.removeEventListener("imagechange", this.onImageChange);
    this.internal?.destroy();
  },

  async mounted() {
    this.$el.addEventListener("imagechange", this.onImageChange);

    const { default: files } = await globalThis.Siggma.import("files.js");
    this.internal = files.imagens({
      url: this.urlTmp,
      dad: this.hdl,
      $elm: jQuery(this.$el),
      title: this.title,
      width: 760,
      height: 300,
      download: true,
      validFileType: this.validFileType,
      draw: this.draw,
      accept: this.accept,
    });
    this.setImage();
  },
};
</script>