<template>
  <div class="data-table">
    <table-container>
      <div v-if="!items.length" class="table-empty">Nenhum registro listado
      </div>

      <table-content :striped="striped" v-else>
        <table-head v-if="showHead">
          <table-check-box :checked="isAllSelected"
            :disabled="!multiSelect && !value.length"
            :partial-checked="isPartialSelected"
            @click="handleHeadCheckboxClick" v-if="showSelect" />
          <table-cell :direction="getSortedDirection(col.sort)"
            :align="col.align" :border="!!col.border"
            :background="col.background" :key="`table-head-cell-${col.value}`"
            :sortable="!!col.sort" :max-width="col.maxWidth"
            :min-width="col.minWidth"
            @change="handleDirectionChange($event, col.sort)" head
            v-for="col in columns.filter((c) => !c.hidden)">
            {{ col.text }}
          </table-cell>
        </table-head>
        <table-row :disabled="isDisabled ? isDisabled(item) : false"
          :key="`table-row-${getItemKey(item, index)}`"
          :selected="isSelected(item, index)" v-for="(item, index) in items"
          @click="handleClick(item, index, true)">
          <table-check-box v-if="showSelect" :checked="isSelected(item, index)"
            @click.stop="handleClick(item, index)" />
          <table-cell v-for="col in columns.filter((c) => !c.hidden)"
            :key="`table-row-cell-${getItemKey(item, index)}-${col.value}`"
            :align="col.align" :border="!!col.border"
            :background="col.background" :max-width="col.maxWidth"
            :min-width="col.minWidth">
            <slot name="item" :item="item" :index="index" :column="col">
              <span :title="col.format?.(item) || item[col.value]">
                {{ col.format?.(item) || item[col.value] }}
              </span>
            </slot>
          </table-cell>
        </table-row>
      </table-content>
    </table-container>
    <slot name="pagination"></slot>
  </div>
</template>

<script>
import TableContainer from "./TableContainer";
import TableContent from "./TableContent";
import TableCell from "./TableCell";
import TableCheckBox from "./TableCheckBox";
import TableHead from "./TableHead";
import TableRow from "./TableRow";

export default {
  name: "s-data-table",

  props: {
    value: Array,
    /**
     * {
     *     text: string;
     *     align?: 'start' | 'center' | 'end';
     *     sort?: boolean;
     *     value: string;
     *     minWidth?: number;
     *     maxWidth?: number;
     * }
     */
    columns: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemKey: String,
    /**
     * Uses the `.sync` modifier
     */
    sortBy: String | Array,
    /**
     * Uses the `.sync` modifier
     */
    sortDesc: Boolean | Array,
    multiSelect: {
      type: Boolean,
      default: false,
    },
    multiSort: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    isDisabled: Function,
    striped: {
      type: Boolean,
      default: false,
    },
    showHead: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableContent,
    TableCheckBox,
  },

  data: () => ({
    waitForDoubleClick: false,
    timeout: -1,
  }),

  computed: {
    isAllSelected() {
      return this.items.length === this.value?.length;
    },

    isPartialSelected() {
      return this.value?.length > 0;
    },
  },

  methods: {
    getItemKey(item, index) {
      return this.itemKey ? item[this.itemKey] : index;
    },

    isSelected(item, index) {
      const key = this.getItemKey(item, index);
      if (!this.value) {
        return false;
      }
      return this.value.indexOf(key) >= 0;
    },

    getSortedDirection(key) {
      if (!this.sortBy?.length) {
        return null;
      }
      if (!this.multiSort) {
        return this.sortBy === key ? this.sortDesc ? "desc" : "asc" : null;
      }
      const index = this.sortBy.indexOf(key);
      if (index < 0) {
        return null;
      }
      return this.sortBy[index] ? "desc" : "asc";
    },

    handleDirectionChange(event, key) {
      const { sortBy, sortDesc } = this.getNextSorted(key, event);
      this.$emit("update:sort-by", sortBy);
      this.$emit("update:sort-desc", sortDesc);
      this.$emit("change-sort", { sortBy, sortDesc });
    },

    handleSelected(item, index) {
      if (this.isDisabled && this.isDisabled(item)) {
        return;
      }
      const key = this.getItemKey(item, index);
      if (!this.value) {
        return;
      }
      const nextValue = this.getNextSelected(this.value, key);
      this.$emit("input", nextValue);
    },

    getNextSelected(next, key) {
      const index = next.indexOf(key);
      if (!this.multiSelect) {
        return index >= 0 ? [] : [key];
      }
      if (index >= 0) {
        next = next.filter((v) => v !== key);
      } else {
        next.push(key);
      }
      return next;
    },

    getNextSorted(key, event) {
      if (!this.multiSort) {
        return {
          sortBy: !event ? null : key,
          sortDesc: !event ? null : event === "desc",
        };
      }

      let sortBy = this.sortBy || [];
      let sortDesc = this.sortDesc || [];
      const index = sortBy.indexOf(key);

      if (index < 0) {
        sortBy.push(key);
        sortDesc.push(true);
      } else {
        if (!event) {
          sortBy = sortBy.filter((v) => v !== key);
          sortDesc = sortDesc.filter((_, i) => i !== index);
        } else {
          sortDesc[index] = event === "desc";
        }
      }
      return {
        sortBy: JSON.parse(JSON.stringify(sortBy)),
        sortDesc: JSON.parse(JSON.stringify(sortDesc)),
      };
    },

    handleHeadCheckboxClick() {
      if (!this.multiSelect) {
        this.value?.length && this.$emit("input", []);
        return;
      }
      if (this.value?.length) {
        this.$emit("input", []);
        return;
      }
      const next = this.items.map((v, i) => this.getItemKey(v, i));
      this.$emit("input", next);
    },

    handleClick(item, index, double = false) {
      if (!double) {
        clearTimeout(this.timeout);
        this.waitForDoubleClick = false;
        this.handleSelected(item, index);
        return;
      }

      clearTimeout(this.timeout);

      if (this.waitForDoubleClick) {
        this.waitForDoubleClick = false;
        this.$emit("dblclick", { item, index });
        return;
      }

      this.waitForDoubleClick = true;

      this.timeout = setTimeout(() => {
        this.waitForDoubleClick = false;
        this.handleSelected(item, index);
      }, 250);
    },
  },
};
</script>

<style scoped>
.data-table {
  display: flex;
  flex: 1;
  flex-direction: column;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: white;
  overflow: auto;
}

.table-empty {
  text-align: center;
  margin: 30px;
  color: var(--s-text-color);
}

.shadow-2dp {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.20), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
</style>