<template>
  <!--
    O evento de click teve que ser feito dessa forma por que utilizando o componente
    dentro da tela de estéticos que utiliza o component draggable não funcionava.
    Por algum motivo o componente draggable inutiliza o evento
    -->
  <span class="s-icon-aviso mdi mdi-bell" :style="`color: ${color}`"
    @mousedown.prevent.stop="clicked" @click.stop=""></span>
</template>

<script>
export default {
  name: "s-icon-aviso",

  props: {
    color: {
      type: String,
      default: "#000",
    },
  },

  methods: {
    clicked() {
      this.$emit("click");
    },
  },
};
</script>

<style>
.s-icon-aviso {
  display: inline-block;
  position: relative;
  font-size: 16px;
  transform-origin: 50% 4px;
  cursor: pointer;
  animation: ring 4s .7s ease-in-out infinite;
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}
</style>