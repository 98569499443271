const id = "s-popup-overlay";

const overlay = {
  open() {
    const div = document.createElement("div");
    div.id = id;
    div.style.cssText = `
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            z-index: 1000;
        `;
    document.body.appendChild(div);
  },
  close() {
    const div = document.getElementById(id);
    div && div.parentNode.removeChild(div);
  },
};

export default overlay;
