<script>
import overlay from "./overlay";

let popupOpen = null;

export default {
  name: "s-popup",
  template: `<div class="s-popup" @click.stop>
        <div ref="seta" class="s-popup-seta"></div>
        <slot></slot>
    </div>`,
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
    movTop: {
      type: Number,
      default: 0,
    },
    movDown: {
      type: Number,
      default: 0,
    },
    movLeft: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  watch: {
    visible(v) {
      if (v) {
        this.open();
        this.$nextTick(() => this.updatePopup());
      } else {
        this.close();
      }
    },
  },
  methods: {
    onDocClick() {
      this.$emit("update:visible", false);
    },
    open() {
      if (popupOpen && popupOpen !== this) {
        popupOpen.close();
        popupOpen.$emit("update:visible", false);
      }
      const divPopup = this.$el;
      if (!this.appendToBody) {
        divPopup.style.right = 0;
        divPopup.style.top = "40px";
      }
      this.$el.style.display = "block";
      document.addEventListener("click", this.onDocClick, {
        once: true,
      });

      overlay.open();
      popupOpen = this;
    },
    updatePopup() {
      const divPopup = this.$el;
      if (this.appendToBody) {
        const elmRef = this.$parent.$el;
        const elmRect = elmRef.getBoundingClientRect();
        const popupRect = divPopup.getBoundingClientRect();

        let top = elmRect.top + elmRect.height + 10 + this.movTop;
        let left = elmRect.left + elmRect.width - popupRect.width + this
          .movLeft;
        const bodyRect = document.body.getBoundingClientRect();
        this.$refs.seta.className = "s-popup-seta";

        if ((top + popupRect.height) > bodyRect.height) {
          top = (elmRect.top - elmRect.height) - popupRect.height + this
            .movDown;
          this.$refs.seta.className = "s-popup-seta-down";
        }
        if (left < 0) {
          left = 0;
          this.$refs.seta.style.left = "10px";
          this.$refs.seta.style.right = "unset";
        }

        divPopup.style.left = `${left}px`;
        divPopup.style.top = `${top}px`;
      }
    },
    close() {
      overlay.close();
      this.$el.style.display = "none";
      document.removeEventListener("click", this.onDocClick);
    },
  },

  mounted() {
    if (this.appendToBody) {
      document.body.appendChild(this.$el);
    }
  },

  destroyed() {
    overlay.close();
    if (popupOpen === this) {
      popupOpen = null;
      this.$emit("update:visible", false);
    }
    if (this.$el && this.$el.parentNode && this.appendToBody) {
      this.$el.parentNode.removeChild(this.$el);
    }
  },
};
</script>

<style>
.s-popup {
  position: absolute;
  top: 0;
  z-index: 1001;
  display: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  flex: 1;
  padding: 5px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.2);
}

.s-popup-seta {
  border-color: transparent;
  border-bottom-color: var(--s-text-color);
  border-style: dashed dashed solid;
  border-width: 0 10px 10px;
  width: 0;
  height: 0;
  position: absolute;
  top: -10px;
  right: 10px;
}

.s-popup-seta-down {
  border-color: transparent;
  border-top-color: var(--s-text-color);
  border-style: dashed dashed solid;
  border-width: 10px 10px 0;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -10px;
  right: 10px;
}
</style>