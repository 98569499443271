// Funcao para arredondar os valores conforme regras da ABNT

// Exemplos de valores e resultados retornados pela funcao
//  Valor  | Resultado
//  0.342  |   0.34
//  0.346  |   0.35
//  0.3452 |   0.35
//  0.3450 |   0.34
//  0.332  |   0.33
//  0.336  |   0.34
//  0.3352 |   0.34
//  0.3350 |   0.34
//  0.3050 |   0.30
//  0.3150 |   0.32

// Precisa passar 2 parametros - valor a ser arredondado e quantas casas decimais deve retornar
function roundAbnt(valor, qtdDecimais) {
  const spl = valor.toString().split(".");
  const cDecimais = spl[1];
  const nSubsequente = qtdDecimais;

  if (qtdDecimais < 1) {
    return parseInt(valor);
  }

  if (!cDecimais || cDecimais.length <= qtdDecimais) {
    return parseFloat(valor);
  }

  let nRetorno = valor;

  // Se a casa decimal SUBSEQUENTE for DIFERENTE de 5
  if (
    cDecimais.substr(nSubsequente, 1) > "5" ||
    cDecimais.substr(nSubsequente, 1) < "5"
  ) {
    nRetorno = Number(nRetorno).toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
  } // Se a casa decimal SUBSEQUENTE for IGUAL a 5
  else if (cDecimais.substr(nSubsequente, 1) == "5") {
    // Se a casa decimal que será CONSERVADA, for IMPAR
    if ((cDecimais.substr(qtdDecimais - 1, 1) % 2) != 0) {
      nRetorno = Number(nRetorno).toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
    } // Se a casa decimal que será CONSERVADA, for PAR
    // Se APÓS a casa decimal SUBSEQUENTE, houver ALGUM algarismo MAIOR que ZERO
    else if (cDecimais.substr(nSubsequente + 1, 1) > 0) {
      nRetorno = Number(nRetorno).toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
    } // Se APÓS a casa decimal SUBSEQUENTE, não houver NENHUM outro algarismo ou TODOS forem iguais a ZERO
    else {
      // TRUNCA (Esse é o único momento em que o "arredondamento ABNT" se diferencia do "arredondamento normal")
      nRetorno = truncate(valor, qtdDecimais);
    }
  }

  return parseFloat(nRetorno);
}

function truncate(valor, qtdDecimais) {
  const spl = valor.toString().split(".");
  const cDecimais = spl[1];

  if (qtdDecimais < 1) {
    return parseInt(valor);
  }

  if (cDecimais.length <= qtdDecimais) {
    return valor;
  }

  // Pega a parte inteira do número e concatena com a substring sem alterar, pois é PAR e vai manter!
  const nRetorno = parseInt(valor.toString()) + "." +
    cDecimais.substr(0, qtdDecimais);

  return parseFloat(nRetorno);
}

export default roundAbnt;
