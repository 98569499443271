<template>
  <div class="s-card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "s-card",
};
</script>