let boxMessages = [];
const config = {
  maxBoxMessage: 4,
};
const bottom = 30;

function BoxMessage(type, message, time) {
  this.dom = createDOM();

  this.dom.className = "notify notify-" + type;
  this.dom.getElementsByTagName("img")[0].src = globalThis.Siggma.basePath +
    "/img/notify/" + type +
    ".png";
  const text = this.dom.getElementsByTagName("div")[0];
  text.innerHTML = message;
  //div.style.height = (70 + text.offsetHeight) + "px";

  this.dom.onclick = (function () {
    clearInterval(this.timer);
    this.hidden();
  }).bind(this);

  if (time > 0) {
    this.timer = setTimeout(
      (function () {
        this.hidden();
      }).bind(this),
      time,
    );
  }

  this.show = function () {
    this.dom.style.visibility = "visible";
    //this.dom.style["-webkit-animation"] = "fadein 0.2s";
  };

  this.hidden = function () {
    this.dom.style.visibility = "hidden";
    //this.dom.style["-webkit-animation"] = "fadeout 0.2s";
    //setTimeout((function () {
    this.destroy();
    //}).bind(this), 200);
  };

  this.destroy = function () {
    clearInterval(this.timer);
    document.body.removeChild(this.dom);
    boxMessages = boxMessages.filter((function (box) {
      return box !== this;
    }).bind(this));
    repaintBox();
  };

  this.setMessage = function (message) {
    const text = this.dom.getElementsByTagName("div")[0];
    text.innerHTML = message;
  };
}

function createDOM() {
  const div = document.createElement("div");
  div.id = "notifyDiv";
  div.className = "notify";
  div.style.cssText = "z-index:999999";
  div.innerHTML = "<img class='notify-img'><div class='notify-text'/>";
  document.body.appendChild(div);
  return div;
}

function repaintBox() {
  let alt = 0, i = boxMessages.length, box;
  while (--i >= 0) {
    box = boxMessages[i];
    box.dom.style.bottom = (bottom + alt) + "px";
    alt += parseInt(box.dom.offsetHeight) + 5;
  }
}

function setMessage(type, message, time) {
  const box = new BoxMessage(type, message, time);
  if (boxMessages.length >= config.maxBoxMessage) {
    boxMessages[0].destroy();
  }
  boxMessages.push(box);
  repaintBox();
  box.show();
}

function PMessage() {
  const self = this;

  function getBox() {
    let i = boxMessages.length;
    while (--i >= 0) {
      if (boxMessages[i].permanent === self) {
        return boxMessages[i];
      }
    }
    return null;
  }

  function message(type, message) {
    let box = getBox();
    if (!box) {
      setMessage(type, message, 20000);
      box = boxMessages[boxMessages.length - 1];
      box.permanent = self;
    } else {
      box.setMessage(message);
      box.dom.getElementsByTagName("img")[0].src = globalThis.Siggma.basePath +
        "/img/notify/" + type + ".png";
    }
    box.dom.className = "notify notify-black";
  }

  this.error = function (m) {
    message("error", m);
  };

  this.info = function (m) {
    message("info", m);
  };

  this.warning = function (m) {
    message("warning", m);
  };

  this.success = function (m) {
    message("success", m);
  };

  this.close = function () {
    const box = getBox();
    if (box) {
      box.destroy();
    }

    // Invalidar objeto
  };
}

export default {
  error(m, time = 7000) {
    setMessage("error", m, time);
  },

  info(m, time = 3000) {
    setMessage("info", m, time);
  },

  warning(m, time = 5000) {
    setMessage("warning", m, time);
  },

  success(m, time = 3000) {
    setMessage("success", m, time);
  },

  config(obj) {
    Object.keys(obj).forEach((propertie) => {
      config[propertie] = obj[propertie];
    });
  },

  createPMessage() {
    return new PMessage();
  },
};
