<template>
  <div class="table-container">
    <slot>
      <span>no_content</span>
    </slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.table-container {
  flex: 1;
  overflow-x: auto;
}
</style>