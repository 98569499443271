<script>
export default {
  name: "s-datetimepicker",

  template: '<input style="width: 150px; text-align: center">',

  props: {
    value: String,
    type: {
      type: String,
      default: "date", // datetime|month|time
    },
    showOn: {
      type: String,
      default: "button",
    },
    timeFormat: {
      type: String,
      default: "HH:mm",
    },
    stepSecond: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    value(novo) {
      jQuery(this.$el).val(novo);
    },
    disabled(v) {
      this.setDisabled(v);
    },
  },

  beforeDestroy() {
    const $el = jQuery(this.$el);
    if (this.type === "month") {
      $el.datepickerMonth("destroy");
    } else if (this.type === "date") {
      $el.datepicker("destroy");
    } else if (this.type === "time") {
      $el.timepicker("destroy");
    } else {
      $el.datetimepicker("destroy");
    }
  },

  methods: {
    setDisabled(v) {
      const $el = jQuery(this.$el);
      if (this.type === "month") {
        $el.datepickerMonth("disable", v);
      } else if (this.type === "date") {
        $el.datepicker("disable", v);
      } else if (this.type === "time") {
        $el.timepicker("disable", v);
      } else {
        $el.datetimepicker("disable", v);
      }
    },
    make() {
      const $el = jQuery(this.$el);

      if (this.type === "month") {
        $el.datepickerMonth({
          showOn: this.showOn,
          onClose: () => this.$emit("input", $el.val()),
        });
      } else if (this.type === "date") {
        $el.datepicker({
          showOn: this.showOn,
          onClose: () => this.$emit("input", $el.val()),
        });
      } else if (this.type === "time") {
        $el.timepicker({ timeFormat: this.timeFormat });
      } else {
        $el.datetimepicker({
          useLocalTimezone: false,
          defaultTimezone: "+0000",
          timeFormat: this.timeFormat,
          stepSecond: this.stepSecond,
          showOn: this.showOn,
        });
      }
      this.disabled && this.setDisabled(true);
      $el.on("change", () => this.$emit("input", $el.val()));
      $el.val(this.value);
    },
  },

  mounted() {
    this.make();
  },
};
</script>