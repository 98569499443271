<template>
  <div class="s-search">
    <div class="s-search-box">
      <div style="position:relative;display:flex;overflow:hidden">
        <i v-if="temTags"
          class="mdi mdi-chevron-left mdi-18px s-search-scroll-icon"
          :style="{visibility: !showSLeft ? 'hidden' : 'visible'}"
          @click.stop="scrollStepLeft"></i>
        <div class="s-search-content" ref="scroll"
          :style="{display: temTags ? 'flex' : 'none'}"
          v-resize-observer="onResizeContentTags" @click.stop="openPopup">
          <div v-for="[k, f] in (filters ? Object.entries(filters) : [])"
            :key="k" class="s-search-tag"
            v-if="f.value || (f.valueKey && f[f.valueKey])">
            <slot :name="k" :filter="f">
              <span><b>{{ f.label }}:</b> {{ f.value }}</span>
            </slot>
            <i v-if="!f.fixed" class="mdi mdi-close-circle s-search-tag-close"
              @click.stop="removeFilter(k)"></i>
          </div>
        </div>
        <i v-if="temTags"
          class="mdi mdi-chevron-right mdi-18px s-search-scroll-icon"
          :style="{visibility: !showSRight ? 'hidden' : 'visible', right: 0}"
          @click.stop="scrollStepRight"></i>
      </div>

      <i :class="['s-icon mdi mdi-18px', loading ? 'mdi-refresh mdi-spin' : 'mdi-magnify']"
        style="padding: 4px" @click="$emit('search')"></i>
      <div style="flex:1; margin-left: 5px; min-width: 100px; display: flex;">
        <input placeholder="Pesquisar..." class="s-search-content-input"
          v-model="localValue" @keypress.enter="$emit('search')">
      </div>
      <i v-if="showClear" class="s-icon mdi mdi-close mdi-18px" @click="clear"
        style="padding: 4px"></i>
      <i v-if="filters" :style="{padding: '4px'}"
        :class="`s-icon mdi mdi-chevron-${popupVisible ? 'up' : 'down'} mdi-18px`"
        @click.stop="openPopup"></i>
    </div>
    <popup :visible.sync="popupVisible" v-if="filters">
      <div class="s-search-seta"></div>
      <slot v-if="popupVisible" :filters="tmpFilters"></slot>
      <div style="text-align: right">
        <button @click="onFiltersOk"
          style="min-width: 80px; margin: 0 14px 10px"
          class="btn-primary">Pesquisar</button>
      </div>
    </popup>
  </div>
</template>

<script>
import Popup from "../popup";

export default {
  name: "s-search",

  components: {
    Popup,
  },

  props: {
    value: String,
    filters: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      step: 200,
      showSLeft: false,
      showSRight: false,
      tmpFilters: {},
      popupVisible: false,
    };
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    temTags() {
      if (this.filters) {
        for (const [_k, v] of Object.entries(this.filters)) {
          if (v.value || (v.valueKey && v[v.valueKey])) {
            return true;
          }
        }
      }
      return false;
    },
    showClear() {
      return this.localValue || this.temTags;
    },
  },

  methods: {
    removeFilter(k) {
      const filter = this.filters[k];
      if (!filter.value && filter.valueKey) {
        filter[filter.valueKey] = null;
      } else {
        filter.value = null;
      }

      this.$emit("search");
    },
    openPopup() {
      this.tmpFilters = JSON.parse(JSON.stringify(this.filters));
      this.popupVisible = true;
    },
    onScroll(_e) {
      this.updateScrollIcons();
    },
    toEnd() {
      const scroll = this.$refs.scroll;
      scroll.scrollLeft = scroll.scrollWidth;
      this.updateScrollIcons();
    },
    scrollStepRight() {
      const scroll = this.$refs.scroll;
      scroll.scrollLeft = scroll.scrollLeft + this.step;
      this.updateScrollIcons();
    },
    scrollStepLeft() {
      const scroll = this.$refs.scroll;
      scroll.scrollLeft = scroll.scrollLeft - this.step;
      this.updateScrollIcons();
    },
    updateScrollIcons() {
      const scroll = this.$refs.scroll;
      if (scroll.scrollWidth <= 1) {
        this.showSRight = false;
        this.showSLeft = false;
        return;
      }
      this.showSRight = scroll.scrollWidth > Math.ceil(
        scroll.scrollLeft +
          scroll.getBoundingClientRect().width,
      );
      this.showSLeft = scroll.scrollLeft > 0;
    },
    onFiltersOk() {
      this.$emit("update:filters", this.tmpFilters);
      this.popupVisible = false;
      this.$emit("search");
    },
    clear() {
      this.localValue = "";
      this.filters && Object.entries(this.filters).forEach(([_k, v]) => {
        if (v.fixed) {
          return;
        }

        if (!v.value && v.valueKey) {
          v[v.valueKey] = null;
        } else {
          v.value = null;
        }
      });
      this.$emit("search");
    },
    onWheel(e) {
      const scroll = this.$refs.scroll;
      scroll.scrollLeft = scroll.scrollLeft + e.deltaY;
      if (e.deltaY > 0 && this.showSRight) {
        e.preventDefault();
      }
      if (e.deltaY < 0 && this.showSLeft) {
        e.preventDefault();
      }
    },
    onResizeContentTags(_e) {
      this.updateScrollIcons();
    },
  },

  mounted() {
    this.updateScrollIcons();
    this.$refs.scroll.addEventListener("scroll", this.onScroll);
    this.$refs.scroll.addEventListener("wheel", this.onWheel);
  },

  beforeDestroy() {
    this.$refs.scroll.removeEventListener("scroll", this.onScroll);
    this.$refs.scroll.removeEventListener("wheel", this.onWheel);
  },
};
</script>

<style>
.s-search {
  position: relative;
  display: inline-block;
  overflow: hidden;

  --s-search-bg: white;
  --s-search-color: #555;
  --s-search-border-color: #777;
}

.s-search:focus-within {
  --s-search-color: #000;
  --s-search-border-color: #000;
}

.s-search i {
  cursor: pointer;
}

.s-search-box {
  border: 1px solid var(--s-search-border-color);
  border-radius: 5px;
  flex: 1;
  padding: 5px 0 5px 5px;
  box-sizing: border-box;
  display: flex;
  height: 30px;
  overflow: hidden;
  position: relative;
  align-items: center;
  color: var(--s-search-color);
  background: var(--s-search-bg);
}

.s-search-content {
  overflow: auto;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  height: 60px;
  padding-top: 38px;
  cursor: pointer;
}

.s-search-scroll-icon {
  position: absolute;
  background: white;
  height: 25px;
  top: 36px;
  display: flex;
  align-items: center;
  opacity: .9;
}

.s-search-content-input {
  border: none !important;
  background-image: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
  height: 30px;
  font-size: 14px !important;
  width: 100%;
  min-width: 50px;
  color: var(--s-search-color)
}

.s-search-content-input:focus {
  outline-offset: 0;
  outline: 0;
}

.s-search-popup {
  position: absolute;
  top: 0;
  z-index: 1000;
  display: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  flex: 1;
  padding: 5px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.2);
}

.s-search-tag {
  max-height: 22px;
  padding-left: 5px;
  font-size: 14px;
  border: 1px solid rgb(46, 85, 100);
  background: #7DCAF0;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-right: 5px;
  overflow: hidden;
  flex: 1 0 auto;
}

.s-search-tag-close {
  margin-right: 4px;
}

.s-search-tag span {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 4px;
}

.s-search-type {
  display: flex;
}

.s-search-seta {
  border-color: transparent;
  border-bottom-color: var(--s-text-color);
  border-style: dashed dashed solid;
  border-width: 0 10px 10px;
  width: 0;
  height: 0;
  position: absolute;
  top: -10px;
  right: 10px;
}
</style>