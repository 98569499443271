import $ from "jquery";

export default function (options) {
  function loading(that, bool) {
    that.loading = bool;
    that.box.find(".mdi-refresh")[bool ? "addClass" : "removeClass"](
      "mdi-spin",
    );
  }

  function isClean(that, msg) {
    setPagination(that);
    that.boxContent.html(
      '<div class="siggma-notify-clean">' + (msg || "Nenhuma notificação!") +
        "</div>",
    );
  }

  function setPagination(that, page, pagination) {
    that.page = page || 1;

    if (that.page > 1 && pagination?.page === 1) {
      pagination.more = that.pagination.more;
    }

    that.pagination = pagination || { total: 0, more: false };

    that.box.find(".siggma-notify-header > .siggma-notify-number")
      .html(that.pagination.total)[that.pagination.total ? "show" : "hide"]();
    that.boxInfo.find(".siggma-notify-info-number")
      .html(that.pagination.total > 9 ? "+9" : that.pagination.total)
      [that.pagination.total ? "show" : "hide"]();
    that.boxInfo.find(".siggma-notify-info-bell")
      [that.pagination.total ? "addClass" : "removeClass"]("s-icon-aviso");
  }

  function create(that) {
    setPagination(that);
    that.boxContent = that.box.find(".siggma-notify");

    that.box.find(".siggma-notify-read-all").on("click", function (e) {
      Yanda.util.stopEvent(e);
      if (that.pagination.total) {
        loading(that, true);
        that.trigger("removeAll");
      }
    });

    that.box.find(".siggma-notify-refresh").on("click", function () {
      isClean(that, "Carregando...");
      loading(that, true);
      that.trigger("refresh");
    });

    that.boxContent.on("scroll", function () {
      if (
        !that.loading && that.pagination.more &&
        this.scrollTop + this.clientHeight >= this.scrollHeight
      ) {
        that.trigger("fetch", ++that.page);
        return;
      }
    });
  }

  function remove(that, id) {
    loading(that, true);
    that.results = that.results.filter((item) => (item.id !== id));
    that.boxContent.find(`.siggma-notify-item[data-id=${id}]`).remove();
    that.trigger("remove", { id, page: that.page });
  }

  function load(that, setScroll) {
    if (!that.pagination?.total) {
      isClean(that);
      return;
    }

    const scrollTop = that.boxContent.scrollTop;
    that.boxContent.html("");
    that.boxContent.scrollTop = 0;
    const report = [];
    that.results.forEach((row) => {
      row.data && row.data.report && report.push(row);
      $(
        '<div data-id="' + row.id + '" class="siggma-notify-item">' +
          '<span class="mdi mdi-close-thick mdi-18px siggma-notify-item-remove"></span>' +
          '<span class="siggma-notify-item-time">' + row.time + "</span>" +
          row.content + "</div>",
      ).appendTo(that.boxContent);
    });
    that.pagination.more &&
      $('<div class="siggma-notify-item">Carregar mais...</div>').appendTo(
        that.boxContent,
      );
    setScroll && (that.boxContent.scrollTop = scrollTop);

    that.boxContent.find(".siggma-notify-item-remove").on(
      "click",
      function (e) {
        const $div = $(this).parent();
        const id = +$div[0].dataset.id;
        Yanda.util.stopEvent(e);
        remove(that, id);
      },
    );

    that.boxContent.find(".siggma-notify-item").on("mouseover", function () {
      $(this).find(".siggma-notify-item-remove").css({ visibility: "visible" });
    });
    that.boxContent.find(".siggma-notify-item").on("mouseout", function () {
      $(this).find(".siggma-notify-item-remove").css({ visibility: "hidden" });
    });

    report.forEach((r) => {
      if (
        globalThis.Yanda.ctr.janelaDes?.uniqid === r.data.uniqid &&
        r.data.notify
      ) {
        globalThis.Yanda.newApl(104, { url: r.data.report });
        remove(that, r.id);
        return;
      }
      if (r.data.notify) {
        globalThis.Notify.info(
          `${r.data.title} gerado com sucesso.<br><br>
                    <a onclick="globalThis.Yanda.newApl(104, {url: '${r.data.report}'});">
                    <i style="color: blue"><u>Abra aqui</u></i></a>
                    <br>ou<br>
                    <a onclick="Siggma.showNotify('');">
                    <i style="color: blue"><u>Acesse as notificações do sistema</u></i></a>`,
          20000,
        );
        globalThis.fetch(
          `${globalThis.Siggma.basePath}/app/report/atualizar-notify?id=${r.id}`,
        );
      }
    });
  }

  function Notify(options) {
    this.box = options.box;
    this.boxInfo = options.boxInfo;
    this.results = [];
    this.page = 1;
    this.pagination = { total: 0, more: false };
    this.options = {
      columns: [],
      noButtons: false,
    };
    $.extend(this.options, options);
    create(this);

    this.setData = (data) => {
      loading(this, false);
      setPagination(this, this.page, data?.pagination);

      if (this.page > 1) {
        (data?.results || []).forEach((novo) => {
          let isResult = false;
          this.results.forEach((item, i) => {
            if (item.id === novo.id) {
              isResult = true;
              this.results[i] = novo;
            }
          });
          !isResult && this.results.push(novo);

          // !this.results.find(item => (item.id === novo.id)) && this.results.push(novo);
        });
        load(this);
        return;
      }

      this.results = data?.results || [];
      load(this);
    };

    this.trigger = function (type, data) {
      $(this).triggerHandler(type, data);
    };

    /* enter, dblclick, select, add, edit, delete, close, open */
    this.on = function (type, data) {
      $(this).on(type, data);
      return this;
    };
  }
  return new Notify(options);
}
