<script>
export default {
  name: "s-foreigninput",

  template: '<div :id="id"></div>',

  inject: ["hdl"],

  props: {
    id: {
      type: String,
      default: `uuid${Math.uuid(8)}`,
    },
    url: String,
    tela: Number,
    data: Function,
    value: [Object, String, Number],
    disabled: Boolean,
    readonly: Boolean,
    adicional: Function,
    setCarregando: Boolean,
    fieldCodStyle: {
      type: String,
      default: "",
    },
    fieldCodPlaceholder: {
      type: String,
      default: "",
    },
    fieldCod: {
      type: String,
      default: "id",
    },
    fieldDes: String,
    inputDes: Boolean,
    required: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    btnValue: String,
    textarea: Boolean,
    autocomplete: Object,
    async: {
      type: Boolean,
      default: true,
    },
    number: {
      type: Boolean,
      default: false,
    },
    inputmask: Object,
    preventSubmit: {
      type: Boolean,
      default: false,
    },
  },

  beforeDestroy() {
    jQuery(this.$el).foreigninput("destroy");
    jQuery(this.$el).off();
  },

  watch: {
    value(novo) {
      (novo === undefined) && (novo = null);

      this.$el.value = novo;
      novo && this.$emit("input", novo[this.fieldCod] || novo);
    },
    disabled(val) {
      jQuery(this.$el).foreigninput("disabled", val);
    },
    readonly(val) {
      jQuery(this.$el).foreigninput("readonly", val);
    },
    preventSubmit() {
      this.make();
    },
    url() {
      this.make();
    },
  },

  methods: {
    make() {
      const vm = this,
        $el = jQuery(this.$el),
        options = Object.assign({}, this.$props, {
          hdl: this.hdl,
          valueChange: false,
          change: function (val) {
            vm.$emit("input", val && val[vm.fieldCod] ? val[vm.fieldCod] : "");
            vm.$emit("change", val, this);
          },
        });

      options.id = this.$props.tela;

      $el.foreigninput(options).val(this.value);
      this.$props.disabled && $el.foreigninput("disabled", true);
      this.$props.readonly && $el.foreigninput("readonly", true);
      this.$props.preventSubmit &&
        $el.find("input:nth-child(2)").on("keypress", (e) => {
          if (e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();
          }
        });
      $el.find("input:nth-child(2)").on("keydown", (e) => {
        (e.keyCode === 120) && $el.find("input:nth-child(1)").click();
      });
    },
  },

  mounted() {
    this.make();
  },
};
</script>