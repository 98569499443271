<template>
  <div class="tooltip-container" @mouseover="showTooltip = true"
    @mouseleave="showTooltip = false">
    <slot></slot>
    <div v-if="showTooltip" class="tooltip-content">
      <slot name="tooltip"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "STooltip",

  data() {
    return {
      showTooltip: false,
    };
  },
};
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 20%;
  margin-left: -20px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}
</style>