<template>
  <div v-if="value" class="s-text-editor-container">
    <div class="s-text-editor-content">
      <div class="s-text-editor-header">
        <div class="s-text-editor-header-button" @click="previewModeChange">
          {{ previewMode ? "CONTINUAR EDITANDO" : "PRE-VISUALIZAR" }}
        </div>
        <a class="s-text-editor-header-help"
          href="https://www.markdownguide.org/basic-syntax" target="_blank">
          COMO EDITAR COM MARKDOWN
        </a>
      </div>

      <div v-if="!previewMode" class="s-text-editor-editor">
        <slot name="editor"></slot>
      </div>

      <div v-else class="s-text-editor-preview">
        <slot name="preview"></slot>
      </div>

      <div class="s-text-editor-footer">
        <button type="button" @click="close" style="min-width: 64px">
          Fechar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "STextEditor",
  props: {
    value: Boolean,
  },

  data() {
    return {
      previewMode: false,
    };
  },

  methods: {
    previewModeChange() {
      this.previewMode = !this.previewMode;
      this.$emit("preview-change", this.previewMode);
    },

    close() {
      this.$emit("input", false);
      this.previewMode = false;
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.s-text-editor-container {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
}

.s-text-editor-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  width: 80%;
  max-width: 768px;
}

.s-text-editor-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
  justify-content: space-between;
}

.s-text-editor-header-button {
  border-radius: 3px;
  color: dodgerblue;
  cursor: pointer;
  font-size: 11px;
  font-weight: 600;
  padding: 8px;
}

.s-text-editor-header-button:hover {
  background-color: rgba(30, 144, 255, 0.1);
}

.s-text-editor-header-help {
  border-radius: 3px;
  color: #757575;
  cursor: pointer;
  font-size: 11px;
  font-weight: 600;
  padding: 8px;
}

.s-text-editor-editor {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  min-height: 300px;
  overflow-y: auto;
  margin-bottom: 16px;
}

.s-text-editor-preview {
  max-height: 400px;
  min-height: 300px;
  overflow-y: auto;
  margin-bottom: 16px;
  padding: 0 8px 0 8px;
}

.s-text-editor-footer {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
</style>