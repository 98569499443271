<template>

  <div style="display: inline-flex; position: relative;">
    <select :id="id" ref="select" style="width: 100%" :disabled="disabled"
      :multiple="multiple" :required="required">
      <slot />
    </select>
    <div
      style="position: absolute; right: 0px; top: 1px; display: flex; z-index: 1">
      <i v-if="(!multiple && value) || (multiple && value && value.length)"
        @click.stop.prevent="remove"
        :class="['mdi mdi-close mdi-18px', (disabled ? '' : 's-icon')]"
        style="padding: 5px; color: #707070;" />
      <i v-if="this.tela && value && !multiple" @click.stop.prevent="edit"
        :class="['mdi mdi-square-edit-outline mdi-18px', (disabled ? '' : 's-icon')]"
        style="padding: 5px; color: #707070;" />
      <i v-if="this.telaSearch"
        :class="['mdi mdi-magnify mdi-18px', (disabled ? '' : 's-icon')]"
        @click.stop.prevent="search" style="padding: 5px; color: #707070;" />
    </div>
  </div>
</template>

<script>
const idNew = "uuid__new__uuid";

const addOption = ($el, data) => {
  const $option = jQuery(new Option(data.text, data.id));
  $option.data("data", data); // para que o template receba os dados
  $el.append($option);
};

export default {
  name: "s-select2",

  inject: ["hdl"],

  props: {
    value: [Array, Object, String, Number],
    tags: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: `uuid${Math.uuid(8)}`,
    },
    data: Array,
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    new: {
      type: Boolean,
      default: true,
    },
    maxHeight: String,
    templateResult: Function,
    templateSelection: Function,
    url: String,
    urlIds: String,
    idsParams: Object,
    payload: [Object, Function],
    fieldCod: String,
    fieldDes: String,
    tela: String,
    telaSearch: String,
    limit: Number,
  },

  watch: {
    value(novo) {
      this.$nextTick(() => this.setValue(novo));
    },
    data(v) {
      const $el = jQuery(this.$refs.select);
      $el.html("");
      v.forEach((d) => addOption($el, d));
      $el.val(this.value).trigger("change.select2");
    },
  },

  methods: {
    search() {
      !this.disabled &&
        globalThis.Yanda.newApl(
          this.telaSearch,
          { postData: this.getPayload(), multiselect: this.multiple },
          this.hdl,
          (hdl) => {
            hdl.onValueSelect = (data) => {
              if (!this.multiple) {
                return this.$emit("input", data.shift());
              }
              const values = this.value || [];
              data.forEach((d) => values.push(d));
              this.$emit("input", values);
            };
          },
        );
    },

    edit() {
      !this.disabled && /^\d+$/.test(this.value[this.fieldCod]) && this.telaNew(
        this.$el.querySelector(".select2-selection__rendered"),
        this.value[this.fieldCod],
      );
    },

    remove() {
      !this.disabled && this.$emit("input", null);
    },

    setValue(values) {
      const $el = jQuery(this.$refs.select);

      this.setStyle(values);

      const data = Array.isArray(values) ? values : [values];
      const fieldData = data[0]?.[this.fieldCod] && data[0]?.[this.fieldDes];

      if (!fieldData) {
        this.setTags(values);
        return $el.val(values).trigger("change.select2");
      }

      const add = (d) => {
        if (!$el.find(`option[value="${d[this.fieldCod]}"]`).length) {
          d.id = d[this.fieldCod];
          d.text = d[this.fieldDes];
          addOption($el, d);
        }
      };

      if (!this.multiple) {
        values && add(values);
        return $el.val(values ? values[this.fieldCod] : null)
          .trigger("change.select2");
      }

      (values || []).forEach((v) => add(v));
      $el.val((values || []).map((v) => v[this.fieldCod]))
        .trigger("change.select2");
    },

    setStyle(values) {
      const $elm = jQuery(this.$el).find(".select2-container--default");

      if (!this.tela) {
        if (this.multiple) {
          return;
        }
        if (this.telaSearch) {
          $elm.find(".select2-selection__arrow").css({
            paddingRight: (values ? "120px" : "60px"),
          });
          $elm.find(".select2-selection__rendered").width(
            `calc(100% - ${(values ? "86px" : "46px")})`,
          );
          return;
        }

        $elm.find(".select2-selection__arrow").css({
          paddingRight: (values ? "60px" : "0"),
        });
        $elm.find(".select2-selection__rendered").width(
          `calc(100% - ${(values ? "70px" : "0")})`,
        );
        return;
      }

      if (this.multiple) {
        $elm.find(".select2-selection__rendered")
          .width(`calc(100% - ${(values && values.length ? "70px" : "40px")})`);
        return;
      }
      $elm.find(".select2-selection__arrow").css({
        paddingRight: (values ? "160px" : "60px"),
      });
      $elm.find(".select2-selection__rendered").width(
        `calc(100% - ${(values ? "100px" : "70px")})`,
      );
    },

    setTags(values) {
      if (!values) {
        return;
      }

      const $el = jQuery(this.$refs.select);
      const ids = Array.isArray(values) ? values : [values];

      if (this.tags) {
        ids.forEach((v) =>
          !$el.find(`option[value="${v}"]`).length &&
          $el.append(new Option(v, v))
        );
        return;
      }

      if (this.urlIds && !$el.find("option").length) {
        if (!this.idsParams && !ids.length) {
          return;
        }
        const params = { ...this.idsParams, ids };
        this.hdl.http({
          url: this.urlIds,
          data: jQuery.param(params),
          loading: false,
        }).then((resp) => {
          resp.results.length && resp.results.forEach((d) => addOption($el, d));
          $el.val(ids).trigger("change.select2");
        }).catch(() => null);
      }
    },

    telaNew(tag, id) {
      const $el = jQuery(this.$refs.select);
      $el.select2("close");
      const params = this.payload ? this.getPayload({ id }) : id;
      globalThis.Yanda.newApl(this.tela, params, this.hdl, (hdl) => {
        if (id) {
          const set = (data, values) => {
            $el.find(`option[value="${data[this.fieldCod]}"]`).remove();
            this.$emit("input", null); // efetuar o change
            this.$nextTick(() => this.$emit("input", values));
            hdl.close();
          };

          return hdl.registroSalvo = (data) => {
            if (!this.multiple) {
              const values = this.value;
              Object.keys(data).forEach((k) => values[k] = data[k]);
              return set(data, values);
            }

            const values = this.value || [];
            values.forEach((v) => {
              (v[this.fieldCod] == data[this.fieldCod]) &&
                Object.keys(data).forEach((k) => v[k] = data[k]);
            });

            return set(data, values);
          };
        }

        jQuery(hdl.janela).find(`[name="${this.fieldDes}"]`).val(tag);
        let values = this.value || (this.multiple ? [] : null);
        hdl.registroSalvo = (data) => {
          if (!this.multiple) {
            values = data;
            return hdl.close();
          }
          values.push(data);
          hdl.close();
        };

        hdl.onBeforeClose = () => {
          this.$emit(
            "input",
            this.multiple
              ? values.filter((v) => (v[this.fieldCod] !== tag))
              : values,
          );
        };
      });
    },

    onEnterInput(e) {
      if (e.key !== "Enter") {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      const elm = globalThis.document.querySelector("[s-select2-cadastrar]");
      const msg = elm ? elm.innerHTML : "";
      (msg === "Cadastrar") && this.telaNew(e.target.value);
    },

    getPayload(params = {}) {
      const isFunction = typeof this.payload === "function";
      const data = (isFunction ? this.payload() : this.payload) || {};
      return Object.assign(params, data);
    },

    make() {
      const $el = jQuery(this.$refs.select);
      const opts = {
        data: this.data,
        placeholder: this.placeholder,
        tags: this.tags,
        templateResult: this.templateResult,
        templateSelection: this.templateSelection,
        width: "resolve",
      };
      let ids = this.value;

      if (this.url) {
        opts.ajax = {
          delay: 300,
          url: this.url,
          dataType: "json",
          data: (t) => this.getPayload({ term: t.term, page: t.page }),
        };

        if (this.tela && this.new) {
          const newRegister = {
            id: idNew,
            text: "<b>Cadastrar...</b>",
            newRegister: true,
          };
          opts.ajax.processResults = (data) => {
            (!data.results || !data.results.length) && (data.results = []);
            this.tela && this.new &&
              (!data.pagination || !data.pagination.more) &&
              data.results.push(newRegister);
            return data;
          };
        }
      }

      if (this.tela) {
        opts.tags = false;
        opts.escapeMarkup = (d) => d;
        if (this.multiple) {
          opts.templateSelection = (d) =>
            jQuery(`<span style="cursor: pointer;">${d.text}</span>`)
              .on("click", (e) => {
                e.stopPropagation();
                this.telaNew(e.target, d.id);
              });
          opts.data = (this.value || []).map((d) => ({
            id: d[this.fieldCod],
            text: d[this.fieldDes],
          }));
          ids = (this.value || []).map((d) => d[this.fieldCod]);
        } else if (this.value) {
          opts.data = [{
            id: this.value[this.fieldCod],
            text: this.value[this.fieldDes],
          }];
          ids = this.value[this.fieldCod];
        }
      }

      this.limit && (opts.maximumSelectionLength = this.limit);

      this.setTags(ids);
      $el.select2(opts)
        .val(ids)
        .trigger("change.select2")
        .on("change", () => {
          if (!this.tela) {
            return this.$emit("input", $el.val());
          }

          const select2 = $el.select2("data");
          if (select2.find((d) => d.id === idNew)) {
            const elm = this.multiple
              ? this.$el.querySelector(".select2-search__field")
              : (this.value && !/^\d+$/.test(this.value[this.fieldCod])
                ? this.value[this.fieldDes]
                : globalThis.document.querySelector(
                  ".select2-search--dropdown .select2-search__field",
                ));
            this.telaNew(elm ? (typeof elm == "string" ? elm : elm.value) : "");
          }

          const data = select2.filter((d) => d.id !== idNew).map((d) => ({
            [this.fieldCod]: d.id,
            [this.fieldDes]: d.text,
          }));

          return this.$emit("input", this.multiple ? data : data.shift());
        });

      this.maxHeight && $el.parent().find(".select2-selection__rendered").css({
        maxHeight: this.maxHeight,
      });
      this.setStyle(ids);
    },
  },

  beforeDestroy() {
    jQuery(this.$refs.select).select2("destroy");
    jQuery(this.$el).off();
  },

  mounted() {
    this.make();
  },
};
</script>