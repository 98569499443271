<template>
  <div v-if="enabled" style="display: flex; flex-direction: column;
      justify-content: center; width: 300px; min-width: 300px; height: 28px;
      text-align: center; z-index: 1; position: relative;">
    <div>
      <label style="color: #000; font-size: 10px;">
        R$: {{valor.toFixed(2)}}
      </label>
      <progress style="width: 100px; height: 12px;" id="progressFrete" min="0"
        max="100" :value="valor"></progress>
      <label style="color: #000; font-size: 10px;">
        R$: {{freteGratis.toFixed(2)}}
      </label>
    </div>
    <label style="color: #000">
      <span v-if="restante > 0">
        Faltam <b>R$ {{restante.toFixed(2)}}</b> para
      </span>
      <b v-if="frete" style="color: blue;">Frete Grátis</b>
      <b v-else style="color: blue;">Frete não Definido</b>
    </label>
  </div>
</template>

<script>
export default {
  name: "s-fretes-cep",

  inject: ["hdl"],

  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
    firstLoad: {
      type: Boolean,
      default: false,
    },
    valor: Number,
    frete: Number,
    idFrete: String,
    freteGratis: Number,
    previsaoEntrega: String,
    idPrevisaoEntrega: String,
  },

  computed: {
    restante() {
      const restante = this.freteGratis - this.valor;
      return restante > 0 ? restante : 0;
    },
  },

  watch: {
    restante(value) {
      if (this.enabled && !this.firstLoad && this.frete) {
        const frete = value ? this.frete : 0;
        this.hdl.valorFreteOriginal = frete;
        this.hdl.$(this.idFrete).numberValue = frete;
      }
    },
    previsaoEntrega() {
      if (this.enabled && this.previsaoEntrega) {
        this.hdl.$(this.idPrevisaoEntrega).value = this.previsaoEntrega;
      }
    },
  },
};
</script>