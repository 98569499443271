<template>
  <table :class="computedClass">
    <slot>
      <span>no_content</span>
    </slot>
  </table>
</template>

<script>
export default {
  props: ["striped"],
  computed: {
    computedClass() {
      return this.striped
        ? "table-content table-content-striped"
        : "table-content";
    },
  },
};
</script>

<style scoped>
.table-content {
  justify-content: center;
  min-width: calc(100% - 1px);
}

.table-content-striped>tr:not(.table-head):nth-child(even) {
  background-color: rgba(0, 0, 0, 0.08);
}
</style>