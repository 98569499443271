function lockSession(config) {
  globalThis.Siggma.lockSession = function () {
    !globalThis.Yanda.ctr.janDesableAll && jQuery.ajax({
      method: "POST",
      data: "lock=true",
      url: `${globalThis.Siggma.basePath}/app/index/lock-session`,
    }).done(function (resp) {
      (resp.type !== "success")
        ? globalThis.Yanda.message(resp)
        : globalThis.Siggma.lockedSession("Sessão bloqueada");
    });
  };

  globalThis.Siggma.lockedSession = function (type) {
    const login = JSON.parse(localStorage.getItem("login"));
    const logout = () => {
      globalThis.onbeforeunload = null;
      document.location = `${globalThis.Siggma.basePath}/app/index/logout`;
    };

    let url;
    let title;
    let divDisableAll = null;
    switch (type) {
      case "Expediente encerrado":
        globalThis.Yanda.message({
          msg: "Acesso ao sistema <b>fora de expediente</b> não permitido",
          type: "warning",
          title: type,
          onClose: () => setTimeout(logout, 10),
        });
        return;

      case "Sessão bloqueada":
        url = `${globalThis.Siggma.basePath}/app/index/lock-session`;
        title = "Sessão Bloqueada";
        break;

      case "Sessão expirou":
        url = `${globalThis.Siggma.basePath}/app/index/index`;
        title = "Sessão Expirada";
        break;

      case "Sessão outro local":
        url = `${globalThis.Siggma.basePath}/app/index/index`;
        title =
          'Sessão Bloqueada. <span style="font-weight: normal">Login efetuado em outro local</span>';
        break;

      default:
        globalThis.Yanda.message({
          msg: type,
          type: "error",
          title: "Controle de Acesso",
          onClose: () => setTimeout(logout, 10),
        });
        return;
    }

    if (!login || !login.usu) {
      globalThis.Yanda.message({
        msg: "Usuário não encontrado",
        type: "error",
        title: type,
        onClose: () => setTimeout(logout, 10),
      });
      return;
    }

    const hdl = new globalThis.Yanda.newJanela({
      title,
      mode: "new",
      btFe: true,
    });
    hdl.setContent(
      `<div id="body">
                <div style="padding: 30px 15px; height: 70px">
                    <div style="position: relative; margin-bottom: 10px">
                        <input
                            id="cpUser"
                            type="text"
                            class="text"
                            style="width: 100%; height: 40px; padding: 3px 35px 3px 15px;"
                            readonly
                            placeholder="Usuário"
                        >
                        <span
                            class="mdi mdi-account mdi-24px"
                            style="font-size: 20px!important; opacity: 0.7; position: absolute; right: 10px; top: 10px;"
                        ></span>
                    </div>
                    <div style="position: relative">
                        <input
                            id="cpPwd"
                            type="password"
                            style="width: 100%; height: 40px; padding: 3px 35px 3px 15px;"
                            placeholder="Senha"
                        >
                        <span
                            class="mdi mdi-lock mdi-24px"
                            style="font-size: 20px!important; opacity: 0.7; position: absolute; right: 10px; top: 10px;"
                        ></span>
                    </div>
                </div>
            </div>
            <div style="text-align: right; padding: 15px;">
                <button id='btnOk' type='button' class="btn-primary" style="float: right; height: 40px; font: bold  14px Verdana;
                    min-width: 120px; display: inline-flex; justify-content: center; align-items: center;"
                ><i class="mdi mdi-lock-open-variant mdi-18px">Continuar</i></button>
                <button id='btnSair' type='button'
                    style="min-width: 80px; margin-right: 5px; height: 40px; font: normal 14px Verdana; display:
                        inline-flex; justify-content: center; align-items: center;"
                ><i class="mdi mdi-logout mdi-18px">Sair</i></button>
            </div>`,
    );

    hdl.setTamanho(480, 236);
    hdl.centraliza();
    hdl.setResize(false);

    hdl.onCreate = () => {
      hdl.acptDisableAll = false;
      globalThis.Yanda.disableAll(true);
      divDisableAll = document.querySelector("#yanda-overlay");
      divDisableAll.style.backgroundColor = "#000";
      divDisableAll.style.zIndex = 600003;
    };

    hdl.onBeforeClose = function () {
      hdl.$("cpPwd").select();
      return false;
    };

    hdl.onAfterClose = () => {
      divDisableAll.style.backgroundColor = "#a5a5a5";
      divDisableAll = null;
      globalThis.Yanda.disableAll(false);
    };

    hdl.$("btnSair").addEventListener("click", logout);
    hdl.$("cpPwd").addEventListener(
      "keypress",
      (e) => (e.keyCode === 13) && hdl.$("btnOk").click(),
    );
    hdl.$("btnOk").addEventListener("click", async () => {
      if (!hdl.$("cpPwd").value) {
        Notify.warning("Informe a senha");
        hdl.$("cpPwd").focus();
        return;
      }

      const browserHash = await Siggma.database.connect(
        globalThis.Siggma.basePath,
      )
        .then(() => Siggma.database.get("_", 1))
        .then((data) => (data && data.hash) || "")
        .catch(() => "");

      const data = "lock=" +
        `&pwd=${hdl.$("cpPwd").value}` +
        `&user=${hdl.$("cpUser").value}` +
        `&filial=${globalThis.Siggma.filial}` +
        `&empresa=${globalThis.Siggma.empresa}` +
        `&browserHash=${browserHash}`;

      hdl.ajax(true, url, data, (xhr) => {
        const obj = JSON.parse(xhr.responseText);
        if (obj.type === "success") {
          hdl.onBeforeClose = null;
          hdl.close();

          if (title === "Sessão Expirada" && obj.data) {
            document.querySelector("#zt-usuario-acessos").innerHTML =
              obj.data.usuarioAcessos;
            document.querySelector("#zt-usuario-ultimo-acesso").innerHTML =
              obj.data.usuarioUltimoAcesso;
          }

          globalThis.Siggma.startBind(config);
          return;
        }
        Notify.warning(obj.msg);
      });
    });

    hdl.show();
    hdl.janela.style.zIndex = 600004;

    hdl.$("cpUser").value = login.usu;
    hdl.$("cpPwd").select();

    return hdl;
  };

  config.lockSession && Siggma.lockedSession("Sessão bloqueada");
}

export default lockSession;
