export default function (config) {
  const Yanda = globalThis.Yanda;

  if (!config.novo) {
    Yanda.ajanMenu.setJson(config.menus);
    return;
  }

  Siggma.zjqMenu = globalThis.zjqMenu({
    data: [],
    width: config.width,
    maxWidth: 320,
    menuElm: jQuery("#menuNovo"),
    toggleSearch: (width) => Siggma.painelMenu.changeWidth(width),
  }).on("enter click", function (e, data) {
    if (!data) {
      return;
    }
    const ctrl = e && (e.ctrlKey || e.metaKey);
    if (data.exec) {
      !ctrl && Siggma.painelMenu.toggleAuto();
      data.exec && eval(data.exec);
      return;
    }

    if (data.id && data.id !== "null") {
      !ctrl && Siggma.painelMenu.toggleAuto();
      Yanda.newApl(data.id);
    }
  });

  Siggma.zjqMenu.reload(config.menus);

  Siggma.painelMenu = new globalThis.Painel({
    elm: jQuery("#painelMenu"),
    width: config.width,
    menuOpen: config.menuOpen,
    menuFixed: config.menuFixed,
  }).on("open", function () {
    !Yanda.util.isMobile() &&
      Siggma.zjqMenu.menuElm.find("input").each((_i, obj) => obj.select());
  });

  Siggma.painelMenu.refresh();

  jQuery("#zt-menu").on("touchstart click", function (e) {
    e.preventDefault();
    Siggma.painelMenu.toggle(true);
  });

  document.addEventListener("keydown", function (e) {
    // ctrl + alt + f with menu open
    if (e.ctrlKey && e.altKey && e.keyCode === 70) {
      globalThis.Yanda.util.stopEvent(e);
      globalThis.Yanda.util.cancelEvent(e);
      if (Siggma.painelMenu.isOpen) {
        Siggma.zjqMenu.search();
        return;
      }
      Siggma.painelMenu.toggle(true);
      Siggma.zjqMenu.search();
      return;
    }

    // Esc e toggleAuto
    if (e.keyCode === 27 && Siggma.painelMenu.toggleAuto()) {
      globalThis.Yanda.util.stopEvent(e);
      globalThis.Yanda.util.cancelEvent(e);
      return;
    }

    // F3 - abre/esconde o painel
    if (e.keyCode === 114) {
      globalThis.Yanda.util.stopEvent(e);
      globalThis.Yanda.util.cancelEvent(e);
      !Siggma.painelMenu.toggleAuto() && Siggma.painelMenu.toggle(true);
    }
  }, true);
}
