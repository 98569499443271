import Moment from "moment";

export default (date) => {
  if (!date) {
    return "";
  }

  const monthsFn = (v) => `${v} ${v < 2 ? "mês" : "meses"}`;
  const yearsFn = (v) => `${v} ${v < 2 ? "ano" : "anos"}`;

  const mmt = Moment(date, "DD/MM/YYYY"),
    now = Moment(),
    months = now.diff(mmt, "months"),
    years = now.diff(mmt, "years"),
    left = months % 12;

  if (months < 13) {
    return monthsFn(months);
  }
  if (left === 0) {
    return yearsFn(years);
  }

  return `${yearsFn(years)} e ${monthsFn(left)}`;
};
