import Radio from "element-ui/lib/radio";
import Checkbox from "element-ui/lib/checkbox";
import CheckboxGroup from "element-ui/lib/checkbox-group";
import Table from "element-ui/lib/table";
import TableColumn from "element-ui/lib/table-column";
import Alert from "element-ui/lib/alert";
import Notification from "element-ui/lib/notification";
import Upload from "element-ui/lib/upload";
import Steps from "element-ui/lib/steps";
import Step from "element-ui/lib/step";
import locale from "element-ui/src/locale";
import Loading from "element-ui/lib/loading";

const components = [
  Checkbox,
  CheckboxGroup,
  Table,
  TableColumn,
  Alert,
  Upload,
  Steps,
  Step,
  Radio,
];

const install = function (Vue, opts = {}) {
  locale.use(opts.locale);
  locale.i18n(opts.i18n);

  components.forEach((component) => {
    Vue.component(component.name, component);
  });

  // Vue.use(InfiniteScroll);
  Vue.use(Loading.directive);

  Vue.prototype.$ELEMENT = {
    size: opts.size || "",
    zIndex: opts.zIndex || 2000,
  };

  // Vue.prototype.$loading = Loading.service;
  // Vue.prototype.$msgbox = MessageBox;
  // Vue.prototype.$alert = MessageBox.alert;
  // Vue.prototype.$confirm = MessageBox.confirm;
  // Vue.prototype.$prompt = MessageBox.prompt;
  Vue.prototype.$notify = Notification;
  // Vue.prototype.$message = Message;
};

export default {
  version: "2.15.6",
  locale: locale.use,
  i18n: locale.i18n,
  install,
  Notification,
};
