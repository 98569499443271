<template>
  <div style="display:inline-flex; color: #fff">
    <input ref="input" type="checkbox" :disabled="disabled"
      :data-checked-label="activeText || text"
      :data-unchecked-label="inactiveText || text" :data-min-width="width"
      :data-max-width="width" :data-color="color" />
  </div>
</template>

<script>
export default {
  name: "s-onoff",

  props: {
    width: String,
    value: {
      type: [Boolean, String, Number],
      default: false,
    },
    text: String,
    activeText: String,
    inactiveText: String,
    activeValue: {
      type: [Boolean, String, Number],
      default: true,
    },
    inactiveValue: {
      type: [Boolean, String, Number],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: String,
  },

  watch: {
    value(value) {
      this.$nextTick(() => this.set(value));
    },
    disabled(value) {
      jQuery(this.$refs.input).onoff(value ? "disable" : "enable");
    },
  },

  methods: {
    set(value) {
      this.$refs.input.checked = value === this.activeValue;
    },
  },

  beforeDestroy() {
    jQuery(this.$el).off();
  },

  mounted() {
    jQuery(this.$refs.input).onoff({
      labels: !(!this.text && !this.activeText && !this.inactiveText),
    })
      .on("change", () => {
        const value = this.$refs.input.checked ? this.activeValue : this
          .inactiveValue;
        if (this.value === value) {
          return;
        }

        this.$emit("input", value);
        this.$emit("change", value);
      });

    this.set(this.value);
  },
};
</script>