import Yanda from "yanda";
import $ from "jquery";

function general() {
  /**
   * Suporte as variáveis e métodos antigos
   * @deprecated
   */
  globalThis.$ = function (id) {
    console.warn(`Função '$' está depreciada. Id procurado: ${id}`);
    return document.getElementById(id);
  };

  String.prototype.toNumber = function () {
    const num = this;
    return parseFloat(
      num.includes(",") ? num.replace(".", "").replace(",", ".") : num,
    );
  };

  Number.prototype.toNumber = function () {
    return this;
  };

  Number.prototype.numberFormat = function (
    decimals = Siggma.decimais,
    decPoint = ",",
    thousandsSep = ".",
  ) {
    const number = (this + "").replace(/[^0-9+\-Ee.]/g, "");
    const n = !isFinite(+number) ? 0 : +number;
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    const toFixedFix = function (n, prec) {
      const k = Math.pow(10, prec);
      return "" + Math.round(n * k) / k;
    };

    const s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandsSep);
    }
    if ((s[1] || "").length < prec) {
      s[1] = s[1] || "";
      s[1] += new Array(prec - s[1].length + 1).join("0");
    }
    return s.join(decPoint);
  };

  Date.prototype.getWeek = function () {
    const date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    const week1 = new Date(date.getFullYear(), 0, 4);
    return 1 + Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 + (week1.getDay() + 6) % 7) / 7,
    );
  };
}

/** @deprecated Essas adapatações serão eliminadas */
function yanda() {
  // TODO: Suporte a versoes antigas, eliminar em versões futuras
  globalThis.System = Yanda;
  [
    "trocaFoco",
    "getDataToServer",
    "soValor",
    "soNum",
    "addOption",
  ].forEach((key) => {
    globalThis[key] = function () {
      console.warn(
        `Função '${key}' está depreciada. Utilize Yanda.util.${key}`,
      );
      return Yanda.util[key]?.apply(this, arguments);
    };
  });

  Yanda.help.click = (checked) => {
    Siggma.setUsuariosPreferencias({ helpJaEntendi: checked });
  };
  Yanda.lancaItem = () =>
    Yanda.include(
      `../../js/lancaItem.js?${Siggma.urlArgs}`,
      "lancaItem.js",
      "js",
      false,
    );
  Yanda.json = (url) =>
    Yanda.include((url || "../../") + "js/json.js", "json.js", "js", false);

  /**
   * @param url Object|String
   * @param data
   * @returns {Promise<any>}
   */
  Yanda.janela.prototype.http = function (url, data) {
    const params = (typeof url === "object") ? url : { url, data };

    if (
      params.data && (typeof params.data === "object") &&
      !(params.data instanceof FormData)
    ) {
      const data = [];
      Object.keys(params.data).forEach((k) => {
        const empty = [undefined, null].includes(params.data[k]);
        data.push(`${k}=${empty ? "" : encodeURIComponent(params.data[k])}`);
      });
      params.data = data.join("&");
    }

    return new Promise((resolve, reject) => {
      (params.loading === undefined) && (params.loading = true);
      this.ajax(params.loading, params.url, params.data, (xhr) => {
        if (params.callback) {
          resolve(xhr);
          return;
        }

        const resp = JSON.parse(xhr.responseText) || {
          type: "error",
          msg: "Erro ao efetuar operação",
          detail: "Resposta inválida do servidor",
        };

        if (Array.isArray(resp) || !resp.type) {
          resolve(resp);
          return;
        }

        if (resp.detail || resp.exception) {
          Yanda.message({
            ...resp,
            onClose() {
              (resp.type !== "success") ? reject(resp) : resolve(resp);
            },
          });
          return;
        }

        resp.msg && Notify[resp.type](resp.msg);
        (resp.type !== "success") ? reject(resp) : resolve(resp);
      });
    });
  };

  Yanda.janela.prototype.campoPadrao = async function (e) {
    const that = this;
    if (!that.options.btPr) {
      return;
    }

    const { default: pref } = await Siggma.import("janelas-preferencias.js");

    if (e.type !== "contextmenu") {
      pref.open({ dad: that });
      return;
    }

    const keydown = function (e) {
      (e.key === "Escape" && globalThis.Yanda?.ctr?.janelaDes === that) &&
        close(e, jQuery(that.janela).find(".janelas-preferencias"));
    };

    const close = (e, $elm) => {
      e.preventDefault();
      e.stopPropagation();
      $elm.remove();
      document.removeEventListener("keydown", keydown, true);
      that.setDisable(false);
    };

    const getJanelaPref = () => {
      let $janelaPref = jQuery(that.janela).find(".janelas-preferencias");
      if ($janelaPref.length) {
        return $janelaPref;
      }
      $janelaPref = jQuery(
        `<div class="ajan-help-rtl janelas-preferencias" tabindex="0">
          <span class="mdi mdi-close-circle mdi-24px"></span>
          <div class="ajan-help-arrow"></div>
          <div class="ajan-help-info"><ul style="font-weight: normal;"></ul></div>
        </div>`,
      ).on("click", (e) => e.stopPropagation());

      jQuery(that.janela).find(".ajan-title-bt-pr").append($janelaPref);
      $janelaPref.find(".mdi-close-circle").on("click", function (e) {
        close(e, $janelaPref);
      });
      return $janelaPref;
    };

    const sidx = that.janelaPreferenciaId
      ? `case when e.id = ${that.janelaPreferenciaId} then 1 else 2 end, e.descricao`
      : "e.descricao";

    that.http(
      `${Siggma.basePath}/app/janelas-preferencias/consultar`,
      { janela: that.id, page: 1, rows: 20, sord: "asc", sidx },
    ).then(({ rows }) => {
      if (!rows?.length) {
        Notify.warning("Preferências não encontradas");
        return;
      }

      that.setDisable(true);
      document.addEventListener("keydown", keydown, true);

      const $janelaPref = getJanelaPref();
      const $ul = $janelaPref.find("ul");
      $ul.html("<li>&nbsp;</li>");
      $ul.html("");

      rows.forEach((row) => {
        const style = that.janelaPreferenciaId == row.id
          ? `style="font-weight: bold;"`
          : "";
        jQuery(`<li ${style}>${row.descricao}</li>`).on("click", function (e) {
          const preferencia = jQuery(this).data("preferencia");
          close(e, $janelaPref);
          pref.set(that, preferencia, true);
        }).data("preferencia", row).appendTo($ul);
      });
    }).catch(() => null);
  };

  /**
   * @param {doc: string, video: string} help - Urls para documentação e video
   */
  Yanda.janela.prototype.setHelp = function (help) {
    if (!help) {
      return;
    }

    const titleButtons = this.janela.querySelector(".ajan-title-buttons");
    if (!titleButtons) {
      console.warn("Não foi possível encontrar os botões da janela");
      return;
    }

    if (help?.doc) {
      const docButton = document.createElement("div");
      docButton.className =
        "mdi mdi-help-circle s-yanda-icon-help ajan-btncolor-help-doc";
      docButton.title = "Ajuda";
      docButton.addEventListener(
        "click",
        () => globalThis.open(Siggma.basePath + help.doc, "_blank"),
      );
      titleButtons.appendChild(docButton);
    }

    if (help?.video) {
      const videoButton = document.createElement("div");
      videoButton.className =
        "mdi mdi-youtube s-yanda-icon-help ajan-btncolor-help-video";
      videoButton.title = "Video de demonstração";
      videoButton.addEventListener(
        "click",
        () => globalThis.open(Siggma.basePath + help.video, "_blank"),
      );
      titleButtons.appendChild(videoButton);
    }
  };

  Yanda.janela.prototype.simultaneousEditController = function (prefix) {
    if (!Siggma.ws.isConnected) {
      return;
    }

    const editingIds = new Map();

    const registerDefault = (idRegistro, editLocked) => {
      if (!this.getById) {
        console.warn(
          `A janela ${this.id} não possui o método getById, a validação de edição simultânea não será realizada`,
        );
        return;
      }

      const callback = (msg) => {
        const data = msg.data;

        if (msg.cmd == `${prefix}-${Siggma.filial}-refresh`) {
          Siggma.ws.emit("event", {
            cmd: `${prefix}-${Siggma.filial}-${idRegistro}`,
            data: {
              uniqid: this.uniqid,
              usuarioNome: Siggma.usuarioNome,
            },
          });
        }

        if (
          msg.cmd === `${prefix}-${Siggma.filial}-${idRegistro}` &&
          data.uniqid !== this.uniqid
        ) {
          data.usuarioNome = Siggma.usuarioNome;
          Siggma.ws.emit("event", {
            cmd: `${prefix}-${Siggma.filial}-${idRegistro}-edit`,
            data,
          });
        }

        if (
          msg.cmd === `${prefix}-${Siggma.filial}-${idRegistro}-edit` &&
          data.uniqid === this.uniqid
        ) {
          const onClose = () => {
            this.onBeforeClose = function () {
              Siggma.ws.removeMessageListener(callback);
            };
            setTimeout(() => this.close(), 10);
          };

          const msg =
            `O registro numero ${idRegistro} ja está sendo editado pelo usuario <b>${data.usuarioNome}</b>.`;
          editLocked
            ? Yanda.message({ msg, type: "warning", onClose })
            : Yanda.confirm({
              msg: `${msg}<br/><br/>Deseja editar esse registro mesmo assim?`,
              actionFalse: onClose,
            });
        }
      };

      Siggma.ws.addMessageListener(callback);

      const handler = {
        apply(target, thisArg, argumentsList) {
          Siggma.ws.emit("event", {
            cmd: `${prefix}-${Siggma.filial}-${idRegistro}`,
            data: {
              uniqid: thisArg.uniqid,
              usuarioNome: Siggma.usuarioNome,
            },
          });

          return Reflect.apply(target, thisArg, argumentsList);
        },
      };

      const getByIdProxy = new Proxy(this.getById, handler);
      this.getById = getByIdProxy;

      const onBeforeClose = this.onBeforeClose;

      this.onBeforeClose = function () {
        Siggma.ws.emit("event", {
          cmd: `${prefix}-${Siggma.filial}-${idRegistro}-remove`,
        });
        Siggma.ws.removeMessageListener(callback);
        return onBeforeClose?.();
      };
    };

    const registerEditingIds = () => {
      const regexAdd = new RegExp(`^${prefix}-${Siggma.filial}-\\d+$`);
      const regexRemove = new RegExp(
        `^${prefix}-${Siggma.filial}-\\d+-remove$`,
      );

      const otaCallback = (msg) => {
        const data = msg.data;
        const idRegistro = msg.cmd.split("-").slice(2, 3);

        (regexAdd.test(msg.cmd) &&
          !editingIds.has(`${prefix}-${idRegistro}`)) &&
          editingIds.set(`${prefix}-${idRegistro}`, data.usuarioNome);

        regexRemove.test(msg.cmd) &&
          editingIds.delete(`${prefix}-${idRegistro}`);
      };

      Siggma.ws.addMessageListener(otaCallback);

      const onBeforeClose = this.onBeforeClose;
      this.onBeforeClose = function () {
        Siggma.ws.removeMessageListener(otaCallback);
        return onBeforeClose?.();
      };

      Siggma.ws.emit("event", {
        cmd: `${prefix}-${Siggma.filial}-refresh`,
        data: {
          uniqid: this.uniqid,
          usuarioNome: Siggma.usuarioNome,
        },
      });
    };

    return {
      registerDefault,
      registerEditingIds,
      editingIds,
    };
  };

  // hdl não é mais utilizado nas janelas
  Object.defineProperty(Yanda.janela.prototype, "hdl", {
    get() {
      console.warn(
        `(tela ${this.id}) hdl não deve mais ser usado nas janelas, utilize \`_\${this.id}\` caso necessário`,
      );
      return `_${this.id}`;
    },
  });
}

// Ajuste nos componentes visuais do jquery
function jquery() {
  $.jgrid = $.jgrid || {};
  $.extend($.jgrid, {
    formatter: {
      number: {
        decimalSeparator: ".",
        thousandsSeparator: " ",
        decimalPlaces: 2,
        defaultValue: "0.00",
      },
      currency: {
        decimalSeparator: ".",
        thousandsSeparator: ",",
        decimalPlaces: 2,
        prefix: "R$ ",
        suffix: "",
        defaultValue: "0,00",
      },
    },
  });

  $.widget("custom.numberinput", $.custom.numberinput, {
    options: {
      change: null,
      decimals: 2,
      decPoint: ".",
      thousandsSep: "",
    },
  });

  // subscribe language and event keypress (ESC - 27)
  $.fn.select2.defaults.set("language", "pt-BR");
  $.fn.select2.amd.require(
    ["select2/core", "select2/keys"],
    function (Select2, KEYS) {
      Select2.prototype._registerEvents = function () {
        const self = this;

        this.on("open", function () {
          self.$container.addClass("select2-container--open");
        });

        this.on("close", function () {
          self.$container.removeClass("select2-container--open");
        });

        this.on("enable", function () {
          self.$container.removeClass("select2-container--disabled");
        });

        this.on("disable", function () {
          self.$container.addClass("select2-container--disabled");
        });

        this.on("blur", function () {
          self.$container.removeClass("select2-container--focus");
        });

        this.on("query", function (params) {
          !self.isOpen() && self.trigger("open", {});

          this.dataAdapter.query(params, function (data) {
            self.trigger("results:all", { data: data, query: params });
          });
        });

        this.on("query:append", function (params) {
          this.dataAdapter.query(params, function (data) {
            self.trigger("results:append", { data: data, query: params });
          });
        });

        this.on("keypress", function (evt) {
          const key = evt.which;

          if (self.isOpen()) {
            if (
              key === KEYS.ESC || key === KEYS.TAB ||
              (key === KEYS.UP && evt.altKey)
            ) {
              self.close();
              evt.preventDefault();
              evt.stopPropagation();
            } else if (key === KEYS.ENTER) {
              self.trigger("results:select", {});

              evt.preventDefault();
            } else if ((key === KEYS.SPACE && evt.ctrlKey)) {
              self.trigger("results:toggle", {});

              evt.preventDefault();
            } else if (key === KEYS.UP) {
              self.trigger("results:previous", {});

              evt.preventDefault();
            } else if (key === KEYS.DOWN) {
              self.trigger("results:next", {});

              evt.preventDefault();
            }
            return;
          }

          if (key === KEYS.SPACE || (key === KEYS.DOWN && evt.altKey)) {
            self.open();
            evt.preventDefault();
          }
        });
      };
    },
  );
}

export default function () {
  general();
  yanda();
  jquery();
}
