<template>
  <div class="s-form-group">
    <div v-if="header" :class="headerClasses"
      :style="{cursor: collapsable ? 'pointer' : 'unset'}" @click="toggle">
      <div style="text-align: center">
        <b style="text-transform: uppercase;">
          {{header}}
          <a v-if="!!helper" class='mdi mdi-help-circle mdi-18px' @click.stop
            :href='helper' target='_blank'></a>
        </b>
        <span v-if="details">- {{details}}</span>
        <i v-if="remove" @click.stop="removeGroup"
          class="mdi mdi-close mdi-24px s-form-group-icon"></i>
        <i v-if="collapsable"
          :class="`mdi mdi-chevron-${open ? 'up' : 'down'} mdi-24px; s-form-group-icon`"></i>
      </div>
      <span class="s-form-group-subtitle" v-if="open && subtitle">
        {{subtitle}}
      </span>
    </div>
    <div class="s-form-group-container" v-show="open">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "s-form-group",

  props: {
    value: {
      type: Boolean,
      default: undefined,
    },
    header: {
      type: String,
      default: "",
    },
    helper: {
      type: String,
      default: "",
    },
    details: {
      type: String,
      default: "",
    },
    collapsable: {
      type: Boolean,
      default: true,
    },
    collapsed: Boolean,
    remove: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: String,
      default: "",
    },
    dense: Boolean,
  },

  data() {
    return {
      open: true,
    };
  },

  computed: {
    headerClasses() {
      return {
        "s-form-group-header": true,
        "s-form-group-header-dense": this.dense,
      };
    },
  },

  watch: {
    value(value) {
      this.open = value;
    },
  },

  methods: {
    toggle() {
      if (this.collapsable) {
        this.open = !this.open;
        this.$emit("input", this.open);
      }
    },
    removeGroup() {
      this.$emit("remove");
    },
  },

  mounted() {
    if (
      typeof this.value === "undefined" && typeof this.collapsed === "boolean"
    ) {
      this.open = !this.collapsed;
    } else {
      this.open = this.value;
    }
  },
};
</script>

<style>
.s-form-group {
  font-size: 12px;
  width: 100%;
  margin: 10px auto;
  border: 1px solid #c1c1c1;
  padding-top: 10px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column
}

.s-form-group-icon {
  float: right;
  margin-right: 10px;
  cursor: pointer;
}

.s-form-group>.s-form-group-header {
  color: #777;
  font-size: 1.2em;
  padding-left: 5px;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 10px;
}

.s-form-group>.s-form-group-header-dense {
  color: #777;
  font-size: 1em;
  padding-left: 8px;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 10px;
}

.s-form-group>.s-form-group-container {
  position: relative;
  box-sizing: border-box;
  overflow: auto;
}

.s-form-group+.s-form-item {
  border-top: none;
}

.s-form-group-subtitle {
  color: #777;
  font-size: 0.8rem;
  margin-left: 6px;
}
</style>