<template>
  <td class="table-checkbox-td">
    <div class="table-checkbox-container">
      <i :class="iconClass" @click="handleClick" />
    </div>
  </td>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    partialChecked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    iconClass() {
      const mdi = this.checked
        ? "mdi-checkbox-marked"
        : this.partialChecked
        ? "mdi-minus-box"
        : "mdi-checkbox-blank-outline";
      return [
        "mdi mdi-24px table-checkbox-icon",
        mdi,
        this.disabled ? "icon-disabled" : "s-icon",
      ];
    },
    tableClass() {
      return this.disabled ? "table-checkbox-td hidden" : "table-checkbox-td";
    },
  },

  methods: {
    handleClick(e) {
      this.disabled ? e.preventDefault() : this.$emit("click", e);
    },
  },
};
</script>

<style>
.table-checkbox-td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 40px;
}

.table-checkbox-container {
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 40px;
}

.table-checkbox-icon {
  padding: 6px;
}

.icon-disabled {
  opacity: 0.2;
  cursor: default;
}
</style>