function CamposSelecionaveis(options) {
  //ordena os campos, alfabeticamente
  (options?.sort ?? true) && options.fields.sort(function (a, b) {
    if (a.group < b.group) {
      return -1;
    } else if (a.group > b.group) {
      return 1;
    }
    return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
  });

  //cria a tabela e atribui o id
  const table = document.createElement("table");
  table.style.setProperty("width", "100%");
  //cria o input que será usado para pesquisa
  const input = document.createElement("input");
  input.setAttribute("placeholder", "Pesquise...");
  input.style.setProperty("width", "100%");
  //inclui o input e a tabele na div da tela
  options.div.innerHTML = "";
  options.div.append(input);
  options.div.append(table);

  const params = { input, table };

  setSortable(options, table, true);
  input.addEventListener("input", (e) => buscaCampoListar(e, params));
  //inicia um contador para auxiliar na "impressao" das trs e tds
  let cont = 0;
  //loop percorrendo todos os fields do option
  for (let i = 0; i < options.fields.length; i++) {
    //cria uma tr e adiciona à table
    let tr = document.createElement("tr");
    //de acordo com o numero de colunas passado como parametro nas options, gera as tds
    for (let c = 0; c < options.cols; c++) {
      if (options.fields[cont]) {
        const checkbox = options.fields[cont];
        //cada td tem o input (nesse exemplo, checkbox, e um label)
        const td = document.createElement("td");
        const labelEl = document.createElement("label");
        const labelText = document.createTextNode(checkbox.label);
        //tr precisa de id pra saber a posicao quando utilizada ordenacao
        tr.setAttribute("id", checkbox.name);

        if (checkbox.name) {
          const input = document.createElement("input");
          input.setAttribute("type", "checkbox");
          input.setAttribute("name", checkbox.name);
          input.setAttribute("id", checkbox?.id || checkbox.name);
          input.setAttribute("value", checkbox.value ?? "checked");
          checkbox.hidden && td.setAttribute("hidden", true);
          if (checkbox.checked) {
            input.checked = true;
          }
          //quando marcado ou desmarcado refaz a ordenacao
          input.addEventListener(
            "change",
            () => setSortable(options, table, true),
          );
          labelEl.append(input);
        } else {
          tr.innerHTML && table.append(tr);
          tr = document.createElement("tr");
          labelEl.style.fontWeight = "bold";
        }
        labelEl.append(labelText);
        td.append(labelEl);
        //inclui a td na tr
        tr.append(td);
        cont++;
      }
    }
    tr.innerHTML && table.append(tr);
  }
}

function buscaCampoListar(_e, { table, input }) {
  const filter = input.value.toUpperCase();
  const tds = table.getElementsByTagName("td");
  for (let i = 0; i < tds.length; i++) {
    const td = tds[i];
    if (td && td.querySelector("input")) {
      td.style.display = (td.textContent.toUpperCase().indexOf(filter) > -1)
        ? ""
        : "none";
    }
  }
}

function setSortable(options, table, ordenar = false) {
  //se nao passado o parametro, nao deixa ordenar
  if (options.sortable != true) {
    return;
  }

  let fields = [];
  let el = [];
  setTimeout(() => {
    //verifica se ja existe a o input que ficara gravada a ordenacao, se nao existe cria ele
    if (
      document.getElementsByName(`ordination_${options.div.id}`).length == 0
    ) {
      el = document.createElement("input");
      el.setAttribute("name", `ordination_${options.div.id}`);
      options.div.append(el);
      el.style.display = "none";
    } else {
      el = document.getElementsByName(`ordination_${options.div.id}`)[0];
    }

    //define que a table é sortable, e chama o ordenar quando fizer o update
    const sort = jQuery(table).sortable({
      update: () => ordena(),
    });

    const ordena = () => {
      //limpa os valores anteriores
      el.setAttribute("value", "");
      fields = [];
      //inclui nos filds somente os campos que tiverem checked
      sort[0].childNodes.forEach((r) => {
        r.childNodes[0].childNodes[0].childNodes[0].checked &&
          fields.push(r.id);
      });
      //seta value do input de acordo com os fields, separado por virgula e ordenado conforme configuração
      el.setAttribute("value", fields.join(","));
    };

    ordenar && ordena();
  }, 20);
}

export default CamposSelecionaveis;
