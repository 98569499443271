const TITLE = Symbol();
const TITLE_MOUSE_ENTER = Symbol();
const TITLE_MOUSE_LEAVE = Symbol();

function register(el, binding) {
  el[TITLE_MOUSE_ENTER] = function () {
    if (el[TITLE]) {
      document.body.removeChild(el[TITLE]);
      el[TITLE] = null;
    }
    const popupRect = el.getBoundingClientRect();
    const top = Math.round(popupRect.top - popupRect.height);
    const left = Math.round(popupRect.left + 5);
    const div = document.createElement("div");
    div.className = "title-directive";
    div.innerHTML = binding.value;
    div.style.cssText = `top: ${top}px;left: ${left}px;`;
    document.body.appendChild(div);
    el[TITLE] = div;
  };
  el[TITLE_MOUSE_LEAVE] = function () {
    if (el[TITLE]) {
      document.body.removeChild(el[TITLE]);
      el[TITLE] = null;
    }
  };
  el.addEventListener("mouseenter", el[TITLE_MOUSE_ENTER]);
  el.addEventListener("mouseleave", el[TITLE_MOUSE_LEAVE]);
}

function unregister(el) {
  if (el[TITLE]) {
    document.body.removeChild(el[TITLE]);
    el[TITLE] = null;
  }
  if (el[TITLE_MOUSE_ENTER]) {
    el.removeEventListener("mouseenter", el[TITLE_MOUSE_ENTER]);
    el[TITLE_MOUSE_ENTER] = null;
  }
  if (el[TITLE_MOUSE_LEAVE]) {
    el.removeEventListener("mouseleave", el[TITLE_MOUSE_LEAVE]);
    el[TITLE_MOUSE_LEAVE] = null;
  }
}

export default {
  bind(el, binding) {
    register(el, binding);
  },
  unbind(el) {
    unregister(el);
  },
  update(el, binding) {
    unregister(el);
    register(el, binding);
  },
};
