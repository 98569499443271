<template>
  <div style="display:inline-flex;">
    <input ref="input" style="width: 200px;" :disabled="disabled">
  </div>
</template>

<script>
export default {
  name: "s-phone",

  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    value(value) {
      this.$nextTick(() => this.set(value));
    },
  },

  methods: {
    set(value) {
      this.$refs.input.value = value;
    },
  },

  beforeDestroy() {
    jQuery(this.$el).off();
  },

  mounted() {
    const that = this;

    jQuery(that.$refs.input).inputmask("phone", {
      showMaskOnHover: false,
      showMaskOnFocus: false,
      placeholder: " ",
    }).on("change", function () {
      that.$emit("input", this.value);
      that.$emit("change", this.value);
    });

    this.set(this.value);
  },
};
</script>