<template>
  <div class="s-animal-historico">
    <div>
      <span style="cursor: pointer" :style="tipoServico.css"
        @click="abrirAtendimento()">
        <i :class="`mdi mdi-${tipoServico.icon} mdi-18px`"></i>
      </span>
      <div style="width: 180px">
        {{value.datahora}}
        <i class="mdi mdi-clock-outline"></i>
      </div>
    </div>
    <div id="cliente" style="flex: 1; text-align: left">
      <span>{{value.cliente}}</span>
      <span class="s-tag s-tag-info" style="margin-left: 20px">
        {{value.animal}}
      </span>
    </div>
    <div id="status">
      {{value.status}}
    </div>
  </div>
</template>

<script>
import historicos from "@/utils/petshopHistoricos";
const tiposKeys = historicos.tiposKeys;

export default {
  name: "s-animal-historico",

  inject: ["hdl"],

  props: {
    value: Object,
  },

  data() {
    return {
      tiposHistoricos: historicos.tiposHistoricos(),
    };
  },

  computed: {
    tipoServico() {
      return this.tiposHistoricos.filter((t) => t.name === this.value.tipo)
        .shift();
    },
  },
  methods: {
    abrirAtendimento() {
      [tiposKeys.VACINA, tiposKeys.VERMIFUGO].includes(this.value.tipo) &&
        globalThis.Yanda.newApl(
          241,
          { id: this.value.animalId, tipo: this.value.tipo },
          this.hdl,
        );
    },
  },
};
</script>

<style scoped>
.s-animal-historico {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  min-height: 0;
  padding: 5px;
  margin: 0 auto 2px;
  border-radius: 5px;
  width: calc(100% - 20px);
  max-width: 1200px;
  box-shadow: 0 1px 3px #c1c1c1;
  background-color: #fff;
  transition: margin .5s;
  cursor: default;
}

.s-animal-historico>div {
  display: flex;
  line-height: 35px;
  align-items: center;
}

.s-animal-historico #tipo {
  background: rgb(75, 217, 138) none repeat scroll 0% 0%;
  color: black;
  font-weight: bold;
  text-shadow: none;
  text-transform: capitalize;
  text-align: center;
  padding: 0 8px;
  border-radius: 8px;
}

.s-animal-historico #cliente {
  order: 1;
  overflow: hidden;
  white-space: nowrap;
}

.s-animal-historico #status {
  order: 2;
  min-width: 90px;
  justify-content: center;
  margin-left: 3px;
  margin-right: 3px;
  font-weight: bold;
}

@media (max-width: 800px) {
  .s-animal-historico {
    flex-flow: column;
  }

  .s-animal-historico #cliente {
    order: 2;
    overflow: unset;
  }

  .s-animal-historico>div:first-child {
    flex: 1;
  }
}
</style>