<template>
  <div class="cte-veiculo-estrutura">
    <div class="cte-veiculo-estrutura-limites"></div>
    <div class="cte-veiculo-estrutura-chassi"></div>
    <div class="cte-veiculo-estrutura-chassi cte-veiculo-estrutura-chassi2">
    </div>
    <div v-for="(e, i) in value" :key="i" v-if="i !== 'estepe'"
      :class="`${i == 'E1' ? 'cte-veiculo-estrutura-eixo' : 'cte-veiculo-estrutura-eixo2'}`">
      <label
        class="cte-veiculo-estrutura-eixo-label">{{i != 'estepe' ? i : ''}}</label>
      <div v-if="temPneuNoEixo(e)" class="cte-veiculo-estrutura-risco"></div>
      <div v-for="(p, j) in e" :key="j"
        :class="`cte-veiculo-estrutura-${p.posicao} ${classPneu(p)}`"
        :title="`${i}_${p.posicao}`" @click='pneuClick(p)'>
        <span v-if="p.warning"
          style="position: absolute; display: inline-block; width: 10px;
                      height: 10px; background-color: red; top: 10px;z-index: 2;border-radius: 50%"></span>
        <s-cte-pneu :dragable="dragPneus" :value="modelar ? p.marcado : p.pneu"
          :dragdata="pneuDragdata" :dropcheck="pneuDropcheck"
          @pneudrop="(e) => {pneuDrop(e, i, p)}" />
      </div>
    </div>
    <div class="cte-veiculo-estrutura-limites"></div>
    <div v-if="value.estepe">
      <div v-for="(p, j) in value.estepe" :key="j"
        :class="`cte-veiculo-estrutura-${p.posicao} cte-veiculo-estrutura-estepe-bottom ${classPneu(p)}`"
        :title="`estepe_${p.posicao}`" @click='pneuClick(p)'>
        <span v-if="p.warning"
          style="position: absolute; display: inline-block; width: 10px;
                      height: 10px; background-color: red; top: 10px;z-index: 2;border-radius: 50%"></span>
        <s-cte-pneu :dragable="dragPneus" :value="modelar ? p.marcado : p.pneu"
          :dragdata="pneuDragdata" :dropcheck="pneuDropcheck"
          @pneudrop="(e) => {pneuDrop(e, 'estepe', p)}" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "s-cte-veiculo-estrutura",

  inject: ["hdl"],

  props: {
    value: [Object, Array],
    modelar: {
      type: Boolean,
      default: false,
    },
    pneuDragdata: String,
    pneuDropcheck: Function,
  },

  computed: {
    dragPneus() {
      return !this.modelar;
    },
  },

  methods: {
    classPneu(p) {
      if (this.modelar) {
        return p.marcado ? "cte-veiculo-estrutura-pneu-bold" : "";
      }
      return p.marcado === false ? "cte-veiculo-estrutura-pneu-oculto" : "";
    },

    temPneuNoEixo(eixo) {
      if (this.modelar) {
        return true;
      }
      for (let i = 0; i < eixo.length; i++) {
        if (eixo[i].marcado) {
          return true;
        }
      }
      return false;
    },

    pneuClick(p) {
      if (this.modelar) {
        p.marcado = !p.marcado;
        return;
      }
      p.pneu && this.$emit("pneuclick", p.pneu);
    },

    pneuDrop(pneu, eixo, p) {
      this.$emit("pneudrop", {
        pneu: pneu,
        posicao: `${eixo}_${p.posicao}`,
      });
    },
  },
};
</script>

<style>
.cte-veiculo-estrutura {
  width: 230px;
  margin: 0 auto;
  position: relative;
  /*transform: scale(0.9);*/
}

.cte-veiculo-estrutura-limites {
  width: 100%;
  height: 5px;
  border: 1px solid #000;
  background-color: #AAA;
}

.cte-veiculo-estrutura-chassi {
  top: 6px;
  left: calc(50% - 30px);
  width: 5px;
  float: left;
  border: 1px solid #000;
  height: calc(100% - 14px);
  position: absolute;
  background-color: #AAA;
}

.cte-veiculo-estrutura-chassi2 {
  left: calc(50% + 20px);
}

.cte-veiculo-estrutura-eixo {
  width: 100%;
  height: 80px;
  margin: 0 0 30px 0;
  position: relative;
}

.cte-veiculo-estrutura-risco {
  top: 50%;
  width: 100%;
  height: 1px;
  border: 1px solid #000;
  position: absolute;
  background-color: #ccc;
}

.cte-veiculo-estrutura-eixo2 {
  width: 100%;
  margin: 0;
  height: 80px;
  position: relative;
}

.cte-veiculo-estrutura-eixo-label {
  top: 20px;
  left: calc(50% - 10px);
  position: absolute;
}

.cte-veiculo-estrutura-estepe1 {
  left: 30px;
  bottom: -60px;
  cursor: pointer;
  position: absolute;
  transform: rotate(-90deg);
  max-width: 80px;
  max-height: 80px;
}

.cte-veiculo-estrutura-estepe2 {
  right: 50px;
  bottom: -60px;
  cursor: pointer;
  position: absolute;
  transform: rotate(-90deg);
  max-width: 80px;
  max-height: 80px;
}

.cte-veiculo-estrutura-estepe-bottom label {
  top: calc(50% - 5px) !important;
  left: -6px !important;
  width: 50px !important;
  transform: rotate(90deg);
  max-width: 50px !important;
}

.cte-veiculo-estrutura-pneu-oculto {
  display: none;
}

.cte-veiculo-estrutura-pneu-bold {
  opacity: 1 !important;
}

.cte-veiculo-estrutura-E2 {
  top: -11px;
  cursor: pointer;
  position: absolute;
  margin-left: 5px;
}

.cte-veiculo-estrutura-E1 {
  top: -11px;
  cursor: pointer;
  position: absolute;
  margin-left: 40px;
}

.cte-veiculo-estrutura-D1 {
  top: -11px;
  cursor: pointer;
  position: absolute;
  margin-left: 155px;
}

.cte-veiculo-estrutura-D2 {
  top: -11px;
  cursor: pointer;
  position: absolute;
  margin-left: 190px;
}
</style>