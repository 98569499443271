export default {
  rgbToHex: function (r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  },

  hexToRgb: function (hex = "") {
    if (!hex.startsWith("#")) {
      hex = "#" + hex;
    }
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  },

  rgbaToHex: function (r, g, b, a) {
    return (
      "#" +
      ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1) +
      Math.round(a * 255).toString(16).padStart(2, "0")
    );
  },

  hexToRgba: function (hex = "") {
    if (!hex.startsWith("#")) {
      hex = "#" + hex;
    }
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    const a = parseInt(hex.slice(7), 16) / 255;
    return [r, g, b, a];
  },

  rgbTextToHex: function (input = "") {
    if (input.startsWith("#")) {
      return input;
    }
    if (input.toLowerCase().startsWith("rgba")) {
      const [r, g, b] = input
        .slice(5, -1)
        .split(",")
        .map((x) => parseFloat(x));
      return this.rgbToHex(r, g, b);
    }
    if (input.toLowerCase().startsWith("rgb")) {
      const [r, g, b] = input
        .slice(4, -1)
        .split(",")
        .map((x) => parseInt(x));
      return this.rgbToHex(r, g, b);
    }
    if (input.split(",").length > 2) {
      const [r, g, b] = input.split(",").map((x) => parseInt(x));
      return this.rgbToHex(r, g, b);
    }
    return input;
  },

  hexToRgbText: function (hex = "") {
    const [r, g, b] = this.hexToRgb(hex);
    return `${r},${g},${b}`;
  },

  rgbaTextToHex: function (rgba = "") {
    if (rgba.toLowerCase().startsWith("rgba")) {
      const [r, g, b, a] = rgba
        .slice(5, -1)
        .split(",")
        .map((x) => parseFloat(x));
      return this.rgbaToHex(r, g, b, a);
    }
    const [r, g, b, a] = rgba.split(",").map((x) => parseFloat(x));
    return this.rgbaToHex(r, g, b, a);
  },

  hexToRgbaText: function (hex = "") {
    const [r, g, b, a] = this.hexToRgba(hex);
    return `${r},${g},${b},${a}`;
  },
};
