<template>
  <div class="s-form-item">
    <div class="s-form-item-description" v-if="descricao">
      <div class="s-form-item-descricao" :style="descriptionStyle"
        v-html="descricao"></div>
      <div class="s-form-item-input">
        <slot></slot>
      </div>
    </div>
    <div style="width: 100%;" v-if="!descricao">
      <div style="width: 100%">
        <slot></slot>
      </div>
    </div>
    <div class="s-form-item-detalhes" v-if="detalhes" v-html="detalhes"></div>
  </div>
</template>

<script>
export default {
  name: "s-form-item",
  props: {
    descricao: {
      type: String,
      default: "",
    },
    detalhes: {
      type: String,
      default: "",
    },
  },
  computed: {
    descriptionStyle() {
      const s = ["min-width: 120px;"];
      !this.detalhes && s.push("flex: 1;");
      return s.join(" ");
    },
  },
};
</script>

<style>
.s-form-item {
  font-size: 12px;
  padding: 10px;
  position: relative;
}

.s-form-item:not(:first-child) {
  border-top: 1px solid #e7e7e7;
}

.s-form-item>div:first-child {
  display: flex;
}

.s-form-item .s-form-item-descricao {
  flex: 1;
  font-size: 1.2em;
  align-self: center;
  margin-right: 10px;
  text-align: left;
}

.s-form-item .s-form-item-detalhes {
  flex: 1;
  color: #777;
  align-self: center;
  text-align: justify;
  padding-top: 10px;
}

.s-form-item .s-form-item-input {
  display: flex;
  align-self: center;
}

.s-form-item-description {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
</style>