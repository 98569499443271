function renderEvent(e, colaborador) {
  const el = document.createElement("div");
  const tipo = Siggma.petshop.historicos.tiposHistoricos()
    .find((t) => t.name === e.tipo);

  const descricao = e.animal
    ? `${e.animal.cliente.cliCod} - ${e.animal.cliente.pessoa.nome}`
    : e.descricao;
  const animal = !e.animal
    ? ""
    : `<div class="painel-agenda-list-animal">${e.animal.nome}</div>`;
  const tipoServico = e.tipoServico
    ? `<div class="painel-agenda-list-desc" style="font-size: 0.8em">${e.tipoServico.descricao}</div>`
    : "";
  const styleColaborador = !colaborador?.cor ? "" : `
    background: ${colaborador.cor};
    color: ${Siggma.getContrastYiq(colaborador.cor)}
  `;

  el.innerHTML =
    `<div data-id="${e.id}" class="fc-colaborador-event" style="${styleColaborador}">
      <div>
        ${getInfoPacotes(e)}
        <span>${e.horainicio} - ${e.horafim}</span>
        <span
          class="fc-colaborador-event-icon"
          style="background: ${tipo.css.background}; color: ${tipo.css.color};"
        >
          <i style="position: relative; top: 2px" class="mdi mdi-${tipo.icon} mdi-18px"></i>
        </span>
      </div>
      <div>
        <div>${descricao}</div>
        ${animal} ${tipoServico}
      </div>
      <div>${e.status}</div>
    </div>`;

  return el;
}

function getInfoPacotes(e) {
  if (!e.pacote) {
    return "";
  }

  const statusKeys = Siggma.petshop.historicos.status;
  const lastPacote =
    ((e.atendimentos - e.finalizados) === 1) && e.status === statusKeys.NOVO
      ? ' style="color: red"'
      : "";
  return `<i class="mdi mdi-gift mdi-18px"${lastPacote}` +
    `title="Pacote número: ${e.pacote} | Atendimento: ${e.id}\n` +
    `Atendimentos realizados: ${e.finalizados}/${e.atendimentos}` +
    ` - ${e.progresso}%"></i>`;
}

function emptyEvent(elm, horaInicio, horaFim, start) {
  const div = document.createElement("div");
  div.innerHTML = `<div
        class="fc-colaborador-event-empty"
        data-date="${moment(start).format("DD/MM/YYYY")}"
        data-hora-inicio="${horaInicio}"
        data-hora-fim="${horaFim}"
    >
        <div>${horaInicio} - ${horaFim}</div>
        <div>
            <i class="mdi mdi-crop-free mdi-18px"></i>
            <span>Livre</span>
        </div>
    </div>`;
  elm.appendChild(div);
}

function emptyEventOpen(that, elm) {
  const open = (start, end) =>
      that.publiclyTrigger("eventClick", {
        context: null,
        args: [{ start, end }, that.view],
      }),
    data = elm.dataset,
    format = "DD/MM/YYYY HH:mm",
    dataHora = `${data.date} ${data.horaInicio}`,
    inicio = moment(dataHora, format),
    fim = moment(`${data.date} ${data.horaFim}`, format),
    minutes = fim.diff(inicio, "minutes");

  let intervals = Math.floor(minutes / that.intervaloAgenda);
  if (intervals < 2) {
    const end = !intervals
      ? fim
      : moment(dataHora, format).add(that.intervaloAgenda, "minutes");
    open(inicio, end);
    return;
  }

  const rows = [];
  const date = inicio.format("DD/MM/YYYY");
  for (; intervals; intervals--) {
    const start = inicio.format("HH:mm");
    inicio.add(that.intervaloAgenda, "minutes");
    const end = inicio.format("HH:mm");
    rows.push({ start, end });
  }

  const hdl = globalThis.Yanda.newJanela({
    title: "Adicionar Evento",
    btMi: true,
    btFe: true,
    dad: that.hdl,
  });
  hdl.content.innerHTML = `<div style="overflow-y: auto; text-align: center;">
        <h4>{{ date }}</h4>
        <span>Períodos disponíveis</span>
        <div class="fc-colaborador-list" style="justify-content: center;">
            <div
                v-for="(row, i) in rows"
                :key="i"
                class="fc-colaborador-event-empty s-card"
                style="margin: 5px"
                @click="open(row)"
            >
                <div>{{row.start}} - {{row.end}}</div>
            </div>
        </div>
    </div>`;

  hdl.setTamanho(700, 380);
  hdl.centraliza();
  hdl.setResize(false);
  hdl.vue = new Vue({
    el: hdl.content.firstElementChild,
    data: () => ({ date, rows }),
    methods: {
      open(row) {
        open(
          moment(`${date} ${row.start}`, format),
          moment(`${date} ${row.end}`, format),
        );
        hdl.close();
      },
    },
  });

  hdl.show();
}

function getColaborador(colaboradores, id) {
  return colaboradores.find((c) => c.id === id);
}

function isWithinRange(eStart, eEnd, vStart, vEnd) {
  return (
    (eStart.isAfter(vStart) || eStart.isSame(vStart)) && eStart.isBefore(vEnd)
  ) || (
    (vStart.isAfter(eStart) || vStart.isSame(eStart)) && vStart.isBefore(eEnd)
  );
}

export default function () {
  const FC = jQuery.fullCalendar;
  if (FC.views.colaborador) {
    return;
  }

  const CustomView = FC.View.extend({
    hdl: null,
    colaboradores: [],
    intervaloAgenda: "",
    colunaUnicaAgenda: false,
    intervaloVazioAgenda: false,

    initialize: function () {
      this.hdl = this.viewSpec.options.hdl || null;
      this.colaboradores = this.viewSpec.options.colaboradores || [];
      this.colaboradores.unshift({ id: null });
      this.intervaloAgenda = this.viewSpec.options.intervaloAgenda;
      this.colunaUnicaAgenda = this.viewSpec.options.colunaUnicaAgenda;
      this.intervaloVazioAgenda = this.viewSpec.options.intervaloVazioAgenda;
    },

    render: function () {
      if (
        this.el[0].querySelector(
          ':scope > div[data-colaborador="null"] .fc-colaborador-list',
        )
      ) {
        return;
      }

      const nowrap = this.colunaUnicaAgenda ? "fc-colaborador-list-nowrap" : "";
      const frag = document.createDocumentFragment();
      this.colaboradores.forEach((c) => {
        const desc = c.id ? `${c.id} - ${c.pessoa.nome}` : "-";
        const el = document.createElement("div");
        el.dataset.colaborador = c.id;
        el.innerHTML = `
          <div class='fc-colaborador-header'>${desc}</div>
          <div class='fc-colaborador-list ${nowrap}'></div>
        `;
        frag.appendChild(el);
        c.el = el;
      });
      this.el.append(frag);

      jQuery(this.el).on("click", (e) => {
        const empty = e.target.closest(".fc-colaborador-event-empty");
        if (empty) {
          emptyEventOpen(this, empty);
          return;
        }

        const t = e.target.closest(".fc-colaborador-event");
        if (!t) {
          return;
        }
        const evento =
          this.calendar.eventManager.getEventDefsById(t.dataset.id)[0];
        const evt = Object.assign(evento.toLegacy(), {
          start: evento.dateProfile.start,
          end: evento.dateProfile.end,
        });
        this.publiclyTrigger("eventClick", {
          context: null,
          args: [evt, this.view],
        });
      });
    },

    setHeight: function (height) {
      this.el.css({ height });
    },

    renderEvents: function (events) {
      let count = 0;
      const defaultColab = this.colaboradores.find((l) => l.id === null);
      const data = events.sort((a, b) => {
        if (!a.colaborador?.id && b.colaborador?.id) {
          return -1;
        }
        if (a.colaborador?.id && !b.colaborador?.id) {
          return 1;
        }
        if (a.colaborador?.id > b.colaborador?.id) {
          return -1;
        }
        if (a.colaborador?.id < b.colaborador?.id) {
          return 1;
        }
        return a.start.isAfter(b.start) ? 1 : -1;
      });

      const vEnd = moment(this.end);
      const vStart = moment(this.start);
      let colaboradorId = "start";

      data.forEach((e, i) => {
        !e.end && (e.end = e.start);
        if (!isWithinRange(e.start, e.end, vStart, vEnd)) {
          return;
        }

        const colaborador = e.colaborador
          ? getColaborador(this.colaboradores, e.colaborador.id)
          : null;
        const list = (colaborador || defaultColab).el.querySelector(
          ".fc-colaborador-list",
        );
        if (this.intervaloVazioAgenda && colaboradorId !== e.colaborador?.id) {
          colaboradorId = e.colaborador?.id;
          (e.horainicio !== "00:00") &&
            emptyEvent(list, "00:00", e.horainicio, e.start);
        }

        list.appendChild(renderEvent(e, colaborador));

        if (!this.intervaloVazioAgenda) {
          count++;
          return;
        }

        const next = data[i + 1];
        const nextEnd = next ? (next.end || next.start) : null;
        if (
          e.colaborador?.id !== next?.colaborador?.id ||
          (nextEnd && !isWithinRange(next.start, nextEnd, vStart, vEnd))
        ) {
          (e.horafim !== "23:59") &&
            emptyEvent(list, e.horafim, "23:59", e.start);
        } else if (next) {
          e.end.isBefore(next.start) &&
            emptyEvent(list, e.horafim, next.horainicio, e.start);
        }
        count++;
      });

      this.colaboradores.forEach((c) => {
        c.el.style.display =
          c.el.querySelector(".fc-colaborador-list").childElementCount
            ? "flex"
            : "none";
      });

      const empty = this.el["0"].querySelector(":scope > .fc-list-empty-wrap2");
      if (count && empty) {
        empty.remove();
        this.publiclyTrigger("eventAfterRender");
        return;
      }
      if (!count && !empty) {
        const el = document.createElement("div");
        el.className = "fc-list-empty-wrap2";
        el.innerHTML = `<div class="fc-list-empty-wrap1">
                    <div class="fc-list-empty">Não há eventos para mostrar</div>
                </div>`;
        this.el[0].appendChild(el);
      }
      this.publiclyTrigger("eventAfterRender");
    },

    destroyEvents: function () {
      this.el[0].querySelectorAll(".fc-colaborador-list").forEach((l) =>
        l.innerHTML = ""
      );
    },
  });

  FC.views.colaborador = CustomView;
}
