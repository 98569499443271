<template>
  <div style="display:flex;min-height: 0">
    <textarea ref="txt"></textarea>
  </div>
</template>

<script>
import jQuery from "jquery";

/**
 * https://github.com/Alex-D/Trumbowyg
 *
 * Foi copiada a pasta dist do repositório do projeto e colocada em
 * public/js/lib/trumbowyg-{versão}
 */
function installTrumb() {
  const add = globalThis.Yanda.include;

  const path = `${globalThis.Siggma.basePath}/js/lib/trumbowyg-2.18.0`;

  add(`${path}/ui/trumbowyg.min.css`, "trumbowyg.min.css", "css", false);
  add(
    `${path}/plugins/table/ui/trumbowyg.table.min.css`,
    "trumbowyg.table.min.css",
    "css",
    false,
  );
  add(
    `${path}/plugins/colors/ui/trumbowyg.colors.css`,
    "trumbowyg.colors.css",
    "css",
    false,
  );

  add(`${path}/trumbowyg.min.js`, "trumbowyg.min.js", "js", false);
  add(
    `${path}/plugins/table/trumbowyg.table.min.js`,
    "trumbowyg.table.min.js",
    "js",
    false,
  );
  add(
    `${path}/plugins/colors/trumbowyg.colors.min.js`,
    "trumbowyg.colors.min.js",
    "js",
    false,
  );
  add(
    `${path}/plugins/fontsize/trumbowyg.fontsize.min.js`,
    "trumbowyg.fontsize.min.js",
    "js",
    false,
  );
  add(
    `${path}/plugins/history/trumbowyg.history.min.js`,
    "trumbowyg.history.min.js",
    "js",
    false,
  );
  add(`${path}/langs/pt_br.min.js`, "pt_br.min.js", "js", false);

  return path;
}

export default {
  name: "s-trumb",

  props: ["value"],

  inject: ["hdl"],

  watch: {
    value(novo) {
      jQuery(this.$refs.txt).trumbowyg("html", novo);
    },
  },

  methods: {
    getValue() {
      return jQuery(`<div>${jQuery(this.$refs.txt).val()}</div>`).html();
    },

    setSize(width, height) {
      const height_ = Number.isInteger(height) ? `${height}px` : `${height}`;
      jQuery(this.$el).find(".trumbowyg-editor").css({
        width: `${width}px`,
        height: height_,
        minHeight: height_,
      });
    },

    setImage(alt, url) {
      const hasClass = function (elm) {
          return elm
            ? jQuery(elm).hasClass("trumbowyg-editor")
              ? true
              : hasClass(elm.parentNode)
            : false;
        },
        t = jQuery(this.$refs.txt).trumbowyg().data("trumbowyg"),
        docSelect = t.doc.getSelection();
      let node = docSelect.focusNode;

      if (jQuery(node).hasClass("trumbowyg-editor")) {
        const $p = jQuery("<p></p>");
        $p.appendTo(node);
        node = $p[0];
      } else if (!hasClass(node)) {
        return;
      }

      t.range.selectNode(node);
      t.range.insertNode(
        jQuery(`<img src="${url}" alt="${alt}" width="300">`)[
          0
        ],
      );
      t.syncCode();
    },

    setHtml(html) {
      html && jQuery(this.$refs.txt).trumbowyg("execCmd", {
        cmd: "insertText",
        param: html,
      });
    },
  },

  beforeDestroy() {
    jQuery(this.$refs.txt).trumbowyg("destroy");
  },

  mounted() {
    const path = installTrumb();

    const ctr = globalThis.Yanda.ctr;

    jQuery.trumbowyg.svgPath = `${path}/ui/icons.svg`;
    jQuery(this.$refs.txt).trumbowyg({
      lang: "pt_br",
      semantic: false,
      btns: [
        ["viewHTML"],
        ["historyUndo", "historyRedo"],
        ["formatting"],
        ["fontsize"],
        ["foreColor", "backColor"],
        ["bold", "italic", "strikethrough", "underline"],
        ["table"],
        ["link"],
        ["insertImage"],
        ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
        ["unorderedList", "orderedList"],
        ["horizontalRule"],
        ["removeformat"],
        ["fullscreen"],
      ],
    });
    this.value && jQuery(this.$refs.txt).trumbowyg("html", this.value);

    const $el = jQuery(this.$el);
    $el.find(".trumbowyg-box").css({
      margin: 0,
      backgroundColor: "#FFF",
    });
    $el.find(".trumbowyg-editor").css({
      top: 0,
      left: 0,
      width: "100%",
      margin: 0,
      minWidth: "100%",
    });

    $el.on("tbwclosefullscreen", () => {
      $el.find(".trumbowyg-box").css({
        left: 0,
        top: 0,
        width: "100%",
      });
      $el.find(".trumbowyg-editor-box").css({
        marginBottom: "",
      });
    });

    $el.on(
      "tbwopenfullscreen",
      () => {
        $el.find(".trumbowyg-fullscreen")
          .css({
            top: `${ctr.janMaPosTop}px`,
            left: `${ctr.janMaPosLeft}px`,
            width: `${ctr.janMaLar}px`,
          }),
          $el.find(".trumbowyg-editor-box").css({
            marginBottom: `${ctr.janMaPosTop + 29}px`,
          });
      },
    );

    $el.on("tbwclosefullscreen", () => this.hdl.resize && this.hdl.resize());

    let fonteInicial = false;
    $el.on("tbwfocus", () => {
      const $elm = jQuery(this.$refs.txt);
      if (!fonteInicial && !$elm.val().trim()) {
        fonteInicial = true;
        $elm.trumbowyg("execCmd", {
          cmd: $elm.data("trumbowyg")["btnsDef"]["fontsize_large"].fn,
          param: "fontsize_large",
        });
        return;
      }
      setTimeout(() => (fonteInicial = false), 1000);
    });
  },
};
</script>