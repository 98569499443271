<template>
  <div :class="`s-cte-pneu ${value ? 's-cte-pneu-bold' : ''}`"
    :draggable="dragable" @dragstart="dragstart" @drop="drop"
    @dragover="dragover" @dragleave="dragleave" @dragend="dragend"
    @dragenter="dragenter" @click="$emit('click', value)">
    <img :src="imagem" />
    <label v-if="value && value.id">{{value.codigo}}</label>
    <span v-if="bolinha" class="s-ctr-pneu-status" title="Teste"
      :style="`background-color: ${bolinha.color}`">
      <i :class="`mdi mdi-${bolinha.icon} mdi-14px`" :title="bolinha.title"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: "s-cte-pneu",

  inject: ["hdl"],

  props: {
    value: [Object, String, Number, Boolean],
    dragable: {
      type: Boolean,
      default: true,
    },
    dragdata: String,
    dropcheck: Function,
  },

  data() {
    return {
      imagem: window.Siggma.basePath + "/img/pneu.png",
    };
  },

  computed: {
    bolinha() {
      if (!this.value) {
        return null;
      }

      switch (this.value.status) {
        case "estoque":
          return {
            color: "green",
            icon: "database",
            title: this.value.status,
          };

        case "manutenção":
          return {
            color: "darkorange",
            icon: "cog",
            title: this.value.status,
          };

        case "descarte":
          return {
            color: "red",
            icon: "trash-can",
            title: this.value.status,
          };

        default:
          return !this.value.recapes ? null : {
            color: "blue",
            icon: "recycle",
            title: `${this.value.recapes} recape(s)`,
          };
      }
    },
  },

  methods: {
    dragstart(ev) {
      if (!this.value || !this.value.id) {
        ev.preventDefault();
        return;
      }
      this.$el.style.opacity = "0.3";
      ev.dataTransfer.effectAllowed = "move";
      ev.dataTransfer.setData("text", this.value.id);
      ev.dataTransfer.setData("pneu", this.value.id);

      this.dragdata && ev.dataTransfer.setData("dragdata", this.dragdata);

      // O chrome não envia o dataTransfer nos eventos antes do drop
      // Por isso tem que ser utilizado uma variável global pra gerenciar o drop
      window.Siggma._sCtePneuDrag = {
        pneu: this.value.id,
        dragdata: this.dragdata,
      };

      const crt = this.$el.cloneNode(true);
      crt.style.position = "absolute";
      crt.style.zIndex = "1";
      crt.style.right = "-100px";
      crt.style.top = "-200px";

      document.body.appendChild(crt);
      ev.dataTransfer.setDragImage(crt, 0, 50);
      globalThis.setTimeout(() => crt.parentNode.removeChild(crt), 1000);
    },

    dragend(_ev) {
      window.Siggma._sCtePneuDrag = null;
      this.$el.style.opacity = "";
    },

    dragover(ev) {
      if (
        !window.Siggma._sCtePneuDrag || (this.value && window.Siggma
              ._sCtePneuDrag.pneu == this.value.id)
      ) {
        return;
      }
      if (this.dropcheck && !this.dropcheck(this.dragdata)) {
        return;
      }
      ev.preventDefault();
      ev.dataTransfer.dropEffect = "move";
    },

    dragenter(_ev) {
      if (
        !window.Siggma._sCtePneuDrag || (this.value && window.Siggma
              ._sCtePneuDrag.pneu == this.value.id)
      ) {
        return;
      }
      if (this.dropcheck && !this.dropcheck(this.dragdata)) {
        return;
      }
      this.$el.style.animation = "s-cte-pneu-swing 1s ease";
    },

    dragleave(ev) {
      ev.preventDefault();
      this.$el.style.animation = "";
    },

    drop(ev) {
      ev.preventDefault();
      ev.stopPropagation && ev
        .stopPropagation(); // Stops some browsers from redirecting.
      this.$emit("pneudrop", ev.dataTransfer.getData("pneu"));
    },
  },
};
</script>

<style>
.s-cte-pneu {
  height: 80px;
  cursor: pointer;
  opacity: 0.3;
  position: relative;
  margin-top: 10px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.s-cte-pneu>label {
  top: calc(50% - 10px);
  left: 2px;
  width: 32px;
  color: white;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  font-size: 10px;
  max-width: 35px;
  word-wrap: break-word;
  text-align: center;
  line-height: normal;
  font-weight: bold;
}

.s-cte-pneu>img {
  top: -10px;
  height: 100px;
  position: relative;
  transform: rotateX(40deg);
  border-radius: 4px;
}

.s-cte-pneu .s-ctr-pneu-status {
  top: -5px;
  right: -2px;
  color: white;
  width: 21px;
  height: 20px;
  border: 1px solid white;
  position: absolute;
  font-size: 10px !important;
  text-align: center;
  line-height: normal;
  border-radius: 50%;
  background-color: darkgreen;
}

.s-cte-pneu .s-ctr-pneu-status i {
  top: 2px;
  position: relative;
  font-size: 1.3em !important;
}

.s-cte-pneu-bold {
  opacity: 1 !important;
}

@keyframes s-cte-pneu-swing {
  15% {
    transform: translateX(5px);
  }

  30% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(3px);
  }

  65% {
    transform: translateX(-3px);
  }

  80% {
    transform: translateX(2px);
  }

  100% {
    transform: translateX(0);
  }
}
</style>