<template>
  <div style="display: flex; align-items: center;">
    <s-onoff v-model="value.ativo" activeText="Repetir"
      inactiveText="Repetir" />
    <s-numberinput :min="1" :max="30" required :decimals="0" name="periodo"
      v-if="value.ativo" v-model="value.periodo"
      style="width: 80px; text-align: center;" title="Número de repetições" />
    <s-select2 style="height: 26px; width: 160px;" v-if="value.ativo" tags
      placeholder="Dicas ->" v-model="value.intervalo"
      :data="intervalos"></s-select2>

    <label v-if="value.ativo" class="mdi mdi-eye mdi-18px"
      :title="repetirLancamentoTitle" style="margin-left: 5px;">
    </label>
  </div>
</template>

<script>
const intervalos = [
  { id: "", text: "" },
  { id: "1 days", text: "dias" },
  { id: "1 weeks", text: "semanas" },
  { id: "2 weeks", text: "quinzenas" },
  { id: "1 months", text: "meses" },
  { id: "2 months", text: "bimestres" },
  { id: "3 months", text: "trimestres" },
  { id: "6 months", text: "semestres" },
  { id: "1 years", text: "anos" },
];

export default {
  name: "s-petshop-repetir-evento",

  props: {
    value: {
      type: Object,
      default() {
        return {
          ativo: false,
          periodo: 1,
          intervalo: "",
        };
      },
    },
    datahora: String,
  },

  data() {
    return {
      intervalos:
        JSON.parse(localStorage.getItem("petshop-repetir-evento") || "null") ||
        intervalos,
    };
  },

  computed: {
    dica() {
      return [
        "Adicione um periodo personalizado com Enter",
        "Exemplos: 2 dias, 2 semanas, 2 meses, 2 anos...",
      ].join("\n\n");
    },
    repetirLancamentoTitle() {
      const intervalo = `${this.value.intervalo || ""}`;
      if (!intervalo) {
        return this.dica;
      }

      const translate = {
        dias: "days",
        semanas: "weeks",
        meses: "months",
        anos: "years",
      };
      const values = Object.values(translate);
      const aux = intervalo.split(" ");
      const quantia = parseInt(aux[0]);
      const unidade = translate[aux[1]] ||
        (values.includes(aux[1]) ? aux[1] : "");

      if (!unidade || !(parseInt(aux[0]) > 0)) {
        Notify.warning("Intervalo inválido");
        this.$set(this.value, "intervalo", "1 days");
        this.$nextTick(() => this.$set(this.value, "intervalo", ""));

        return this.dica;
      }

      let text = "";
      const id = `${quantia} ${unidade}`;
      const obj = this.intervalos.find((i) => i.id == id);
      if (!obj) {
        text = `${quantia} ${aux[1]}`;
        this.intervalos.push({ id, text });
        localStorage
          .setItem("petshop-repetir-evento", JSON.stringify(this.intervalos));
      } else {
        text = obj.text;
      }

      this.$nextTick(() => this.$set(this.value, "intervalo", id));

      let periodo = parseInt(`${this.value.periodo}`);
      const format = "DD/MM/YYYY HH:mm";
      const data = this.datahora ? moment(this.datahora, format) : moment();
      const eyeTitle = [
        `Repetir ${periodo} a cada ${text}`,
        `à partir de ${data.format(format)}\n`,
      ];
      while (periodo--) {
        eyeTitle.push(`${data.add(quantia, unidade).format(format)}`);
      }

      return eyeTitle.join("\n");
    },
  },
};
</script>