<template>
  <div style="display: flex;flex-direction: column">
    <div class="s-card s-card-caption">
      <div class="s-box-line s-box-line-caption">
        <h4 class="ln-valor">Centro custo</h4>
        <h4 :style="getStyleEl('desc')">Descrição</h4>
        <h4 v-if="tipoconta == 'P'" class="ln-valor">Fornecedor</h4>
        <h4 v-if="tipoconta == 'R'" class="ln-valor">Cliente</h4>
        <h4 v-if="tipoconta != undefined" class="ln-desc">Nome</h4>
        <h4 v-if="(componente != undefined) || (total != undefined)"
          class="ln-valor">Valor</h4>
        <h4 class="ln-valor">Percentual</h4>
        <div style="align-items: center;width: 60px;">
          <button v-if="!emEdicao" type="button" class="btn-small btn-primary"
            style="width: 100%;margin-left: 10px;" @click="novo">
            Novo
          </button>
        </div>
      </div>
    </div>
    <div v-for="(row) in value" :key="row.id" class="s-box-line-value"
      v-enter-next>
      <div v-if="!(idEdicao == row.id)" class="ln-valor">
        <label>{{ row['centroCusto.cencusCod'] }}</label>
      </div>
      <s-foreigninput v-else id="cc_centro_custo" :style="getStyleEl('input')"
        v-model="row['centroCusto.cencusCod']"
        :url="`${basePath}/industrial/centro-custo/get-by-id`" :tela="247"
        :number="true" field-cod="cencusCod" field-des="cencusDes"
        @change="(cc) => row['centroCusto.cencusDes'] = cc?.cencusDes || ''" />
      <div v-if="!(idEdicao == row.id)" :style="getStyleEl('desc')">
        <label>{{ row['centroCusto.cencusDes'] }}
          {{ (row.origem != undefined ? row.origem : '') }}</label>
      </div>

      <div v-if="!(idEdicao == row.id) && (tipoconta === 'P')" class="ln-valor">
        <label>{{ row['fornecedor.forCod'] }}</label>
      </div>
      <s-foreigninput v-if="(tipoconta == 'P') && (idEdicao == row.id)"
        id="cc_fornecedor" class="ln-input" v-model="row['fornecedor.forCod']"
        :url="`${basePath}/app/fornecedores/get-by-id`" :tela="49"
        :number="true" field-cod="forCod" field-des="pessoa.nome"
        @change="(f) => row['fornecedor.pessoa.nome'] = f?.pessoa?.nome || ''" />
      <div v-if="!(idEdicao == row.id) && (tipoconta == 'P')" class="ln-desc">
        <label>{{ row['fornecedor.pessoa.nome'] }}</label>
      </div>

      <div v-if="!(idEdicao == row.id) && (tipoconta == 'R')" class="ln-valor">
        <label>{{ row['cliente.cliCod'] }}</label>
      </div>
      <s-foreigninput v-if="(tipoconta == 'R') && (idEdicao == row.id)"
        id="cc_cliente" class="ln-input" v-model="row['cliente.cliCod']"
        :url="`${basePath}/app/clientes/get-by-id`" :tela="47" :number="true"
        field-cod="cliCod" field-des="pessoa.nome"
        @change="(f) => row['cliente.pessoa.nome'] = f?.pessoa?.nome || ''" />
      <div v-if="!(idEdicao == row.id) && (tipoconta == 'R')" class="ln-desc">
        <label>{{ row['cliente.pessoa.nome'] }}</label>
      </div>

      <div
        v-if="!(idEdicao == row.id) && ((componente != undefined) || (total != undefined))"
        class="ln-valor">
        <label>{{ parseFloat(row.valor).toFixed(2) }}</label>
      </div>
      <s-numberinput
        v-if="(idEdicao == row.id) && ((componente != undefined) || (total != undefined))"
        class="ln-valor" v-model="row.valor" :decimals="2"
        v-on:change="(event) => calcular(event, row, false)" />
      <div v-if="!(idEdicao == row.id)" class="ln-valor">
        <label>{{ parseFloat(row.percentual).toFixed(2) }}%</label>
      </div>
      <s-numberinput v-else class="ln-valor" v-model="row.percentual"
        :decimals="2" :min="1" :max="100"
        v-on:change="(event) => calcular(event, row, true)"
        v-on:focusout="aplicar()" />
      <div style="width: 10%; text-align: left; display: flex; flex: 1">
        <i v-if="!emEdicao" title='Excluir' :class="classBtn('del')"
          @click="excluir(row.id)"></i>
        <i v-if="!emEdicao" title='Editar' :class="classBtn('alt')"
          @click="editar(row.id)"></i>
        <i v-if="idEdicao == row.id" title='Cancelar' :class="classBtn('can')"
          @click="cancelar()"></i>
        <i v-if="idEdicao == row.id" title='Aplicar' :class="classBtn('save')"
          @click="aplicar()"></i>
      </div>
    </div>
    <div
      style="font-weight: bold;text-align: end;margin-top: 15px;width: 100%;">
      <label style="">Total: </label>
      <label
        style="font-size: 16px;width: 100px;">{{ parseFloat(this.totalGeral).toFixed(2) }}%</label>
    </div>
  </div>

</template>
<script>
export default {
  name: "s-centrocusto",
  inject: ["hdl"],
  props: {
    value: Array,
    componente: String,
    tipoconta: String,
    origem: String,
    total: Number,
  },
  data() {
    return {
      basePath: globalThis.Siggma.basePath,
      idEdicao: null,
      emEdicao: false,
      isNew: false,
      tempRow: null,
      totalGeral: 0,
    };
  },
  watch: {
    value(v) {
      this.totalGeral = 0;
      v.forEach((row) => {
        !Object.keys(row).includes("id") && (row.id = `uuid${Math.uuid(8)}`);
        (row.id == undefined) && (row.id = `uuid${Math.uuid(8)}`);
        this.totalGeral += Number(row.percentual ?? 0);
      });
      this.tempRow = null;
    },
  },
  methods: {
    aplicar() {
      let temErro = false;
      this.value.map((line) => {
        if (line.id != this.idEdicao) {
          return;
        }
        if (!line["centroCusto.cencusCod"]) {
          globalThis.Yanda.message({
            type: "warning",
            msg: "Centro de custo deve ser informado!",
          });
          temErro = true;
        }
        if (line.percentual == 0) {
          globalThis.Yanda.message({
            type: "warning",
            msg: "Percentual deve ser maior que zero!",
          });
          temErro = true;
        }
      });
      !temErro && this.atualiza(JSON.parse(JSON.stringify(this.value)));
    },
    novo() {
      this.idEdicao = `uuid${Math.uuid(8)}`;
      this.isNew = true;
      this.emEdicao = true;
      const data = JSON.parse(JSON.stringify(this.value));
      data.push({
        id: this.idEdicao,
        "centroCusto.cencusCod": null,
        "centroCusto.cencusDes": null,
        "cliente.cliCod": null,
        "cliente.pessoa.nome": null,
        "fornecedor.forCod": null,
        "fornecedor.pessoa.nome": null,
        origem: (this.origem ?? null),
        valor: 0,
        percentual: 0,
      });
      this.$emit("input", data);
    },
    editar(id) {
      this.tempRow = JSON.parse(JSON.stringify(this.value.find((r) =>
        r.id ===
          id
      )));
      this.idEdicao = id;
      this.emEdicao = true;
    },
    cancelar() {
      (this.isNew)
        ? this.atualiza(this.value.filter((val) => val.id != this.idEdicao))
        : this
          .atualiza(this.value.map((val) =>
            (val.id === this.idEdicao)
              ? this
                .tempRow
              : val
          ));
    },
    excluir(id) {
      globalThis.Yanda.confirm({
        msg: "<br>Deseja realmente excluir?<br>",
        actionTrue: () =>
          this.atualiza(this.value.filter((val) =>
            val.id !=
              id
          )),
      });
    },
    calcular(event, row, isPercent) {
      if (this.componente == undefined && this.total == undefined) {
        return;
      }
      const compEl = this.hdl.$(this.componente),
        valueCompEl = compEl.value == undefined ? compEl.innerHTML : compEl
          .value;
      const valor = this.componente != undefined ? (Number(valueCompEl)) : this
          .total,
        elValue = Number(event.target.value);
      isPercent
        ? row.valor = parseFloat((elValue / 100) * valor).toFixed(2)
        : row.percentual = parseFloat((elValue / valor) * 100).toFixed(2);
    },
    atualiza(data) {
      this.$emit("input", data);
      this.idEdicao = null;
      this.emEdicao = false;
      this.isNew = false;
      this.tempRow = null;
    },
    classBtn(tipo) {
      const tipos = {
        "del": "mdi-trash-can-outline",
        "alt": "mdi-pencil",
        "save": "mdi-check",
        "can": "mdi-close",
      };
      return "s-icon mdi mdi-24px " + tipos[tipo];
    },
    getStyleEl(tipo) {
      const widthInput = this.tipoconta == undefined
          ? (this.componente ==
              undefined
            ? "75%;"
            : "65%;")
          : "35%;",
        widthDesc = this.tipoconta == undefined
          ? (this.componente ==
              undefined
            ? "65%;"
            : "55%;")
          : "25%;";
      switch (tipo) {
        case "input":
          return "text-align: left;width: " + widthInput;
        case "desc":
          return "text-align: left;width: " + widthDesc;
        default:
          return "";
      }
    },
  },
};
</script>
<style scoped>
.s-icon {
  padding: 5px;
}

.ln-valor {
  width: 10%;
  text-align: center;
}

.ln-desc {
  width: 25%;
  text-align: left;
}

.ln-input {
  width: 35%;
}
</style>